import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getDataPlans, getModalTrue } from '../../redux/actions';

import { Form, Input, Icon, Modal, Button, Checkbox } from 'antd';
// import { FormInput } from '../FormCreateCompany/antd-masked-input';
import InputMask from 'react-input-mask';
import Service from '../../services';

import { validaCNPJ, validaPhone } from '../../utils';

class ModalCreateFilial extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      data: {
        id: null,
      },
      nome: '',
      email: '',
      estado: '',
      cidade: '',
      cep: '',
      cpfcnpj: '',
      celular: '',
      comercial: '',
      endereco: '',
      visible: false,
      confirmLoading: false,
      identificacaoObrigatoria: false,
      unidadeSemNome: false,
    };

    this.service = new Service();
  }

  componentWillReceiveProps(props) {
    if (props.modalState && props.modalState.visible === true) {
      this.setState({
        visible: props.modalState.visible,
        data: props.modalState.data,
      });
    } else {
      this.setState({
        visible: false,
      });
    }
    // console.log(this.props)
    // console.log("props", props.visible)
    // if(props.dataCompany) {
    //   this.setState({ data: props.dataCompany })
    // }
  }

  // componentDidUpdate(prevProps) {
  //   if (this.props.visible !== prevProps.visible) {
  //     this.setVisible(this.props.visible);
  //   }
  // }

  setVisible(data) {
    this.setState({ visible: data });
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = async e => {
    const status = await this.onChange();
    if (status) {
      this.setState({
        visible: false,
      });
      this.props.getModalTrue(false);
    }
  };

  handleCancel = () => {
    this.resetInputs();
    this.setState({
      visible: false,
    });
    this.props.getModalTrue(false);
  };

  resetInputs() {
    this.refs.cpfcnpj.value = '';
    this.refs.cep.value = '';
    this.refs.celular.value = '';
    this.refs.comercial.value = '';
    this.props.form.resetFields();
  }

  onChange = async () => {
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({ confirmLoading: true });

        const payload = {
          idEmpresa: this.state.data.id,
          nome: values.name,
          nomeEmpresa: values.name,
          cpfcnpj: values.cpfcnpj.replace(/\D/g, ''),
          email: values.email,
          cidade: values.cidade,
          estado: values.estado,
          endereco: values.endereco,
          cep: values.cep.replace(/\D/g, ''),
          unidadeSemNome: this.state.unidadeSemNome,
          telefones: [
            {
              ddd: values.celular.split(' ')[0].replace(/\D/g, ''),
              numero: values.celular.split(' ')[1].replace(/\D/g, ''),
              tipoTelefone: 1,
            },
          ],
        };

        if (values.comercial) {
          payload.telefones.push({
            ddd: values.comercial.split(' ')[0].replace(/\D/g, ''),
            numero: values.comercial.split(' ')[1].replace(/\D/g, ''),
            tipoTelefone: 2,
          });
        }

        const status = await this.props.onCreateFilial(payload);
        if (status) {
          await this.setState({
            visible: false,
            confirmLoading: false,
          });
          this.resetInputs();
        } else {
          this.setState({ confirmLoading: false });
        }

        return status;
      }
    });
  };

  getCEP = async value => {
    if (value.length === 8) {
      const result = await this.service.getCEP(value);
      if (result && !result.erro) {
        this.props.form.setFieldsValue({
          estado: result.uf || '',
          cidade: result.localidade || '',
          endereco: result.logradouro || '',
        });
        return true;
      } else {
        return false;
      }
    }
  };

  validateCEP = async (rule, value, callback) => {
    value = value.replace(/[^\d]+/g, '');
    if (value.length === 8) {
      const isValid = await this.getCEP(value);
      if (isValid) {
        callback();
      } else {
        callback('Forneça um CEP válido!');
      }
    }
  };
  
  render() {
    const {
      nome,
      email,
      estado,
      cidade,
      endereco,
      cep,
      celular,
      comercial,
      cpfcnpj,
      confirmLoading,
    } = this.state;
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8, offset: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16, offset: 4 },
      },
    };

    return (
      <div>
        <Button
          style={{ backgroundColor: '#00a1ed', color: '#FFF' }}
          className="btn-add"
          icon="plus"
          onClick={this.showModal}
        >
          {this.props.titleButton}
        </Button>
        <Modal
          title="Cadastrar Unidade"
          visible={this.state.visible}
          onOk={this.handleOk}
          confirmLoading={confirmLoading}
          onCancel={this.handleCancel}
        >
          <div>
            <Form {...formItemLayout}>
              <Form.Item>
                {getFieldDecorator('name', {
                  initialValue: nome,
                  rules: [
                    {
                      required: true,
                      message: 'Por favor, informe o nome da unidade!',
                      whitespace: true,
                    },
                  ],
                })(
                  <Input
                    placeholder={'Nome da unidade'}
                    prefix={
                      <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                    }
                  />
                )}
              </Form.Item>
              <Form.Item>
                <Checkbox style={{
                      fontSize: '12px',
                  }} 
                  checked={this.state.unidadeSemNome}
                  onChange={(e) => this.setState({unidadeSemNome: e.target.checked })}  
                  >
                    Unidade sem nome 
                    <br /><Icon
                          style={{
                          fontSize: 14,
                          marginRight: 5,
                          lineHeight: '1px'
                          }}
                          type="question-circle"
                      />
                      <span style={{
                        fontSize: '9px',
                        fontStyle: 'italic'
                      }}>Ao marcar a opção acima, o nome da unidade não será exibido para quem estiver fazendo a avaliação.</span>
                </Checkbox>
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('email', {
                  initialValue: email,
                  rules: [
                    {
                      type: 'email',
                      message: 'Forneça um e-mail válido!',
                    },
                    {
                      required: true,
                      message: 'E-mail obrigatório!',
                    },
                  ],
                })(
                  <Input
                    placeholder="Email"
                    type="email"
                    prefix={
                      <Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />
                    }
                  />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('cpfcnpj', {
                  initialValue: cpfcnpj,
                  rules: [
                    {
                      required: true,
                      message: 'Forneça um CNPJ válido!',
                      whitespace: true,
                    },
                    {
                      validator: validaCNPJ,
                    },
                  ],
                })(
                  <span className="ant-input-affix-wrapper">
                    <span className="ant-input-prefix">
                      <Icon
                        type="solution"
                        style={{ color: 'rgba(0,0,0,.25)' }}
                      />
                    </span>
                    <InputMask
                      className="ant-input"
                      mask="99.999.999/9999-99"
                      placeholder="CNPJ"
                      ref="cpfcnpj"
                    />
                  </span>
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('cep', {
                  initialValue: cep,
                  rules: [
                    {
                      required: true,
                      message: 'Forneça um CEP válido!',
                      whitespace: true,
                    },
                    {
                      validator: this.validateCEP,
                    },
                  ],
                })(
                  <span className="ant-input-affix-wrapper">
                    <span className="ant-input-prefix">
                      <Icon
                        type="pushpin"
                        style={{ color: 'rgba(0,0,0,.25)' }}
                      />
                    </span>
                    <InputMask
                      className="ant-input"
                      mask="99.999-999"
                      placeholder="CEP"
                      ref="cep"
                    />
                  </span>
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('estado', {
                  initialValue: estado,
                  rules: [
                    {
                      required: true,
                      message: 'Forneça um Estado válido!',
                      whitespace: true,
                    },
                  ],
                })(
                  <Input
                    placeholder="Estado"
                    prefix={
                      <Icon type="bank" style={{ color: 'rgba(0,0,0,.25)' }} />
                    }
                  />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('cidade', {
                  initialValue: cidade,
                  rules: [
                    {
                      required: true,
                      message: 'Forneça uma Cidade válida!',
                      whitespace: true,
                    },
                  ],
                })(
                  <Input
                    placeholder="Cidade"
                    prefix={
                      <Icon type="team" style={{ color: 'rgba(0,0,0,.25)' }} />
                    }
                  />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('endereco', {
                  initialValue: endereco,
                  rules: [
                    {
                      required: true,
                      message: 'Forneça uma Endereço válido!',
                      whitespace: true,
                    },
                  ],
                })(
                  <Input
                    placeholder="Endereço"
                    prefix={
                      <Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />
                    }
                  />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('celular', {
                  initialValue: celular,
                  rules: [
                    {
                      required: true,
                      message: 'Forneça um número do celular!',
                    },
                    {
                      validator: validaPhone,
                    },
                  ],
                })(
                  <span className="ant-input-affix-wrapper">
                    <span className="ant-input-prefix">
                      <Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />
                    </span>
                    <InputMask
                      className="ant-input"
                      mask="(99) 99999-9999"
                      placeholder="Celular"
                      ref="celular"
                    />
                  </span>
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('comercial', {
                  initialValue: comercial,
                })(
                  <span className="ant-input-affix-wrapper">
                    <span className="ant-input-prefix">
                      <Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />
                    </span>
                    <InputMask
                      className="ant-input"
                      mask="(99) 99999-9999"
                      placeholder="Numero comercial"
                      ref="comercial"
                    />
                  </span>
                )}
              </Form.Item>
            </Form>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getDataPlans, getModalTrue }, dispatch);

const mapStateToProps = store => ({
  modalState: store.userData.modalState,
  dataPlans: store.userData.dataPlans,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ModalCreateFilial));

//export default Form.create()(ModalCreateFilial);
