import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

// import dashboardimage from '../../images/content.jpeg';
import dashboardimage from '../../images/undraw.svg';
import './styles.css';

class ContentImage extends Component {
  onChange = () => {};

  render() {
    return (
      <div className="">
        <div className="wrapper">
          <div className="container-image">
            <img src={dashboardimage} className="imageContent" alt="Blue" />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ContentImage);
