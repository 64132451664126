/* eslint-disable prefer-destructuring */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import { Layout, Row, Col } from 'antd';
import { getUserData } from '../../redux/actions';
import Service from './Service';

// import './styles.css';
import ContentImageRecover from '../../components/ContentImageRecover';
import FormRecoverPassword from '../../components/FormRecoverPassword';

const { Content } = Layout;

class RecoverPassword extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      senha: '',
      loading: false,
    };

    this.service = new Service();
  }

  onCreatePassword = async (data) => {
    const payload = {
      id: this.props.match.params.id,
      guid: this.props.match.params.guid,
      senha: data,
    };
    await this.service.createPassword(payload);
    this.props.history.push('/login');
  };

  render() {    
    return (
      <Layout style={{ height: '100vh' }}>
        <Content style={{ backgroundColor: '#fff', height: '100vh' }}>
          <Row className="content-row" type="flex" justify="center" align="middle">
            <Col className="content-left" xs={16} lg={12} style={{ backgroundColor: '#fff' }}>
              <div className="account-logo">
                <h1 className="link-blue">
                  <a
                    style={{ color: '#000' }}
                    href="http://blueexperiencias.com.br/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    blueexperiencias.com.br
                  </a>
                </h1>
              </div>
              <FormRecoverPassword onCreatePassword={data => this.onCreatePassword(data)} />
            </Col>
            <Col className="content-right" xs={8} lg={12} style={{ backgroundColor: '#00a1ed' }}>
              <ContentImageRecover />
            </Col>
          </Row>
        </Content>
      </Layout>
    );
  }
}

RecoverPassword.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators({ getUserData }, dispatch);

const mapStateToProps = store => ({
  data: store.userData.data,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(RecoverPassword));
