import { Alert } from 'antd';
import Services from '../../services';

export async function listarPerguntasAtivas() {
    const service = new Services();
    const tokenLogin = localStorage.getItem('token');
    try {
      const response = await fetch(`${service.endPoint}Questionario/GetPerguntasAtivas`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'text/plain',
        },
      });

      const json = await response.json();

      return json;
    } catch (err) {
      
    }
}

export async function listarPerguntasEmpresa(idEmpresa) {
  const service = new Services();
  const tokenLogin = localStorage.getItem('token');
  try {
    const response = await fetch(`${service.endPoint}Questionario/GetPerguntasEmpresa?idEmpresa=${idEmpresa}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${tokenLogin}`,
        'Content-Type': 'text/plain',
      },
    });

    const json = await response.json();

    return json;
  } catch (err) {
  }
}

export async function listarTipoExibicao() {
  const service = new Services();
  const tokenLogin = localStorage.getItem('token');
  try {
    const response = await fetch(`${service.endPoint}Empresa/listar-tipo-exibicao`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${tokenLogin}`,
        'Content-Type': 'text/plain',
      },
    });

    const json = await response.json();

    return json;
  } catch (err) {
  }
}

export async function consultarTipoExibicao(empresaId) {
  const service = new Services();
  const tokenLogin = localStorage.getItem('token');
  try {
    const response = await fetch(`${service.endPoint}Empresa/consultar-tipo-exibicao/${empresaId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${tokenLogin}`,
        'Content-Type': 'text/plain',
      },
    });

    const json = await response.json();

    return json;
  } catch (err) {
  }
}

export async function salvarTipoExibicao(tipo, empresaId) {
  const service = new Services();
  const tokenLogin = localStorage.getItem('token');
  try {
    const data = {
      tipoExibicao: tipo,
      empresaId: empresaId
    }
    const response = await fetch(`${service.endPoint}Empresa/alterar-exibicao-quesito`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${tokenLogin}`,
        'Content-Type': 'application/json',
      },
    });

    const json = await response.json();

    return json;
  } catch (err) {
  }
}