import React, { Component } from 'react';
import { Layout, Row, Col, Card, Button, message } from 'antd';
import * as jwt_decode from 'jwt-decode';
import { withRouter } from 'react-router-dom';
import Service from './Service';

import Breadcrumb from '../../components/Breadcrumb';
import logoIcon from '../../images/logoblue.png';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUserData } from '../../redux/actions';

const { Content } = Layout;
class Invitation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
    this.service = new Service();
  }

  componentDidMount() {
    if (!this.service.isLogged()) {
      this.props.history.push('/login');
    } else {
      const token = this.props.userData.object.token;
      const decodeToken = jwt_decode(token);
      this.setState({
        role: decodeToken.role,
        dataUser: decodeToken,
      });
    }
  }

  onChange = async () => {
    let payload;

    if (
      this.props.location.inviteState &&
      this.props.location.inviteState[0].idEmpresa
    ) {
      payload = {
        idPessoa: this.props.location.inviteState[0].idPessoa,
        idEmpresa: this.props.location.inviteState[0].idEmpresa,
        aceito: true,
        nomeEmpresa: this.props.location.inviteState[0].nomeEmpresa,
      };
    } else {
      payload = {
        idPessoa:
          this.props.location.inviteState &&
          this.props.location.inviteState[0].idPessoa,
        idFilial:
          this.props.location.inviteState &&
          this.props.location.inviteState[0].idFilial,
        aceito: true,
        nomeFilial:
          this.props.location.inviteState &&
          this.props.location.inviteState[0].nomeFilial,
      };
    }

    const result = await this.service.acceptInvite(payload);

    if (result.code === 200) {
      if (!this.props.userData.object.boolCompleteRegister)
        this.props.history.push('/register');
      else this.props.history.push('/client');
    } else {
      message.error('Acesso negado!');
      this.props.history.push('/login');
    }
  };

  render() {
    const { dataUser } = this.state;
    return (
      <Layout>
        <Breadcrumb pages={[{ title: '' }]} />
        <Content className="content" style={{ height: '86vh' }}>
          <Row gutter={10}>
            <Col md={{ span: 24 }} xs={{ span: 24 }}>
              <Card>
                <div className="welcome">
                  <div>
                    <img
                      src={logoIcon}
                      className="logoWelcome"
                      alt="App-Blue"
                    />
                  </div>
                  <div>
                    <h1>
                      Olá, {dataUser && dataUser.unique_name}! Estamos felizes
                      em te ver aqui na Blue!
                    </h1>
                  </div>

                  <div>
                    <h1>Aceitar convite</h1>
                  </div>
                  <div>
                    <Button
                      type="primary"
                      style={{ marginRight: 20 }}
                      onClick={() => this.props.history.push('/login')}
                    >
                      {' '}
                      Não
                    </Button>
                    <Button type="primary" onClick={this.onChange}>
                      Sim
                    </Button>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </Content>
      </Layout>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getUserData }, dispatch);

const mapStateToProps = store => ({
  userData: store.userData.data,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Invitation));
