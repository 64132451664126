import React, { Component } from 'react';

import { Form, Input, Icon, Select, Button, Checkbox, message } from 'antd';
import InputMask from 'react-input-mask';
import Service from '../../services';
import ModalTerms from '../ModalTerms';
import './styles.css';

import StringMask from 'string-mask';
import { validaCNPJ, validaPhone } from '../../utils';

class FormCreateCompany extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      loading: false,
      terms: false,
      nome: '',
      email: '',
      segment: '',
      estado: '',
      cidade: '',
      cep: '',
      cpfcnpj: '',
      celular: '',
      comercial: '',
      endereco: '',
    };
    this.service = new Service();
  }

  componentDidMount() {
    if (this.props.dataCompany === undefined) {
      this.setState({
        nome: '',
        email: '',
        segmentTeste: '',
        estado: '',
        cidade: '',
        cep: '',
        cpfcnpj: '',
        celular: '',
        comercial: '',
        endereco: '',
      });
    }

    if (this.props.dataCompany && this.props.dataCompany.idSegmento) {
      console.log('this.props.dataCompany', this.props.dataCompany);

      const description = this.props.segment.object.find(item => {
        return item.id === this.props.dataCompany.idSegmento;
      });
      console.log('description', description);
      this.setState({
        nome: this.props.dataCompany.nome,
        email: this.props.dataCompany.email,
        segmentTeste: description.descricao,
        estado: this.props.dataCompany.estado,
        cidade: this.props.dataCompany.cidade,
        cep: this.setMask(this.props.dataCompany.cep, '00.000-00'),
        cpfcnpj: this.setMask(
          this.props.dataCompany.cpfcnpj,
          '00.000.000/0000-00'
        ),
        celular: this.setMask(
          this.props.dataCompany.telefones[0].ddd +
            '' +
            this.props.dataCompany.telefones[0].numero,
          '(00) 00000-0000'
        ),
        comercial:
          this.props.dataCompany.telefones.length > 1
            ? this.props.dataCompany.telefones[1].numero
            : '',
        endereco: this.props.dataCompany.endereco,
        terms: true,
      });
    } else if (this.props.dataCompany && !this.props.dataCompany.idSegmento) {
      this.setState({
        nome: this.props.dataCompany.nome,
        email: this.props.dataCompany.email,
        //segmentTeste: teste.descricao,
        estado: this.props.dataCompany.estado,
        cidade: this.props.dataCompany.cidade,
        cep: this.setMask(this.props.dataCompany.cep, '00.000-00'),
        cpfcnpj: this.setMask(this.props.dataCompany.cpfcnpj, '000.000.000-00'),
        celular: this.props.dataCompany.telefones[0].numero,
        comercial:
          this.props.dataCompany.telefones.length > 1
            ? this.props.dataCompany.telefones[1].numero
            : '',
        endereco: this.props.dataCompany.endereco,
        terms: true,
      });
    }
  }

  componentWillUnmount() {
    this.setState({
      nome: '',
      email: '',
      segmentTeste: '',
      estado: '',
      cidade: '',
      cep: '',
      cpfcnpj: '',
      celular: '',
      comercial: '',
      endereco: '',
    });
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (!this.state.terms) {
          message.warning(
            'Aceite os Termos e condições de uso para continuar!'
          );
          return;
        }
        this.setState({ loading: true });

        let payload = {
          idSegmento: this.state.segmentTeste,
          nome: values.name,
          cpfcnpj: values.cpfcnpj.replace(/\D/g, ''),
          email: values.email,
          cidade: values.cidade,
          estado: values.estado,
          endereco: values.endereco,
          cep: values.cep.replace(/\D/g, ''),
          idPlano: this.props.idPlans,
          contratoAceito: this.state.terms,
          telefones: [
            {
              ddd: values.celular.split(' ')[0].replace(/\D/g, ''),
              numero: values.celular.split(' ')[1].replace(/\D/g, ''),
              tipoTelefone: 1,
            },
          ],
        };

        if (values.comercial) {
          payload.telefones.push({
            ddd: values.comercial.split(' ')[0].replace(/\D/g, ''),
            numero: values.comercial.split(' ')[1].replace(/\D/g, ''),
            tipoTelefone: 2,
          });
        }

        if (this.props.dataCompany === undefined) {
          this.props.onCreateCompany(payload);
        } else if (this.props.dataCompany && this.props.dataCompany.idEmpresa) {
          payload = {
            ...payload,
            idEmpresa: this.props.dataCompany.idEmpresa,
            id: this.props.dataCompany.id,
          };
          this.props.updateCompany(payload);
        } else {
          const idSegmentTeste = this.props.segment.object.find(item => {
            return (
              item.descricao === this.state.segmentTeste ||
              this.state.segmentTeste
            );
          });
          payload = {
            ...payload,
            id: this.props.dataCompany.id,
            idSegmento: idSegmentTeste.id,
          };

          this.props.updateCompany(payload);
        }

        this.props.showFormState(false);
        this.setState({ loading: false });
      }
    });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  onSelectedSegment = value => {
    this.setState({
      segmentTeste: value,
    });
  };

  onSelectedEstado = value => {
    this.setState({ estado: `${value}` });
  };

  onSelectedCidade = value => {
    this.setState({ cidade: `${value}` });
  };

  onChangeTerms = e => {
    this.setState({ terms: e.target.checked });
  };

  getCEP = async value => {
    if (value.length === 8) {
      const result = await this.service.getCEP(value);
      if (result && !result.erro) {
        this.props.form.setFieldsValue({
          estado: result.uf || '',
          cidade: result.localidade || '',
          endereco: result.logradouro || '',
        });
        return true;
      } else {
        return false;
      }
    }
  };

  validateCEP = async (rule, value, callback) => {
    value = value.replace(/[^\d]+/g, '');
    if (value.length === 8) {
      const isValid = await this.getCEP(value);
      if (isValid) {
        callback();
      } else {
        callback('Forneça um CEP válido!');
      }
    }
  };

  validateTerms = (rule, value, callback) => {
    if (value || value === undefined) {
      callback();
    } else {
      callback('Aceite os Termos e condições de uso para continuar!');
    }
  };

  setMask(value, maskType) {
    const mask = new StringMask(maskType);
    return mask.apply(value);
  }

  componentWillReceiveProps(props) {
    if (props.dataCompany && props.dataCompany.idSegmento) {
      const description = props.segment.object.find(item => {
        return item.id === props.dataCompany.idSegmento;
      });
      this.setState({
        nome: props.dataCompany.nome,
        email: props.dataCompany.email,
        segmentTeste: description.descricao,
        estado: props.dataCompany.estado,
        cidade: props.dataCompany.cidade,
        cep: this.setMask(props.dataCompany.cep, '00.000-00'),
        cpfcnpj: this.setMask(props.dataCompany.cpfcnpj, '000.000.000-00'),
        celular: props.dataCompany.telefones[0].numero,
        comercial:
          props.dataCompany.telefones.length > 1
            ? props.dataCompany.telefones[1].numero
            : '',
        endereco: props.dataCompany.endereco,
        terms: true,
      });
    }

    if (props.modalState && props.modalState.visible === true) {
      this.setState({
        visible: props.modalState.visible,
        nome: props.dataCompany.nome,
        email: props.dataCompany.email,
        // segment: '',
        estado: props.dataCompany.estado,
        cidade: props.dataCompany.cidade,
        cep: this.setMask(props.dataCompany.cep, '00.000-00'),
        cpfcnpj: this.setMask(props.dataCompany.cpfcnpj, '000.000.000-00'),
        celular: props.dataCompany.telefones[0].numero,
        comercial:
          props.dataCompany.telefones.length > 1
            ? props.dataCompany.telefones[1].numero
            : '',
        endereco: props.dataCompany.endereco,
        terms: true,
      });
    } else {
      this.setState({
        visible: false,
      });
    }
  }

  render() {
    const {
      nome,
      email,
      estado,
      cidade,
      endereco,
      cep,
      celular,
      comercial,
      segmentTeste,
      cpfcnpj,
      terms,
    } = this.state;
    const segment = this.props.segment;
    const Option = Select.Option;
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8, offset: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16, offset: 4 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 14,
          offset: 10,
        },
      },
    };

    return (
      <div>
        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
          {this.props.dataCompany === undefined ? (
            <Form.Item>
              {getFieldDecorator('segment', {
                // initialValue: "Segmento",
                rules: [
                  {
                    required: true,
                    type: 'number',
                    message: 'Por favor, informe o segmento da empresa!',
                    whitespace: true,
                  },
                ],
              })(
                <Select
                  placeholder="Segmento"
                  optionFilterProp="children"
                  onChange={this.onSelectedSegment}
                  prefix={
                    <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                  }
                >
                  {segment &&
                    segment.object.map(item => (
                      <Option value={item.id} key={item.id}>
                        {item.descricao}
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          ) : null}
          {this.props.dataCompany && !this.props.dataCompany.idEmpresa ? (
            <Form.Item>
              {getFieldDecorator('segment', {
                initialValue: segmentTeste,
              })(
                <Select
                  placeholder="Segmento"
                  optionFilterProp="children"
                  onChange={this.onSelectedSegment}
                  prefix={
                    <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                  }
                >
                  {segment &&
                    segment.object.map(item => (
                      <Option value={item.id} key={item.id}>
                        {item.descricao}
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          ) : null}

          <Form.Item>
            {getFieldDecorator('name', {
              initialValue: nome,
              rules: [
                {
                  required: true,
                  message: 'Por favor, informe o nome da empresa!',
                  whitespace: true,
                },
              ],
            })(
              <Input
                placeholder={'Nome da empresa'}
                prefix={
                  <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('email', {
              initialValue: email,
              rules: [
                {
                  type: 'email',
                  message: 'Forneça um e-mail válido!',
                },
                {
                  required: true,
                  message: 'E-mail obrigatório!',
                },
              ],
            })(
              <Input
                placeholder="Email"
                type="email"
                prefix={
                  <Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('cpfcnpj', {
              initialValue: cpfcnpj,
              rules: [
                {
                  required: true,
                  message: 'Forneça um CNPJ válido!',
                  whitespace: true,
                },
                {
                  validator: validaCNPJ,
                },
              ],
            })(
              <span className="ant-input-affix-wrapper">
                <span className="ant-input-prefix">
                  <Icon type="solution" style={{ color: 'rgba(0,0,0,.25)' }} />
                </span>
                <InputMask
                  className="ant-input"
                  mask="99.999.999/9999-99"
                  defaultValue={cpfcnpj}
                  placeholder="CNPJ"
                />
              </span>
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('cep', {
              initialValue: cep,
              rules: [
                {
                  required: true,
                  message: 'Forneça um CEP válido!',
                  whitespace: true,
                },
                {
                  validator: this.validateCEP,
                },
              ],
            })(
              <span className="ant-input-affix-wrapper">
                <span className="ant-input-prefix">
                  <Icon type="pushpin" style={{ color: 'rgba(0,0,0,.25)' }} />
                </span>
                <InputMask
                  className="ant-input"
                  mask="99.999-999"
                  placeholder="CEP"
                />
              </span>
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('estado', {
              initialValue: estado,
              rules: [
                {
                  required: true,
                  message: 'Forneça um Estado válido!',
                  whitespace: true,
                },
              ],
            })(
              <Input
                onChange={e => this.setState({ estado: e.target.value })}
                placeholder="Estado"
                prefix={
                  <Icon type="bank" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('cidade', {
              initialValue: cidade,
              rules: [
                {
                  required: true,
                  message: 'Forneça uma Cidade válida!',
                  whitespace: true,
                },
              ],
            })(
              <Input
                onChange={e => this.setState({ cidade: e.target.value })}
                placeholder="Cidade"
                prefix={
                  <Icon type="team" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('endereco', {
              initialValue: endereco,
              rules: [
                {
                  required: true,
                  message: 'Forneça uma Endereço válido!',
                  whitespace: true,
                },
              ],
            })(
              <Input
                placeholder="Endereço"
                onChange={e => this.setState({ endereco: e.target.value })}
                prefix={
                  <Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('celular', {
              initialValue: celular,
              rules: [
                {
                  required: true,
                  message: 'Forneça um número do celular!',
                },
                {
                  validator: validaPhone,
                },
              ],
            })(
              <span className="ant-input-affix-wrapper">
                <span className="ant-input-prefix">
                  <Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />
                </span>
                <InputMask
                  className="ant-input"
                  mask="(99) 99999-9999"
                  placeholder="Celular"
                />
              </span>
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('comercial', {
              initialValue: comercial,
            })(
              <span className="ant-input-affix-wrapper">
                <span className="ant-input-prefix">
                  <Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />
                </span>
                <InputMask
                  className="ant-input"
                  mask="(99) 99999-9999"
                  placeholder="Numero comercial"
                />
              </span>
            )}
          </Form.Item>
          {this.props.idPlans ||
          (this.props.dataCompany && this.props.dataCompany.length === 0) ? (
            <Form.Item>
              {getFieldDecorator('terms', {
                initialValue: terms,
                rules: [
                  {
                    required: true,
                    message:
                      'Aceite os Termos e condições de uso para continuar!',
                  },
                  {
                    validator: this.validateTerms,
                  },
                ],
              })(
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    cursor: 'pointer',
                    marginRight: 12,
                  }}
                >
                  <Checkbox onChange={this.onChangeTerms}>
                    Li e aceito os
                  </Checkbox>
                  <ModalTerms />
                </div>
              )}
            </Form.Item>
          ) : null}
          <Form.Item {...tailFormItemLayout}>
            <Button
              type="primary"
              loading={this.state.loading}
              htmlType="submit"
            >
              Concluir contrato
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default Form.create()(FormCreateCompany);
