import { Checkbox, Col, Form, Icon, Input, message, Modal, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import StringMask from 'string-mask';
import { validaPhone } from "../../utils";
import Service from "../../services";
import CheckboxGroup from 'antd/lib/checkbox/Group';

const service = new Service();

function ModalUpdateFilial(props) {
    const [id, setId] = useState(0);
    const [idEmpresa, setIdEmpresa] = useState(0);
    const [nome, setNome] = useState('');
    const [estado, setEstado] = useState('');
    const [cidade, setCidade] = useState('');
    const [endereco, setEndereco] = useState('');
    const [celular, setCelular] = useState('');
    const [comercial, setComercial] = useState('');
    const [documento, setDocumento] = useState('');
    const [cep, setCep] = useState('');
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingCompany, setLoadingCompany] = useState(false);
    const [identificacaoObrigatoria, setIdentificacaoObrigatoria] = useState(false);
    const [unidadeSemNome, setUnidadeSemNome] = useState(false);
    const { getFieldDecorator } = props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8, offset: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16, offset: 4 },
      },
    };

    useEffect(() => {
        const loadFilial = async (id) => {
            if(id > 0) {
                setLoadingCompany(true);
                const resultFilial = await service.getFilialId(id);
                const dataCompany = resultFilial ? resultFilial.object : null;
            
                if(dataCompany){
                    setId(dataCompany.id);
                    setIdEmpresa(dataCompany.idEmpresa);
                    setNome(dataCompany.nome);
                    setIdentificacaoObrigatoria(dataCompany.identificacaoObrigatoria);
                    setUnidadeSemNome(dataCompany.unidadeSemNome);
                    setEstado(dataCompany.estado);
                    setCidade(dataCompany.cidade);
                    setEndereco(dataCompany.endereco);
                    setCep(setMask(dataCompany.cep, '00.0000-000'));
                    setDocumento(setMask(dataCompany.cpfcnpj, '00.000.000/0000-00'));
                    setEmail(dataCompany.email);
                    if(dataCompany.telefones.length > 0) {
                        setCelular(setMask(
                            dataCompany.telefones[0].ddd +
                            '' +
                            dataCompany.telefones[0].numero,
                            '(00) 00000-0000'
                        ));
                    } else {
                        setCelular('');
                    }
                    if(dataCompany.telefones.length > 1) {
                        setComercial(setMask(
                            dataCompany.telefones[1].ddd +
                            '' +
                            dataCompany.telefones[1].numero,
                            '(00) 00000-0000'
                        ));
                    } else {
                        setComercial('');
                    }
                }
                setLoadingCompany(false);
            }
        }

        loadFilial(props.dataId);
    }, [props.dataId])

    const setMask = (value, maskType) => {
        const mask = new StringMask(maskType);
        return mask.apply(value);
    }

    const handleChange = async () => {
        props.form.validateFields(async (err, values) => {
            if (!err) {
              setLoading(true);
              const payload = {
                id: id,
                idEmpresa: idEmpresa,
                identificacaoObrigatoria: identificacaoObrigatoria,
                unidadeSemNome: unidadeSemNome,
                nome: values.name,
                nomeEmpresa: values.name,
                cpfcnpj: documento.replace(/\D/g, ''),
                email: email,
                cidade: cidade,
                estado: estado,
                endereco: endereco,
                cep: cep.replace(/\D/g, ''),
                telefones: [
                  {
                    ddd: values.celular.split(' ')[0].replace(/\D/g, ''),
                    numero: values.celular.split(' ')[1].replace(/\D/g, ''),
                    tipoTelefone: 1,
                  },
                ],
              };
      
              if (values.comercial) {
                payload.telefones.push({
                  ddd: values.comercial.split(' ')[0].replace(/\D/g, ''),
                  numero: values.comercial.split(' ')[1].replace(/\D/g, ''),
                  tipoTelefone: 2,
                });
              }

              await props.updateCompany(payload);
              props.form.resetFields();
              setLoading(false);
              message.success('Unidade alterado com sucesso.');
            }
        });
    }

    if(loadingCompany) {
        return (
            <Modal
                title={props.titleButton}
                visible={props.visible}
                onOk={props.onClose}
                confirmLoading={loading}
                onCancel={props.onClose}
            >
                <Spin size="large" ></Spin>
            </Modal>
        )
    }
    return (
        <Modal
          title={props.titleButton}
          visible={props.visible}
          onOk={handleChange}
          confirmLoading={loading}
          onCancel={props.onClose}
        >
          <div>
            <Form {...formItemLayout}>
                <Form.Item>
                    {getFieldDecorator('name', {
                    initialValue: nome,
                    rules: [
                        {
                        required: true,
                        message: 'Por favor, informe o nome da unidade!',
                        whitespace: true,
                        },
                    ],
                    })(
                    <Input
                        placeholder={'Nome da unidade'}
                        onChange={(e) => setNome(e.target.value)}
                        prefix={
                        <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                        }
                    />
                    )}
                    
                </Form.Item>
                <Form.Item>
                      <Row>
                        <Col>
                          <Checkbox style={{
                                fontSize: '12px',
                            }} 
                            checked={identificacaoObrigatoria}
                            onChange={(e) => setIdentificacaoObrigatoria(e.target.checked)}  
                            >
                              Habilitar identificação obrigatória na avaliação.  
                          </Checkbox>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Checkbox style={{
                              fontSize: '12px',
                          }} 
                          checked={unidadeSemNome}
                          onChange={(e) => setUnidadeSemNome(e.target.checked)}  
                          >
                            Unidade sem nome 
                            <br /><Icon
                                  style={{
                                  fontSize: 14,
                                  marginRight: 5,
                                  lineHeight: '1px'
                                  }}
                                  type="question-circle"
                              />
                              <span style={{
                                fontSize: '9px',
                                fontStyle: 'italic'
                              }}>Ao marcar a opção acima, o nome da unidade não será exibido para quem estiver fazendo a avaliação.</span>
                          </Checkbox>
                        </Col>
                      </Row>
                </Form.Item>
                <Form.Item>
                {getFieldDecorator('estado', {
                  initialValue: estado,
                  rules: [
                    {
                      required: true,
                      message: 'Forneça um Estado válido!',
                      whitespace: true,
                    },
                  ],
                })(
                  <Input
                    placeholder="Estado"
                    onChange={(e) => setEstado(e.target.value)}
                    prefix={
                      <Icon type="bank" style={{ color: 'rgba(0,0,0,.25)' }} />
                    }
                  />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('cidade', {
                  initialValue: cidade,
                  rules: [
                    {
                      required: true,
                      message: 'Forneça uma Cidade válida!',
                      whitespace: true,
                    },
                  ],
                })(
                  <Input
                    placeholder="Cidade"
                    onChange={(e) => setCidade(e.target.value)}
                    prefix={
                      <Icon type="team" style={{ color: 'rgba(0,0,0,.25)' }} />
                    }
                  />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('endereco', {
                  initialValue: endereco,
                  rules: [
                    {
                      required: true,
                      message: 'Forneça uma Endereço válido!',
                      whitespace: true,
                    },
                  ],
                })(
                  <Input
                    placeholder="Endereço"
                    onChange={(e) => setEndereco(e.target.value)}
                    prefix={
                      <Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />
                    }
                  />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('celular', {
                  initialValue: celular,
                  rules: [
                    {
                      required: true,
                      message: 'Forneça um número do celular!',
                    },
                    {
                      validator: validaPhone,
                    },
                  ],
                })(
                  <span className="ant-input-affix-wrapper">
                    <span className="ant-input-prefix">
                      <Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />
                    </span>
                    <InputMask
                      className="ant-input"
                      mask="(99) 99999-9999"
                      placeholder="Celular"
                      value={celular}
                      onChange={e =>
                        setCelular(e.target.value)
                      }
                    />
                  </span>
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('comercial', {
                  initialValue: comercial,
                })(
                  <span className="ant-input-affix-wrapper">
                    <span className="ant-input-prefix">
                      <Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />
                    </span>
                    <InputMask
                      className="ant-input"
                      mask="(99) 99999-9999"
                      placeholder="Numero comercial"
                      onChange={e =>
                        setComercial(e.target.value)
                      }
                    />
                  </span>
                )}
              </Form.Item>
            </Form>
          </div>
        </Modal>
    )
}

export default Form.create()(ModalUpdateFilial);