import React, { Component } from 'react';
import {
  Layout,
  Checkbox,
  Row,
  Col,
  Divider,
  Button,
  Spin,
  message,
  Modal,
  Tooltip,
  Icon,
  Input,
  Select,
} from 'antd';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getUserData,
  getDataUserCompany,
  getDataPlans,
} from '../../redux/actions';
import { consultarTipoExibicao, listarPerguntasAtivas, listarPerguntasEmpresa, listarTipoExibicao, salvarTipoExibicao } from './services';

const { Content } = Layout;

class QuestionsClient extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: this.props.data.object,
      racionais: [],
      emocionais: [],
      dataEmocionais: [],
      dataRacionais: [],
      newArr: [],
      disabled: false,
      shouldDisableEmocionais: false,
      shouldDisableRacionais: false,
      checked: false,
      loading: false,
      currentGroup: '',
      showSubQuestions: false,
      titleSubPerguntas: '',
      subQuestions: [],
      tipoExibicaoLista: [],
      tipoExibicaoEmpresa: 1,
      savingTipoExibicao: false,
      idUnidade: 0,
      idGrupo: 0
    };
  }

  async componentDidMount() {
    await this.handleChangeFilterQuestions();
    await this.loadTipoExibicao();
  }
  
  componentWillReceiveProps(props) {
    const idUnidade = props.dataFilial.length > 0 ? props.dataFilial[0].id : 0;
    const idGrupo = props.companySelected.length > 0 ? props.companySelected[0].id : 0;
    this.setState({
      idUnidade,
      idGrupo
    });
    if((idUnidade > 0 && idUnidade !== this.state.idUnidade) || (idGrupo > 0 && idGrupo !== this.state.idGrupo)) {
      this.handleChangeFilterQuestions(idGrupo);
      this.loadTipoExibicao(idGrupo);
    }
  }

  showSubQuestions = (show, id, tipo) => {
    let subPerguntas = [];
    if(tipo === 1) {
      const pergunta = this.state.racionais.filter(x => x.id === id);
      if(pergunta && pergunta.length > 0) {
        subPerguntas = pergunta[0].subPergunta.filter(s => s.ativa);
      }
    } else if (tipo === 2) {
      const pergunta = this.state.emocionais.filter(x => x.id === id);
      if(pergunta && pergunta.length > 0) {
        subPerguntas = pergunta[0].subPergunta.filter(s => s.ativa);
      }
    }
    this.setState({
      showSubQuestions: show,
      subQuestions: subPerguntas
    })
  }

  handleTipoExibicao = async (e) => {
    this.setState({
      tipoExibicaoEmpresa: e
    });
  }

  loadTipoExibicao = async (idGrupo) => {
    const result = await listarTipoExibicao();
    let idEmpresa = 0;
    if(this.props.companySelected && this.props.companySelected.length > 0) {
      idEmpresa = idGrupo > 0 ? idGrupo : this.props.companySelected[0].id;
    }
    const tipoAtual = await consultarTipoExibicao(idEmpresa);
    let tipo = 1;
    if(tipoAtual.code === 200) {
      tipo = tipoAtual.object;
    }
    this.setState({
      tipoExibicaoLista: result.object,
      tipoExibicaoEmpresa: tipo
    });
  }

  onChangeTipoExibicao = async () => {
    this.setState({
      savingTipoExibicao: true
    });
    let idEmpresa = 0;
    if(this.props.companySelected && this.props.companySelected.length > 0) {
      idEmpresa = this.props.companySelected[0].id;
    }
    await salvarTipoExibicao(this.state.tipoExibicaoEmpresa, idEmpresa);
    message.success('Tipo de exibição de quesitos alterado com sucesso.');
    this.setState({
      savingTipoExibicao: false
    });
  }

  handleChangeFilterQuestions = async (idGrupo) => {
    this.setState({ loading: true });
    let idEmpresa = 0;
    if(idGrupo > 0){
      idEmpresa = idGrupo;
    } else if(this.props.companySelected && this.props.companySelected.length > 0) {
      idEmpresa = idGrupo > 0 ? idGrupo : this.props.companySelected[0].id;
    }
    const resultRC = await listarPerguntasAtivas();
    const resultAT = await listarPerguntasEmpresa(idEmpresa);
    const data = resultRC.object;
    const dataAT = resultAT ? resultAT.object : [];

    const racionais =
      data && data.filter(_racionais => _racionais.idSegmentoPergunta === 1);

    const emocionais =
      data && data.filter(_emocionais => _emocionais.idSegmentoPergunta === 2);

    const nps = data && data.filter(nps => nps.idSegmentoPergunta === 3);

    const criticas =
      data && data.filter(criticas => criticas.idSegmentoPergunta === 4);

    const elogios =
    data && data.filter(elogios => elogios.descricao === 'Crítica');
    let arrDefaultValueRacionais = [];
    let arrDefaultValueEmocionais = [];
    if(dataAT && dataAT.length > 0) {
      
      dataAT.forEach(x => {
        if(x.idSegmentoPergunta === 1) {
          arrDefaultValueRacionais.push(x.id);
        } else if (x.idSegmentoPergunta === 2) {
          arrDefaultValueEmocionais.push(x.id);
        }
      });
    }
    /*if(!racionais) {
      const resultRC = await listarPerguntasAtivas();
      racionais = resultRC.object && resultRC.object.filter(_racionais => _racionais.idSegmentoPergunta === 1);
    }
    if(!emocionais) {
      const resultEM = await listarPerguntasAtivas();
      emocionais = resultEM.object && resultEM.object.filter(_emocionais => _emocionais.idSegmentoPergunta === 2);
    }*/
    this.setState({
      racionais,
      emocionais,
      nps,
      criticas,
      elogios,
      loading: false,
      dataRacionais: arrDefaultValueRacionais,
      dataEmocionais: arrDefaultValueEmocionais,
    });
  };

  onChangeRacionais = checkedValues => {
    this.setState({
      ...this.state.dataRacionais,
      dataRacionais: checkedValues,
    });
  };

  onChangeEmocionais = checkedValues => {
    this.setState({
      ...this.state.dataEmocionais,
      dataEmocionais: checkedValues,
    });
  };

  handleQuestions = () => {
    const emocionals =
      JSON.parse(
        localStorage.getItem(`@emocionalValues-${this.state.currentGroup}`)
      ) || [];
    const racionals =
      JSON.parse(
        localStorage.getItem(`@racionalValues-${this.state.currentGroup}`)
      ) || [];

    const oldAnswers = [...emocionals, ...racionals];
    const currentAnswers = [
      ...this.state.dataEmocionais,
      ...this.state.dataRacionais,
    ];

    const thereIsNoChangeInResponses = currentAnswers.every(item =>
      oldAnswers.includes(item)
    );

    const thereIsNoResponses =
      [...this.state.dataEmocionais, ...this.state.dataRacionais].length === 0;

    const showEmocionais = this.state.tipoExibicaoEmpresa === 1 || this.state.tipoExibicaoEmpresa === 3;
    const showRacionais = this.state.tipoExibicaoEmpresa === 1 || this.state.tipoExibicaoEmpresa === 2;

    if(this.state.dataEmocionais.length == 0 && showEmocionais) {
      message.error('Selecione pelo menos 1 quesito emocional');
      return;
    }
    if(this.state.dataRacionais.length == 0 && showRacionais) {
      message.error('Selecione pelo menos 1 quesito racional');
      return;
    }

    localStorage.setItem(
      `@racionalValues-${this.state.currentGroup}`,
      JSON.stringify(this.state.dataRacionais)
    );
    localStorage.setItem(
      `@emocionalValues-${this.state.currentGroup}`,
      JSON.stringify(this.state.dataEmocionais)
    );

    const newArr = [
      ...this.state.dataEmocionais,
      ...this.state.dataRacionais,
      this.state.nps[0].id,
      this.state.criticas[0].id,
    ];

    const payload = {
      idEmpresa: this.props.companySelected[0].id,
      idPergunta: newArr,
    };
    this.props.saveQuestions(payload);
  };
  

  render() {
    const {
      racionais,
      emocionais,
      shouldDisableEmocionais,
      shouldDisableRacionais,
      showSubQuestions,
      titleSubPerguntas
    } = this.state;

    return (
      <Layout>
        <Modal visible={showSubQuestions} onOk={() => this.showSubQuestions(false)} onCancel={() => this.showSubQuestions(false)} title={titleSubPerguntas} cancelButtonProps={{
                hidden: true
            }}>
                <ul>
                {
                  this.state.subQuestions.map(s => {
                    return (
                      <li key={s.key}>{s.descricao}</li>
                    )
                  })
                }
                </ul>
        </Modal>
        <Content
          style={{
            padding: 24,
            margin: 0,
          }}
        >
          <Spin spinning={this.state.loading} size="large">
            <div>
              <Row>
                <Row style={{ fontWeight: 'bold' }}>
                  <h3 style={{ maxWidth: '60ch' }}>
                  Antes de configurar, confira se o Grupo de Avaliação que você quer configurar 
                  está selecionado corretamente na barra superior. Todas as "unidades" deste grupo 
                  terão a mesma configuração. <br />
                  <i> (Recomendamos no máximo 5 quesitos racionais e 5 emocionais).</i>
                  </h3>
                </Row>
                <Row>
                  <Col>
                    O que será avaliado: 
                     <Select style={{
                          marginLeft: '4px',
                          width: '300px'
                        }}
                        onChange={(e) => this.handleTipoExibicao(e)}
                        value={this.state.tipoExibicaoEmpresa}
                     >
                      {
                        this.state.tipoExibicaoLista.map(tipo => {
                          return (
                            <option 
                              key={tipo.valor} 
                              value={tipo.valor}
                              selected={tipo.valor === this.state.tipoExibicaoEmpresa}
                            >{tipo.descricao}</option>
                          )
                        })
                      }
                     </Select>
                     <Button type='primary' onClick={() => this.onChangeTipoExibicao()}>
                      {
                        this.state.savingTipoExibicao ? (
                          <Spin size='small' loading={this.state.savingTipoExibicao} />
                        ) : ('Salvar')
                      }
                     </Button>
                  </Col>
                </Row>
                <h1 style={{ fontSize: 30, fontWeight: 'bold', marginTop: 20 }}>
                  Racionais
                </h1>
                <Checkbox.Group
                  style={{
                    width: '100%',
                    backgroundColor: '#FFF',
                    marginBottom: 50,
                  }}
                  onChange={this.onChangeRacionais}
                  value={this.state.dataRacionais}
                >
                  <Row style={{ margin: 20 }}>
                    {racionais &&
                      racionais.map(item => (
                        <Col key={item.id} span={12}>
                          <Checkbox
                            value={item.id}
                            checked={true}
                            disabled={
                              shouldDisableRacionais &&
                              !this.state.dataRacionais.includes(item.id)
                            }
                          >
                            {item.descricao}
                          </Checkbox>
                          <Tooltip title="Clique para ver as sub-peguntas associadas" placement="right">
                              <Icon type="info-circle" style={{width: '5px', color: '#ccc', marginLeft: '6px', cursor: 'pointer'}} onClick={() => this.showSubQuestions(true,item.id,1)} />
                          </Tooltip>
                        </Col>
                      ))}
                  </Row>
                </Checkbox.Group>
              </Row>
            </div>
            <Divider />
            <div>
              <br />
              <Row>
                <h1 style={{ fontSize: 30, fontWeight: 'bold', marginTop: 20 }}>
                  Emocionais
                </h1>
                <Checkbox.Group
                  style={{ width: '100%', backgroundColor: '#FFF' }}
                  onChange={this.onChangeEmocionais}
                  value={this.state.dataEmocionais}
                >
                  <Row style={{ margin: 20 }}>
                    {emocionais &&
                      emocionais.map(item => (
                        <Col key={item.id} span={12}>
                          <Checkbox
                            value={item.id}
                            
                            disabled={
                              shouldDisableEmocionais &&
                              !this.state.dataEmocionais.includes(item.id)
                            }
                          >
                            {`${item.descricao}`}
                          </Checkbox>
                          <Tooltip title="Clique para ver as sub-peguntas associadas" placement="right">
                              <Icon type="info-circle" style={{width: '5px', color: '#ccc',marginLeft: '6px', cursor: 'pointer'}} onClick={() => this.showSubQuestions(true,item.id,2)} />
                          </Tooltip>
                        </Col>
                      ))}
                  </Row>
                </Checkbox.Group>
              </Row>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 20,
              }}
            >
              <Button
                style={{ backgroundColor: '#00a1ed', color: '#FFF' }}
                className="btn-add"
                icon="check"
                onClick={() => this.handleQuestions()}
              >
                Salvar Perguntas
              </Button>
            </div>
          </Spin>
        </Content>
      </Layout>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { getUserData, getDataUserCompany, getDataPlans },
    dispatch
  );

const mapStateToProps = store => ({
  userData: store.userData.data,
  dataUserCompany: store.userData.dataUserCompany,
  dataFilial: store.userData.dataFilial,
  clientFilial: store.userData.clientFilial,
  companySelected: store.userData.companySelected,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionsClient);
