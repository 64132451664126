import React, { useState, useEffect } from "react";

import { Form, Icon, Input, message, Modal, Select, Spin } from "antd";
import InputMask from 'react-input-mask';
import StringMask from 'string-mask';
import { validaPhone } from "../../utils";
import Service from "../../services";

const service = new Service();

function ModalUpdateCompany(props) {
    const [idSegmento, setIdSegmento] = useState(0);
    const [segmentsList, setSegmentsList] = useState(null);
    const [id, setId] = useState(0);
    const [nome, setNome] = useState('');
    const [estado, setEstado] = useState('');
    const [cidade, setCidade] = useState('');
    const [endereco, setEndereco] = useState('');
    const [celular, setCelular] = useState('');
    const [comercial, setComercial] = useState('');
    const [documento, setDocumento] = useState('');
    const [cep, setCep] = useState('');
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingCompany, setLoadingCompany] = useState(false);


    useEffect(() => {
        setSegmentsList(props.segments);
    }, [props.segments]);

    useEffect(() => {
        const loadCompany = async (id) => {
            setLoadingCompany(true);
            const result = id > 0 ? await service.getCompanyById(id) : null;
            const dataCompany = result ? result.object : null;
            
            if(dataCompany){
                setId(dataCompany.id);
                setNome(dataCompany.nome);
                setIdSegmento(dataCompany.idSegmento);
                setEstado(dataCompany.estado);
                setCidade(dataCompany.cidade);
                setEndereco(dataCompany.endereco);
                setCep(setMask(dataCompany.cep, '00.0000-000'));
                setDocumento(setMask(dataCompany.cpfcnpj, '00.000.000/0000-00'));
                setEmail(dataCompany.email);
                if(dataCompany.telefones.length > 0) {
                    setCelular(setMask(
                        dataCompany.telefones[0].ddd +
                          '' +
                          dataCompany.telefones[0].numero,
                        '(00) 00000-0000'
                      ));
                } else {
                    setCelular('');
                }
                if(dataCompany.telefones.length > 1) {
                    setComercial(setMask(
                        dataCompany.telefones[1].ddd +
                          '' +
                          dataCompany.telefones[1].numero,
                        '(00) 00000-0000'
                      ));
                } else {
                    setComercial('');
                }
            }
            setLoadingCompany(false);
        }

        loadCompany(props.dataId);
    },[props.dataId])

    const setMask = (value, maskType) => {
        const mask = new StringMask(maskType);
        return mask.apply(value);
    }

    const handleChange = async () => {
        props.form.validateFields(async (err, values) => {
            if (!err) {
              setLoading(true);
              const payload = {
                id: id,
                idSegmento: idSegmento,
                nome: values.name,
                nomeEmpresa: values.name,
                cpfcnpj: documento.replace(/\D/g, ''),
                email: email,
                cidade: cidade,
                estado: estado,
                endereco: endereco,
                cep: cep.replace(/\D/g, ''),
                telefones: [
                  {
                    ddd: values.celular.split(' ')[0].replace(/\D/g, ''),
                    numero: values.celular.split(' ')[1].replace(/\D/g, ''),
                    tipoTelefone: 1,
                  },
                ],
              };
      
              if (values.comercial) {
                payload.telefones.push({
                  ddd: values.comercial.split(' ')[0].replace(/\D/g, ''),
                  numero: values.comercial.split(' ')[1].replace(/\D/g, ''),
                  tipoTelefone: 2,
                });
              }

              await props.updateCompany(payload);
              props.form.resetFields();
              setLoading(false);
              message.success('Grupo alterado com sucesso.');
            }
        });
    }

    const { getFieldDecorator } = props.form;
    const Option = Select.Option;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8, offset: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16, offset: 4 },
      },
    };

    if(loadingCompany) {
        return (
            <Modal visible={props.visible}
                title={props.titleButton}
                onCancel={props.onClose}
                onOk={(handleChange)}
                confirmLoading={loading}
                >
                    <div>
                        <Spin size="large" ></Spin>
                    </div>
            </Modal>
        )
    }

    return (
        <Modal visible={props.visible}
          title={props.titleButton}
          onCancel={props.onClose}
          onOk={(handleChange)}
          confirmLoading={loading}
        >
            <div>
                <Form {...formItemLayout}>
                    <Form.Item>
                        {getFieldDecorator('segment', {
                        initialValue: idSegmento,
                        rules: [
                            {
                            required: true,
                            type: 'number',
                            message: 'Por favor, informe o segmento da empresa!',
                            whitespace: true,
                            },
                        ],
                        })(
                        <Select
                            placeholder="Segmento"
                            optionFilterProp="children"
                            onChange={(value) => setIdSegmento(value)}
                            prefix={
                            <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                            }
                        >
                            {segmentsList &&
                            segmentsList.object.map(item => (
                                <Option value={item.id} key={item.id}>
                                {item.descricao}
                                </Option>
                            ))}
                        </Select>
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('name', {
                        initialValue: nome,
                        rules: [
                            {
                            required: true,
                            message: 'Por favor, informe o nome da unidade!',
                            whitespace: true,
                            },
                        ],
                        })(
                        <Input
                            placeholder={'Nome da unidade'}
                            onChange={(e) => setNome(e.target.value)}
                            prefix={
                            <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                            }
                        />
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('estado', {
                        initialValue: estado,
                        rules: [
                            {
                            required: true,
                            message: 'Forneça um Estado válido!',
                            whitespace: true,
                            },
                        ],
                        })(
                        <Input
                            placeholder="Estado"
                            onChange={(e) => setEstado(e.target.value)}
                            prefix={
                            <Icon type="bank" style={{ color: 'rgba(0,0,0,.25)' }} />
                            }
                        />
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('cidade', {
                        initialValue: cidade,
                        rules: [
                            {
                            required: true,
                            message: 'Forneça uma Cidade válida!',
                            whitespace: true,
                            },
                        ],
                        })(
                        <Input
                            placeholder="Cidade"
                            onChange={(e) => setCidade(e.target.value)}
                            prefix={
                            <Icon type="team" style={{ color: 'rgba(0,0,0,.25)' }} />
                            }
                        />
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('endereco', {
                        initialValue: endereco,
                        rules: [
                            {
                            required: true,
                            message: 'Forneça um Endereço válido!',
                            whitespace: true,
                            },
                        ],
                        })(
                        <Input
                            placeholder="Endereço"
                            onChange={(e) => setEndereco(e.target.value)}
                            prefix={
                            <Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />
                            }
                        />
                        )}
                    </Form.Item>
                    <Form.Item>
                        <span className="ant-input-affix-wrapper">
                        <span className="ant-input-prefix">
                            <Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />
                        </span>
                        {getFieldDecorator('celular', {
                            initialValue: celular,
                            rules: [
                            {
                                required: true,
                                message: 'Forneça um número do celular!',
                            },
                            {
                                validator: validaPhone,
                            },
                            ],
                        })(
                            <InputMask
                                className="ant-input"
                                mask="(99) 99999-9999"
                                placeholder="Celular"
                                onChange={(e) => setCelular(e.target.value)}
                            />
                        )}
                        </span>
                    </Form.Item>
                    <Form.Item>
                        <span className="ant-input-affix-wrapper">
                        <span className="ant-input-prefix">
                            <Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />
                        </span>
                        {getFieldDecorator('comercial', {
                            initialValue: comercial,
                        })(
                            <InputMask
                                className="ant-input"
                                mask="(99) 99999-9999"
                                placeholder="Numero comercial"
                                onChange={(e) => setComercial(e.target.value)}
                            />
                        )}
                        </span>
                        
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    )
}

export default Form.create()(ModalUpdateCompany);