import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb as Breadcrumbs } from 'antd';

import './styles.css';

export default class Breadcrumb extends Component {

  render() {
    return (
      <Breadcrumbs className="breadcrumb">
        <Breadcrumbs.Item>
          {
            this.props.title
          }
        </Breadcrumbs.Item>
        {this.props.pages.map((page, index) => (
          <Breadcrumbs.Item key={index}>
            {page.link !== undefined ? <Link to={page.link}>{page.title}</Link> : page.title}
          </Breadcrumbs.Item>
        ))}
      </Breadcrumbs>
    );
  }
}
