import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon, Input, Button, message } from 'antd';
import { withRouter } from 'react-router-dom';

import logo from '../../images/logo_blue.png';

class FormRecoverPassword extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      senha: '',
      loading: false,
    };
  }

  onChange = async () => {
    this.setState({ loading: true });
    this.props.onCreatePassword(this.state.senha);
  };

  componentWillUnmount() {
    message.success('Senha cadastrada com sucesso');
    this.setState({ loading: false });
  }

  render() {
    const { senha, loading } = this.state;

    return (
      <div className="">
        <div className="wrapper">
          <div className="container">
            <img src={logo} className="logo-login" alt="Blue" />
            {/* <h1 className="subtitle-welcome">Bem-vindo(a) à </h1> */}
            <h1 className="title-welcome">
              <b>Blue Experiências</b>
            </h1>
            <h2 className="subtitle-welcome">
              Nova Senha
            </h2>
            <div className="container-form">
              <form className="form">
                <Input
                  type="password"
                  placeholder="Nova senha"
                  value={senha}
                  onChange={e => {
                    this.setState({
                      senha: e.target.value,
                    });
                  }}
                  prefix={
                    <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                  }
                />
                <Button
                  type="primary"
                  loading={loading}
                  onClick={this.onChange}
                >
                  Próximo
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

FormRecoverPassword.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(FormRecoverPassword);
