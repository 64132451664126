import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as jwt_decode from 'jwt-decode';
import { withRouter } from 'react-router-dom';
import { Layout, Row, Col, Card, DatePicker, Badge, Spin } from 'antd';
//import ReactHighcharts from 'react-highcharts';
import Highcharts from 'highcharts';
import moment from 'moment';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUserData } from '../../redux/actions';

import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Breadcrumb from '../../components/Breadcrumb';

import Service from './Service';
import './styles.css';

const { Content } = Layout;

class Nps extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      data: {},
      dataChart: {},
      loading: false,
      idGrupo: 0,
      idUnidade: 0
    };

    this.service = new Service();
  }

  componentDidMount() {
    if (!this.service.isLogged()) {
      this.props.history.push('/login');
    } else {
      const token = this.props.userData.object.token;
      const decodeToken = jwt_decode(token);
      this.setState({
        role: decodeToken.role,
        dataUser: decodeToken,
      });

      this.handleDate();
    }
  }

  componentWillReceiveProps(props) {
    const idUnidade = props.dataFilial.length > 0 ? props.dataFilial[0].id : 0;
    const idGrupo = props.companySelected.length > 0 ? props.companySelected[0].id : 0;
    this.setState({
      idUnidade,
      idGrupo
    });
    if((idUnidade > 0 && idUnidade !== this.state.idUnidade) || (idGrupo > 0 && idGrupo !== this.state.idGrupo)) {
      this.handleDate(idGrupo, idUnidade);
    }
  }

  handleDate = async (idGrupo, idUnidade) => {
    this.setState({ loading: true });
    let dateFirst = new Date();
    let date = new Date(new Date().setDate(dateFirst.getDate() - 30));

    const dateFormat = 'YYYY-MM-DD';

    let teste = moment(date).format(dateFormat);
    let teste2 = moment(new Date()).format(dateFormat);
    let result = await this.service.getDataNps(
      teste,
      teste2,
      idUnidade > 0 ? idUnidade : this.props.dataFilial[0].id,
      idGrupo > 0 ? idGrupo : this.props.companySelected[0].id
    );
    console.log('result', result);

    this.setState({
      dataChart: result.object,
      dataInitial: teste,
      loading: false,
    });
    this.highChartsRenderNps();
  };

  onChange = async (value, dateString) => {
    this.setState({ loading: true });

    let result = await this.service.getDataNps(
      dateString[0],
      dateString[1],
      this.props.filialId,
      this.props.companySelected[0].id
    );
    console.log('result date', result);
    this.setState({ dataChart: result.object, loading: false });
    this.highChartsRenderNps();
  };

  onOk = value => {
    console.log('onOk: ', value);
  };

  zonasNps = () => {
    const { nps } = this.state.dataChart;

    if (nps <= 0) {
      return 'Crítica';
    } else if (nps < 51) {
      return 'Aperfeiçoamento';
    } else if (nps < 76) {
      return 'Qualidade';
    } else {
      return 'Excelência';
    }
  };

  highChartsRenderNps() {
    Highcharts.chart({
      chart: {
        type: 'column',
        renderTo: 'nps',
      },
      title: {
        text: null,
      },
      subtitle: {
        text: null,
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.y:.1f}%</b>',
      },
      yAxis: {
        allowDecimals: false,
        title: 'Totais',
      },
      xAxis: {
        // labels: {
        //   enabled: false,
        // },
        categories: ['NPS'],
      },
      colors: ['#f5222d', '#faad14', '#52c41a'],
      series: [
        {
          name: 'Detratores',
          data: [this.state.dataChart.percentDetratores],
        },
        {
          name: 'Neutros',
          data: [this.state.dataChart.percentNeutros],
        },
        {
          name: 'Promotores',
          data: [this.state.dataChart.percentPromotores],
        },
      ],
    });
  }

  render() {
    const { RangePicker } = DatePicker;
    const dateFormat = 'YYYY-MM-DD';

    let dateInit = JSON.stringify(this.state.dataInitial);

    const homeClient = {
      home: 'Início',
      user: 'Índice Blue',
      company: 'NPS',
      companyClient: 'Empresa',
      contracts: 'Evoluções',
      plans: 'Avaliações',
      configuration: 'Opções',
      logout: 'Sair',
      iconClient: {
        home: 'home',
        user: 'area-chart',
        company: 'pie-chart',
        companyClient: 'bank',
        contracts: 'line-chart',
        plans: 'message',
        configuration: 'setting',
        logout: 'logout',
      },
    };

    const routesClient = {
      home: 'client',
      user: 'indexBlue',
      company: 'nps',
      companyClient: 'companyClient',
      contracts: 'evolution',
      plans: 'comments',
      configuration: 'settings',
    };

    return (
      <Layout>
        <Sidebar
          dataSidebar={homeClient}
          routes={routesClient}
          current="dashboard"
        />
        <Layout>
          <Header dataUser={this.state.dataUser} />

          <Content
            style={{
              margin: '24px 16px',
              padding: 24,
            }}
          >
            <Spin spinning={this.state.loading} size="large">
              <Row className="nopadding-col">
                <Col md={{ span: 16 }} xs={{ span: 24 }}>
                  <Breadcrumb pages={[{ title: 'NPS' }]} />
                </Col>
              </Row>
              <Row
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-end',
                  marginBottom: 20,
                }}
              >
                {this.state.dataInitial !== undefined ? (
                  <RangePicker
                    defaultValue={[
                      moment(dateInit, dateFormat),
                      moment(new Date(), dateFormat),
                    ]}
                    format={dateFormat}
                    onChange={this.onChange}
                    onOk={this.onOk}
                  />
                ) : null}
              </Row>
              <Row gutter={16}>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 6 }}
                >
                  <Card title="Avaliações" className="tcenter">
                    <h1
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: 40,
                        fontWeight: 'bold',
                      }}
                    >
                      {this.state.dataChart.totalAvaliacao}
                    </h1>
                    <Row
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                      }}
                    >
                      <p style={{ marginBottom: -10 }}>100%</p>
                    </Row>
                  </Card>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 6 }}
                >
                  <Card
                    title={
                      <span style={{ color: '#f5222d', fontWeight: 'bold' }}>
                        Detratores
                      </span>
                    }
                    className="tcenter"
                  >
                    <h1
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: 40,
                        fontWeight: 'bold',
                      }}
                    >
                      {this.state.dataChart.totalDetratores}
                    </h1>
                    <Row
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                      }}
                    >
                      <p style={{ marginBottom: -10 }}>
                        {this.state.dataChart.percentDetratores &&
                          this.state.dataChart.percentDetratores.toFixed()}
                        %
                      </p>
                    </Row>
                  </Card>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 6 }}
                >
                  <Card
                    title={
                      <span style={{ color: '#faad14', fontWeight: 'bold' }}>
                        Neutros
                      </span>
                    }
                    className="tcenter"
                  >
                    <h1
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: 40,
                        fontWeight: 'bold',
                      }}
                    >
                      {this.state.dataChart.totalNeutros}
                    </h1>
                    <Row
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                      }}
                    >
                      <p style={{ marginBottom: -10 }}>
                        {this.state.dataChart.percentNeutros &&
                          this.state.dataChart.percentNeutros.toFixed()}
                        %
                      </p>
                    </Row>
                  </Card>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 6 }}
                >
                  <Card
                    title={
                      <span style={{ color: '#52c41a', fontWeight: 'bold' }}>
                        Promotores
                      </span>
                    }
                    className="tcenter"
                  >
                    <h1
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: 40,
                        fontWeight: 'bold',
                      }}
                    >
                      {this.state.dataChart.totalPromotores}
                    </h1>
                    <Row
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                      }}
                    >
                      <p style={{ marginBottom: -10 }}>
                        {this.state.dataChart.percentPromotores &&
                          this.state.dataChart.percentPromotores.toFixed()}
                        %
                      </p>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row style={{ flexDirection: 'row' }} gutter={16}>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 18 }}
                  lg={{ span: 18 }}
                  style={{ marginBottom: 30, marginTop: 30 }}
                >
                  <Row>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}
                      style={{ marginBottom: 10 }}
                      className="home-charts"
                    >
                      {/* <ReactHighcharts config={config1} /> */}
                      <div id="nps"></div>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 6 }}
                  lg={{ span: 6 }}
                  style={{ marginTop: 26 }}
                >
                  <Row style={{ flexDirection: 'column' }} gutter={16}>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}
                    >
                      <Card title="NPS" className="tcenter">
                        <h1
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: 40,
                            fontWeight: 'bold',
                          }}
                        >
                          {this.state.dataChart.nps &&
                            this.state.dataChart.nps.toFixed()}
                        </h1>
                        <p
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: 14,
                            fontWeight: 'bold',
                          }}
                        >
                          {this.zonasNps()}
                        </p>
                      </Card>
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}
                    >
                      <Card title="Zonas do NPS" className="tcenter">
                        <Row style={{ flexDirection: 'column' }}>
                          <Col span={24} style={{ marginTop: -16 }}>
                            <Badge color="red" text="Crítica -100 a 0" />
                          </Col>
                          <Col span={24}>
                            <Badge
                              color="yellow"
                              text="Aperfeiçoamento 1 a 50"
                            />
                          </Col>
                          <Col span={24}>
                            <Badge color="green" text="Qualidade 51 a 75" />
                          </Col>
                          <Col span={24}>
                            <Badge color="blue" text="Excelência 76 a 100" />
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Spin>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

Nps.propTypes = {
  history: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getUserData }, dispatch);

const mapStateToProps = store => ({
  userData: store.userData.data,
  clientFilial: store.userData.clientFilial,
  filialId: store.userData.filialId,
  companySelected: store.userData.companySelected,
  dataFilial: store.userData.dataFilial
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Nps));
