import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getDataPlans } from '../../redux/actions';
import { Modal } from 'antd';

class ModalTerms extends Component {

  state = {
    ModalText: this.props.dataPlans.contrato.descricao,
    visible: false,
    confirmLoading: false,
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = e => {
    console.log(e);
    this.setState({
      visible: false,
    });

    // this.props.getDataPlans()
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const { visible, confirmLoading, ModalText } = this.state;

    return (
      <div>
        <p style={{ color: '#00a1ed', border: 'none', background: '#FFF' }} onClick={this.showModal}>
          Termos de Uso e Políticas de Privacidade
        </p>
        <Modal
          title="Contrato Blue"
          visible={visible}
          onOk={this.handleOk}
          confirmLoading={confirmLoading}
          onCancel={this.handleCancel}
        >
          <p>{ModalText}</p>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ getDataPlans }, dispatch);

const mapStateToProps = store => ({
  dataPlans: store.userData.dataPlans,
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalTerms);
