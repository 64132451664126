import { Icon, message, Modal } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { obterLogo, removerLogo, salvarLogo } from './services';
import './styles.css';

export default function ModalLogomarca(props) {
    const refInput = useRef(null);
    const [logoAtual, setLogoAtual] = useState('');
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [idAtual, setIdAtual] = useState(0);

    const {
        visible,
        empresaId,
        nomeEmpresa,
        filialId,
        nomeFilial,
        onClose,
    } = props;

    const handleFile = (e) => {
        if(!e.target.files) {
            return;
        }

        const image = e.target.files[0];
        const extensoes = ['image/png','image/jpeg','image/jpg','image/gif'];
        if(extensoes.includes(image.type)) {
            setFile(image);
            setLogoAtual(URL.createObjectURL(image));
        } else {
            message.error('Extensão de arquivo inválida');
        }
    }

    const onHandleOk = async () => {
        if(!file) {
            message.warn('Selecione uma logomarca para continuar');
            return;
        }
        setLoading(true);
        const result = await salvarLogo(empresaId, filialId, file);
        if(result && result.object) {
            message.success('Logo cadastrada com sucesso!');
            onClose();
        } else {
            message.error(result ? result.message : 'Erro ao cadastrar logomarca');
        }
        setLoading(false);
    }

    const handleDelete = async () => {
        if(idAtual > 0) {
            setLoading(true);
            const result = await removerLogo(idAtual);
            if(result && result.object) {
                message.success('Logo removida com sucesso');
                setIdAtual(0);
                setFile(null);
                setLogoAtual('');
            } else {
                message.error(result ? result.message : 'Erro ao excluir logomarca');
            }
            setLoading(false);
        } else {
            setFile(null);
            setLogoAtual('');
        }
    }

    useEffect(() => {
        async function load() {
            const result = await obterLogo(empresaId, filialId);
            setLogoAtual(result.url);
            setIdAtual(result.id);
        }

        load();
    },[empresaId, filialId]);

    return (
        <Modal visible={visible} 
        onCancel={() => onClose()} 
        title={`Cadastrar logomarca para ${nomeEmpresa} ${nomeFilial ? `/ ${nomeFilial}` : ''}`}
        confirmLoading={loading}
        onOk={() => onHandleOk()}>
            <div className='upload-logo'>
                <span>
                    <Icon
                        style={{
                        marginRight: 5,
                        fontSize: 14,
                        }}
                        type="question-circle"
                    />
                    Recomendamos que logomarca tenha 200 pixels de largura por 120 de altura.
                </span>
                {
                    nomeFilial && (
                        <span>
                            <Icon
                                style={{
                                marginRight: 5,
                                fontSize: 14,
                                }}
                                type="question-circle"
                            />
                            Caso não seja cadastrada logomarca para a unidade, irá exibir a logomarca do grupo
                        </span>
                    )
                }
                <input type="file" name="logomarca" ref={refInput} onChange={(e) => handleFile(e)} />
                <label onClick={() => {
                    refInput.current.click();
                }}>Enviar logomarca</label>
                {
                    logoAtual ? (
                        <>
                            <img alt="Logomarca atual" src={logoAtual} />
                            <label onClick={() => handleDelete()}>Remover logomarca</label>
                        </>
                        
                    ) : (
                        <span>Nenhuma logo cadastrada ainda</span>
                    )
                }
                
            </div>
        </Modal>
    )
}