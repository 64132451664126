const validaCPF = (rule, cpf, callback) => {
  if(!cpf) {
    callback()
    return
  }

  cpf = cpf.replace(/[^\d]+/g,'');
  if (cpf.length !== 11 || cpf === "00000000000" || cpf === "11111111111" || cpf === "22222222222" || cpf === "33333333333" || cpf === "44444444444" || cpf === "55555555555" || cpf === "66666666666" || cpf === "77777777777" || cpf === "88888888888" || cpf === "99999999999") callback('Forneça um CPF válido!')

  var soma = 0, resto;

  for (let i=1; i<=9; i++) soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i);
  resto = (soma * 10) % 11;
  if ((resto === 10) || (resto === 11))  resto = 0;
  if (resto !== parseInt(cpf.substring(9, 10))) callback('Forneça um CPF válido!')

  soma = 0;

  for (let i = 1; i <= 10; i++) soma = soma + parseInt(cpf.substring(i-1, i)) * (12 - i);
  resto = (soma * 10) % 11;
  if ((resto === 10) || (resto === 11))  resto = 0;
  if (resto !== parseInt(cpf.substring(10, 11))) callback('Forneça um CPF válido!')

  callback()
}

const validaCNPJ = (rule, cnpj, callback) => {
  if(!cnpj) {
    callback()
    return
  }

  cnpj = cnpj.replace(/[^\d]+/g,'');
  if (cnpj.length !== 14 || cnpj === "00000000000000" || cnpj === "11111111111111" || cnpj === "22222222222222" || cnpj === "33333333333333" || cnpj === "44444444444444" || cnpj === "55555555555555" || cnpj === "66666666666666" || cnpj === "77777777777777" || cnpj === "88888888888888" || cnpj === "99999999999999") callback('CNPJ inválido!')

  var tamanho = cnpj.length - 2,
      numeros = cnpj.substring(0,tamanho),
      digitos = cnpj.substring(tamanho),
      soma = 0,
      pos = tamanho - 7,
      resultado;

  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado !== Number(digitos.charAt(0))) callback('CNPJ inválido!')

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0,tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado !== Number(digitos.charAt(1))) callback('CNPJ inválido!')

  callback()
}

const validaPhone = (rule, value, callback) => {
  if(!value) {
    callback()
    return
  }

  if(value.replace(/\D/g, '').length === 11)
    callback()
  else
    callback('Forneça um número do celular!')
}

const validaEmail = (email) => {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export { validaCPF, validaCNPJ, validaPhone, validaEmail }
