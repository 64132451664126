import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as jwt_decode from 'jwt-decode';
import { withRouter } from 'react-router-dom';
import { Layout, Row, Col, Collapse, Card, Icon, Spin } from 'antd';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUserData, getDataUserCompany } from '../../redux/actions';

import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Breadcrumb from '../../components/Breadcrumb';
import ModalCreateFilial from '../../components/ModalCreateFilial';
import ModalInvitation from '../../components/ModalInvitation';
import ModalUpdateCompany from '../../components/ModalUpdateCompany';

import Service from './Service';

import './styles.css';

const { Content } = Layout;
const Panel = Collapse.Panel;

class CompanyClient extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      loading: false,
      blocked: false,
      data: {},
      dataFilial: [],
      visibleEditFilial: false,
      visibleInvitation: false,
      visibleEditCompany: false,
    };

    this.service = new Service();
  }

  componentDidMount() {
    if (!this.service.isLogged()) {
      this.props.history.push('/login');
    } else {
      const tokenLogin = localStorage.getItem('token');
      const decodeToken = jwt_decode(tokenLogin);
      this.setState({
        role: decodeToken.role,
        dataUser: decodeToken,
      });
      this.listCompany();
      this.listCompanyFilial();
      this.listSegment();
    }
  }

  onChange = () => {
    console.log('okkkkkk');
  };

  async listCompany() {
    this.setState({ loading: true });
    const result = await this.service.listCompany();

    this.props.getDataUserCompany(result.object);

    if (result === undefined) {
      await this.service.doLogout();
      this.props.history.push('/login');
    } else {
      this.setState({ loading: false, data: result.object });
      this.props.getDataUserCompany(result.object);
    }
  }

  async listCompanyFilial() {
    this.setState({ loading: true });
    const result = await this.service.listCompanyFilial();
    console.log('listCompanyFilial', result);
    //this.props.getDataUserCompany(result.object)

    if (result === undefined) {
      await this.service.doLogout();
      this.props.history.push('/login');
    } else {
      this.setState({ loading: false, dataFilial: result.object });
      //this.props.getDataUserCompany(result.object);
    }
  }

  updateCompany = async data => {
    this.setState({ loading: true });
    if (data.idEmpresa) {
      const result = await this.service.updateFilial(data);
      console.log('result', result);
      this.setState({ visible: false, loading: false });
      this.listCompanyFilial();
    } else {
      const resultCompany = await this.service.updateCompany(data);
      console.log('resultCompany', resultCompany);
      this.setState({ visible: false, loading: false });
      this.listCompany();
      this.listCompanyFilial();
    }

    this.setState({ visible: false, loading: false });
  };

  listSegment = async () => {
    const result = await this.service.listSegment();

    if (result === undefined) {
      await this.service.doLogout();
      this.props.history.push('/login');
    } else {
      this.setState({ segment: result });
    }
  };

  onCreateFilial = data => {
    this.service.createCompanyFilial(data);
    this.listCompanyFilial();
  };

  createInvitation = data => {
    this.service.createInvitation(data);
    this.setState({ visibleTeste: false });
  };

  render() {
    const { data, dataFilial } = this.state;

    const homeClient = {
      home: 'Início',
      user: 'Índice Blue',
      company: 'NPS',
      companyClient: 'Empresa',
      contracts: 'Evoluções',
      plans: 'Avaliações',
      configuration: 'Opções',
      logout: 'Sair',
      iconClient: {
        home: 'home',
        user: 'area-chart',
        company: 'pie-chart',
        companyClient: 'bank',
        contracts: 'line-chart',
        plans: 'message',
        configuration: 'setting',
        logout: 'logout',
      },
    };

    const routesClient = {
      home: 'client',
      user: 'indexBlue',
      company: 'nps',
      companyClient: 'companyClient',
      contracts: 'evolution',
      plans: 'comments',
      configuration: 'settings',
    };

    return (
      <Layout>
        <Sidebar
          dataSidebar={homeClient}
          routes={routesClient}
          current="dashboard"
        />
        <Layout>
          <Header dataUser={this.state.dataUser} />
          <Row className="nopadding-col">
            <Col md={{ span: 16 }} xs={{ span: 24 }}>
              <Breadcrumb pages={[{ title: 'Empresas e Filias' }]} />
            </Col>
            <Col md={{ span: 8 }} xs={{ span: 24 }}>
              <div className="new-item">
                <ModalCreateFilial
                  active
                  titleButton="Cadastrar Unidade"
                  dataCompany={data}
                  onCreateFilial={this.onCreateFilial}
                />
              </div>
            </Col>
            <Col md={{ span: 8 }} xs={{ span: 24 }}>
              <div className="new-item" style={{ display: 'none' }}>
                <ModalInvitation
                  active
                  visible={this.state.visibleTeste}
                  //dataCompany={data}
                  dataCompanyFilial={this.state.itemFilial}
                  titleButton="Enviar convite"
                  createInvitation={this.createInvitation}
                />
                <ModalUpdateCompany
                  active
                  visible={this.state.visible}
                  titleButton="Editar empresa"
                  dataCompany={this.state.itemFilial}
                  updateCompany={this.updateCompany}
                  segment={this.state.segment}
                />
              </div>
            </Col>
          </Row>

          <Content
            style={{
              margin: '24px 16px',
              padding: 24,
              background: '#fff',
              minHeight: 280,
            }}
          >
            <Spin
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              delay={500}
              spinning={this.state.loading}
              size="large"
            >
              <Collapse>
                {this.props.dataUserCompany.map(item => {
                  return (
                    <Panel
                      header={`${item.nome}`}
                      key={item.id}
                      extra={
                        <Row
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Icon
                            style={{ marginRight: 15, fontSize: 18 }}
                            type="plus-square"
                            onClick={e => {
                              this.setState({
                                itemFilial: item,
                                visible: false,
                                visibleTeste: true,
                              });
                              e.stopPropagation();
                            }}
                          />
                          <Icon
                            style={{ marginRight: 15, fontSize: 18 }}
                            type="edit"
                            onClick={event => {
                              this.setState({
                                itemFilial: item,
                                visible: true,
                                visibleTeste: false,
                              });
                              event.stopPropagation();
                            }}
                          />
                          <Icon
                            style={{ fontSize: 18 }}
                            type="usergroup-add"
                            onClick={e => {
                              this.setState({
                                itemFilial: item,
                                visible: false,
                                visibleTeste: true,
                              });
                              e.stopPropagation();
                            }}
                          />
                        </Row>
                      }
                    >
                      <Card style={{ maxWidth: 320, marginBottom: 20 }}>
                        <p>{`Estado: ${item.estado}`}</p>
                        <p>{`Cidade: ${item.cidade}`}</p>
                        <p>{`Endereço: ${item.endereco}`}</p>
                        <p>{`Plano contratado: ${item.descricaoPlano}`}</p>
                        <p>{`Responsavel: ${item.pessoas[0].nome}`}</p>
                      </Card>
                      {/* <Collapse defaultActiveKey="1">
                          <Panel header={`Filial: ${dataFilial[0] && dataFilial[0].nome}`} key="1" style={{ backgroundColor: '#d9d9d9' }}>
                            <p>{text}</p>
                          </Panel>
                        </Collapse> */}
                    </Panel>
                  );
                })}
                {dataFilial.map(item => {
                  return (
                    <Panel
                      header={`${item.nome}`}
                      key={item.id}
                      extra={
                        <Row
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Icon
                            style={{ marginRight: 15 }}
                            type="edit"
                            onClick={event => {
                              this.setState({
                                itemFilial: item,
                                visible: true,
                                visibleTeste: false,
                              });
                              event.stopPropagation();
                            }}
                          />
                          <Icon
                            type="plus"
                            onClick={event => {
                              this.setState({
                                itemFilial: item,
                                visible: false,
                                visibleTeste: true,
                              });
                              event.stopPropagation();
                            }}
                          />
                        </Row>
                      }
                    >
                      <Card style={{ maxWidth: 300 }}>
                        <p>{`Estado: ${item.estado}`}</p>
                        <p>{`Cidade: ${item.cidade}`}</p>
                        <p>{`Endereço: ${item.endereco}`}</p>

                        <p>{`Telefone: ${item.telefones[0].numero}`}</p>
                      </Card>
                    </Panel>
                  );
                })}
                {/* <Panel header="This is panel header 3" key="3">
                  <p>{text}</p>
                </Panel> */}
              </Collapse>
            </Spin>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

CompanyClient.propTypes = {
  history: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getUserData, getDataUserCompany }, dispatch);

const mapStateToProps = store => ({
  userData: store.userData.data,
  dataUserCompany: store.userData.dataUserCompany,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CompanyClient));
