import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getDataPlans, setClientFilial } from '../../redux/actions';
import { Layout, Card, Col, Row, message } from 'antd';

import Header from '../../components/Header';
import Breadcrumb from '../../components/Breadcrumb';
import FormCreateCompany from '../../components/FormCreateCompany';

import Service from './Service';
import './styles.css';

const { Content } = Layout;

class RegisterCompany extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showForm: true,
      materialValue: 0
    }

    this.service = new Service();
    this.listSegment = this.listSegment.bind(this);
  }

  async componentDidMount() {
    if (!this.service.isLogged()) {
      this.props.history.push('/login');
    } else {
      this.listSegment();
    }
  }

  async listSegment() {
    const result = await this.service.listSegment();

    if (result === undefined) {
      await this.service.doLogout();
      this.props.history.push('/login');
    } else {
      this.setState({ segment: result})
    }
  }

  showFormState = (data) => {
    this.setState({ showForm: data })
  }

  onCreateCompany = async (data) => {
    const resultCreateCompany = await this.service.createCompany(data);
    if (resultCreateCompany && resultCreateCompany.object === true) {
      message.success('Empresa criada com sucesso');
      this.props.history.push('/client');
    } else {
      let error =
        resultCreateCompany.mensage != undefined && resultCreateCompany.mensage?
          resultCreateCompany.mensage: 'A empresa não foi criada.'
      message.error(error);
    }
  }

  render() {
    const { dataPlans } = this.props;
    console.log("dataPlans", dataPlans)
    const { segment } = this.state;

    return (
      <Layout>
        <Header />
        <Breadcrumb pages={[]} title="CADASTRO DE EMPRESA"/>

        <Content className="content card-header">
          <div style={{ background: '#FFF', padding: '30px' }}>
            <Row gutter={16}>
              <Col lg={14} sm={14} xs={24} style={{ marginTop: 11 }}>
                <FormCreateCompany
                  onCreateCompany={ payload => this.onCreateCompany(payload) }
                  idPlans={dataPlans.id}
                  segment={segment}
                  showFormState={this.showFormState}
                />
              </Col>
              <Col lg={10} sm={10} xs={23}>
                <Row gutter={16} style={{ display: "flex", justifyContent: "center" }}>
                  <Card
                    style={{ width: 350, height: 583, marginTop: 10, backgroundColor: '#00a1ed' }}
                  >
                    <h1 style={{ textAlign: 'center', fontWeight: 'bold' }}>{dataPlans.nome}</h1>
                    <div>
                      <p>{dataPlans.qtdRespostas} Opniões mês</p>
                      <p>{dataPlans.qtdUsuarios} Usuários</p>
                    </div>

                    <ul>
                      <li>Dashboard</li>
                      <li>Graficos</li>
                      <li>Indicadores</li>
                      <li>Índice Blue</li>
                      <li>NPS (Net Promoter Score)</li>
                      <li>Comparativos por data</li>
                      <li>Evolução do NPS</li>
                      <li>Lista de Clientes</li>
                      <li>Comentarios (elogios, sugestões e críticas)</li>
                      <li>Perguntas Personalizadas</li>
                      <li>Alerta em Tempo Real</li>
                    </ul>

                    <div>
                      <Row>
                        <Col span={12} style={{ fontWeight: 'bold' }}><p>Mensalidade:</p></Col>
                        <Col className="row-price" style={{ fontWeight: 'bold' }} span={12}><p>R$ {dataPlans.valor}</p></Col>
                      </Row>
                      {/* <Row>
                        <Col span={12}><p>Material:</p></Col>
                        <Col className="row-price" span={12}><p>R$ {this.state.materialValue}</p></Col>
                      </Row> */}
                    </div>

                    <div>
                      <Row>
                        <Col span={12}><h1 style={{ fontWeight: 'bold' }}>Total: </h1></Col>
                        <Col className="row-price" span={12}><h1 style={{ fontWeight: 'bold' }}>R$: {dataPlans.valor} </h1></Col>
                      </Row>
                    </div>
                  </Card>
                </Row>
                {/* {
                  showForm ?
                    <div style={{ marginTop: 10 }}>
                      <Row type="flex" justify="center">
                        <Col lg={10} sm={20} xs={24}>
                          <Button style={{ width: '100%' }} type="primary" htmlType="submit" onClick={() => this.props.history.push('/client')}>
                            Concluir Contrato
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  : null
                } */}
              </Col>
            </Row>
          </div>
        </Content>
      </Layout>
    )
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ getDataPlans, setClientFilial }, dispatch);

const mapStateToProps = store => ({
  dataPlans: store.userData.dataPlans,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RegisterCompany));
