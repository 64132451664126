import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as jwt_decode from 'jwt-decode';
import { withRouter, Link } from 'react-router-dom';

import { Layout, Row, Col, Card, Spin } from 'antd';
import ReactHighcharts from 'react-highcharts';
import moment from 'moment';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUserData } from '../../redux/actions';

import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Breadcrumb from '../../components/Breadcrumb';

import Service from './Service';
import logoIcon from '../../images/logo_blue.png';

import './styles.css';

const { Content } = Layout;

class Home extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      role: '',
      data: {},
      dataChart: {},
      loading: false,
      dataBlue: {},
    };

    this.service = new Service();
  }

  async componentDidMount() {
    if (!this.service.isLogged()) {
      this.props.history.push('/login');
    } else {
      const { token } = this.props.userData.object;
      const decodeToken = jwt_decode(token);
      console.log('decodeToken', decodeToken);
      await this.setState({
        role: decodeToken.role,
        dataUser: decodeToken,
      });
    }

    if (
      this.state.role === 'Cliente' &&
      this.props.userData.object.boolCompleteRegister === true
    ) {
      this.props.history.push('/client');
    }

    if (this.state.role === 'Administrador') {
      this.getDataBlue();
    }
    this.handleDate();
  }

  handleDate = async () => {
    this.setState({ loading: true });
    const dateFirst = new Date();
    const date = new Date(new Date().setDate(dateFirst.getDate() - 30));

    const dateFormat = 'YYYY-MM-DD';

    const teste = moment(date).format(dateFormat);
    const teste2 = moment(new Date()).format(dateFormat);
    const result = await this.service.getDataNps(
      teste,
      teste2,
      this.props.filialId
    );

    this.setState({
      dataChart: result.object,
      dataInitial: teste,
      loading: false,
    });
  };

  onChange = async (value, dateString) => {
    this.setState({ loading: true });

    const result = await this.service.getDataNps(
      dateString[0],
      dateString[1],
      this.props.filialId
    );
    console.log('result date', result);
    this.setState({ dataChart: result.object, loading: false });
  };

  onOk = value => {
    console.log('onOk: ', value);
  };

  zonasNps = () => {
    const { nps } = this.state.dataChart;

    if (nps <= 0) {
      return 'Crítica';
    }
    if (nps < 51) {
      return 'Aperfeiçoamento';
    }
    if (nps < 76) {
      return 'Qualidade';
    }
    return 'Excelência';
  };

  getDataBlue = async () => {
    const result = await this.service.getDataBlue();
    console.log('result', result.object);

    const totalEmpresas =
      result.object.numeroEmpresasAtivas + result.object.numeroEmpresasInativas;
    const totalEmpresasAtivas = result.object.numeroEmpresasAtivas;
    const totalEmpresasInativas = result.object.numeroEmpresasInativas;
    const porcentagemEmpresasAtivas =
      (totalEmpresasAtivas * 100) / (totalEmpresas || 1);
    const porcentagemEmpresasInativas =
      (totalEmpresasInativas * 100) / (totalEmpresas || 1);

    if (result) {
      this.setState({
        dataBlue: result.object,
        totalEmpresas,
        porcentagemEmpresasAtivas,
        porcentagemEmpresasInativas,
      });
    }
  };

  render() {
    const {
      role,
      dataUser,
      dataBlue,
      totalEmpresas,
      porcentagemEmpresasAtivas,
      porcentagemEmpresasInativas,
    } = this.state;

    const config1 = {
      chart: {
        height: '395',
      },
      title: {
        text: 'Admin',
      },
      yAxis: {
        title: 'Totais',
      },
      xAxis: {
        categories: [
          '01/01',
          '02/01',
          '03/01',
          '04/01',
          '05/01',
          '06/01',
          '07/01',
        ],
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: 'Positivos',
          color: '#2aa1bb',
          data: [120, 134, 103, 126, 84, 93, 135],
        },
      ],
    };

    const home = {
      home: 'Início',
      user: 'Usuários',
      company: 'Empresas',
      contracts: 'Contrato',
      plans: 'Planos',
      logout: 'Sair',
      iconClient: {
        home: 'dashboard',
        user: 'user',
        company: 'bank',
        contracts: 'profile',
        plans: 'tag',
        logout: 'logout',
      },
    };

    const routesClient = {
      home: '/',
      user: 'administrators',
      company: 'companys',
      contracts: 'contracts',
      plans: 'plans',
    };

    return (
      <Layout>
        {role !== 'Cliente' ? (
          <Sidebar
            dataSidebar={home}
            routes={routesClient}
            current="dashboard"
          />
        ) : null}

        {role === 'Cliente' &&
        this.props.userData.object.boolCompleteRegister === false ? (
          <Layout>
            <Header dataUser={this.state.dataUser} />
            <Content className="content" style={{ height: '83vh' }}>
              <Row className="nopadding-col">
                <Col span={8}>
                  <Breadcrumb pages={[{ title: 'Início' }]} />
                </Col>
              </Row>
              <Row gutter={10}>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                  xl={{ span: 24 }}
                >
                  <Card>
                    <div className="welcome">
                      <div>
                        <img
                          src={logoIcon}
                          className="logoWelcome"
                          alt="App-Blue"
                        />
                      </div>
                      <div>
                        <h1>
                          Olá, {dataUser.unique_name}! Estamos felizes em te ver
                          aqui na Blue!
                        </h1>
                      </div>

                      <div>
                        <h1>
                          Crie uma nova empresa escolhendo um
                          <Link
                            to={{ pathname: '/plansChoice', isRegister: true }}
                          >
                            <span> plano</span>
                          </Link>
                        </h1>
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Content>
          </Layout>
        ) : (
          <Layout>
            <Header dataUser={this.state.dataUser} />
            <Content
              style={{
                margin: '24px 16px',
                padding: 24,
              }}
            >
              <Spin spinning={this.state.loading} size="large">
                <Row className="nopadding-col">
                  <Col
                    xs={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                    xl={{ span: 24 }}
                    style={{ marginBottom: 20 }}
                  >
                    <Breadcrumb
                      pages={[{ title: `Painel de indicadores Blue` }]}
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 8 }}
                    md={{ span: 8 }}
                    lg={{ span: 8 }}
                    xl={{ span: 8 }}
                  >
                    <Card
                      title="Empresas Ativas"
                      className="tcenter"
                      extra={
                        <span>{`${porcentagemEmpresasAtivas &&
                          porcentagemEmpresasAtivas.toFixed(2)} %`}</span>
                      }
                    >
                      <Row gutter={14}>
                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 10 }}
                          xl={{ span: 10 }}
                        >
                          <h1
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              fontSize: 40,
                              fontWeight: 'bold',
                            }}
                          >
                            {dataBlue.numeroEmpresasAtivas}
                          </h1>
                        </Col>
                        <Col
                          className="gutter-row"
                          xs={{ span: 12 }}
                          sm={{ span: 12 }}
                          md={{ span: 12 }}
                          lg={{ span: 8 }}
                          xl={{ span: 8 }}
                        >
                          <p
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              fontSize: 14,
                              fontWeight: 'bold',
                            }}
                          >
                            Básico
                          </p>
                          <p
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              fontSize: 14,
                              fontWeight: 'bold',
                            }}
                          >
                            Avançado
                          </p>
                          <p
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              fontSize: 14,
                              fontWeight: 'bold',
                            }}
                          >
                            Personalizado
                          </p>
                        </Col>
                        {dataBlue &&
                          dataBlue.numeroDeEmpresasPorPlano &&
                          dataBlue.numeroDeEmpresasPorPlano.map(item => {
                            return (
                              <Col
                                key={item.text}
                                className="gutter-row"
                                xs={{ span: 12 }}
                                sm={{ span: 12 }}
                                md={{ span: 12 }}
                                lg={{ span: 6 }}
                                xl={{ span: 6 }}
                              >
                                <p
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {item.value}
                                </p>
                              </Col>
                            );
                          })}
                      </Row>
                    </Card>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 8 }}
                    md={{ span: 8 }}
                    lg={{ span: 8 }}
                    xl={{ span: 8 }}
                  >
                    <Card
                      title="Empresas Inativas"
                      className="tcenter"
                      extra={
                        <span>{`${porcentagemEmpresasInativas &&
                          porcentagemEmpresasInativas.toFixed(2)} %`}</span>
                      }
                    >
                      <Row
                        gutter={14}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: 105,
                        }}
                      >
                        <Col className="gutter-row">
                          <h1 style={{ fontSize: 40, fontWeight: 'bold' }}>
                            {dataBlue.numeroEmpresasInativas}
                          </h1>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 8 }}
                    md={{ span: 8 }}
                    lg={{ span: 8 }}
                    xl={{ span: 8 }}
                  >
                    <Card title="Empresas Cadastradas" className="tcenter">
                      <Row
                        gutter={14}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: 105,
                        }}
                      >
                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 10 }}
                          xl={{ span: 10 }}
                        >
                          <h1
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              fontSize: 40,
                              fontWeight: 'bold',
                            }}
                          >
                            {totalEmpresas}
                          </h1>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
                <Row style={{ flexDirection: 'row' }} gutter={16}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                    xl={{ span: 16 }}
                    style={{ marginBottom: 30, marginTop: 30 }}
                  >
                    <Row>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 24 }}
                        style={{ marginBottom: 10 }}
                        className="home-charts"
                      >
                        <ReactHighcharts config={config1} />
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                    xl={{ span: 8 }}
                    style={{ marginTop: 26 }}
                  >
                    <Row style={{ flexDirection: 'column' }} gutter={16}>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                        xl={{ span: 24 }}
                      >
                        <Card title="Usuários" className="tcenter">
                          <Row gutter={16}>
                            <Col
                              className="gutter-row"
                              xs={{ span: 12 }}
                              sm={{ span: 12 }}
                              md={{ span: 24 }}
                              lg={{ span: 24 }}
                              xl={{ span: 12 }}
                            >
                              <h1
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  fontSize: 30,
                                  fontWeight: 'bold',
                                }}
                              >
                                {dataBlue.numeroUsuariosAtivos}
                              </h1>
                              <p
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  fontSize: 14,
                                  fontWeight: 'bold',
                                }}
                              >
                                Ativos
                              </p>
                            </Col>
                            <Col
                              className="gutter-row"
                              xs={{ span: 12 }}
                              sm={{ span: 12 }}
                              md={{ span: 24 }}
                              lg={{ span: 24 }}
                              xl={{ span: 12 }}
                            >
                              <h1
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  fontSize: 30,
                                  fontWeight: 'bold',
                                }}
                              >
                                {dataBlue.numeroUsuariosInativos}
                              </h1>
                              <p
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  fontSize: 14,
                                  fontWeight: 'bold',
                                }}
                              >
                                Inativos
                              </p>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                        xl={{ span: 24 }}
                      >
                        <Card title="Respostas" className="tcenter">
                          <Row gutter={16}>
                            <Col
                              className="gutter-row"
                              xs={{ span: 12 }}
                              sm={{ span: 12 }}
                              md={{ span: 24 }}
                              lg={{ span: 24 }}
                              xl={{ span: 12 }}
                            >
                              <h1
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  fontSize: 30,
                                  fontWeight: 'bold',
                                }}
                              >
                                {dataBlue.numeroAvaliacoes}
                              </h1>
                              <p
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  fontSize: 14,
                                  fontWeight: 'bold',
                                }}
                              >
                                Avaliações
                              </p>
                            </Col>
                            <Col
                              className="gutter-row"
                              xs={{ span: 12 }}
                              sm={{ span: 12 }}
                              md={{ span: 24 }}
                              lg={{ span: 24 }}
                              xl={{ span: 12 }}
                            >
                              <h1
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  fontSize: 30,
                                  fontWeight: 'bold',
                                }}
                              >
                                {dataBlue.numeroComentarios}
                              </h1>
                              <p
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  fontSize: 14,
                                  fontWeight: 'bold',
                                }}
                              >
                                Comentários
                              </p>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Spin>
            </Content>
          </Layout>
        )}
      </Layout>
    );
  }
}

Home.propTypes = {
  history: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getUserData }, dispatch);

const mapStateToProps = store => ({
  userData: store.userData.data,
  clientFilial: store.userData.clientFilial,
  filialId: store.userData.filialId,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Home));
