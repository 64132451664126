import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as jwt_decode from 'jwt-decode';
import { withRouter } from 'react-router-dom';
import {
  Layout,
  Row,
  Col,
  Card,
  Spin,
  Divider,
  Modal,
  Button,
  Select,
} from 'antd';
import moment from 'moment';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getUserData,
  getDataUserCompany,
  getDataPlans,
  getDataFilial,
  setClientFilial,
  getIdFilial,
  setCompanySelected,
  getCompanyPlan,
} from '../../redux/actions';

import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Breadcrumb from '../../components/Breadcrumb';

import Service from './Service';
import NPSService from '../Nps/Service';
import CommentsService from '../Comments/Service';
import sun from '../../images/sun.svg';
import hourglass from '../../images/hourglass-end.png';
import arrowGrow from '../../images/chat-arrow-grow.png';
import ArrowRed from '../../images/up-arrow-red.svg';
import ArrowGreen from '../../images/up-arrow-green.svg';
import ArrowNeutral from '../../images/up-arrow-green.svg';
import comment from '../../images/comment-alt.png';
import './styles.css';

const { Content } = Layout;
const { Option } = Select;

class HomeClient extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      data: {},
      dataPlan: [],
      datafilial: [],
      loading: false,
      showPlan: false,
      disabled: false,
      companyData: [],
      planCompany: [],
      npsData: {},
      commentsData: {
        allComments: [],
        todayComments: [],
        formatedComments: { commentCount: 0, suggestions: [], complains: [] },
        todaysFormatedComments: {
          commentCount: 0,
          suggestions: [],
          complains: [],
        },
        passives: 0,
        promotors: 0,
        detractors: 0,
      },
      idUnidade: 0,
      idGrupo: 0,
      userName: ''
    };
    this.service = new Service();
  }

  toggle = () => {
    this.setState({ loading: true });
  };

  componentDidMount() {
    if (!this.service.isLogged()) {
      this.props.history.push('/login');
    } else {
      const token = this.props.userData.object.token;
      const decodeToken = jwt_decode(token);
      this.setState({
        role: decodeToken.role,
        dataUser: decodeToken,
      });
      this.listCompany();
      this.getNPSData();
      this.getYesterdayNPSData();
      this.getCommentsData();
    }
  }

  componentDidUpdate() {
    console.log({ estado: this.state });
  }

  componentWillReceiveProps(props) {
    const idUnidade = props.dataFilial.length > 0 ? props.dataFilial[0].id : 0;
    const idGrupo = props.companySelected.length > 0 ? props.companySelected[0].id : 0;
    this.setState({
      idUnidade,
      idGrupo,
      userName: props.userData.object.nome
    })
    if((idUnidade > 0 && idUnidade !== this.state.idUnidade) || (idGrupo > 0 && idGrupo !== this.state.idGrupo)) {
      this.handleChange(idGrupo);
      this.handleChangeFilial(idUnidade);
    }
  }


  async getNPSData() {
    const dateFormat = 'YYYY-MM-DD';

    let today = new Date(new Date().setDate(new Date().getDate()));
    let todayMinus30 = new Date(new Date().setDate(today.getDate() - 30));

    console.log({
      hoje: new Date(today).toLocaleString('pt-br'),
      hojeMenos30: new Date(todayMinus30).toLocaleString('pt-br'),
    });

    let initalDate = moment(todayMinus30).format(dateFormat);
    let finalDate = moment(today).format(dateFormat);
    let result = await this.service.getDataNps(
      initalDate,
      finalDate,
      this.props.dataFilial.length > 0 ? this.props.dataFilial[0].id : 0,
      this.props.companySelected.length > 0 ? this.props.companySelected[0].id : 0
    );

    if (!(result && result.object)) return;
    this.setState({
      npsData: result.object,
    });
  }

  async getYesterdayNPSData() {
    const dateFormat = 'YYYY-MM-DD';
    let yesterday = moment(new Date()).add(-1, 'day');
    let yesterdayMinus30 = moment(new Date()).add(-31, 'day');

    console.log({
      ontem: new Date(yesterday).toLocaleString('pt-br'),
      ontemMenos30: new Date(yesterdayMinus30).toLocaleString('pt-br'),
    });

    let initialDate = moment(yesterdayMinus30).format(dateFormat);
    let finalDate = moment(yesterday).format(dateFormat);

    let result = await this.service.getDataNps(
      initialDate,
      finalDate,
      this.props.dataFilial.length > 0 ? this.props.dataFilial[0].id : 0,
      this.props.companySelected.length > 0 ? this.props.companySelected[0].id : 0
    );

    if (!(result && result.object)) return;
    this.setState({
      yesterdayNPSData: result.object,
    });
  }

  async getCommentsData() {
    const dateFormat = 'YYYY-MM-DD';

    let initDate = new Date('January 1, 2019');

    let initalDate = moment(initDate).format(dateFormat);
    let finalDate = moment(new Date()).format(dateFormat); 

    let result = await new CommentsService().getDataFeedbacks(
      initalDate,
      finalDate,
      this.props.dataFilial.length > 0 ? this.props.dataFilial[0].id : 0,
      this.props.companySelected.length > 0 ? this.props.companySelected[0].id : 0
    );

    if (!(result && result.object)) return;

    this.setState(
      {
        commentsData: {
          ...this.state.commentsData,
          allComments: result.object,
        },
      },
      () => {
        this.getTodayComments(this.state.commentsData.allComments);

        this.setState({
          commentsData: {
            ...this.state.commentsData,
            formatedComments: this.getFormatedComments(
              this.state.commentsData.allComments
            ),
          },
        });
      }
    );
  }

  async listCompany() {
    this.setState({ loading: true });
    const result = await this.service.listCompany();
    console.log('listCompany', result);
    this.props.getDataUserCompany(result.object);

    ////redux data company created
    if (result.object && result.object.length > 0) {
      this.props.setClientFilial({ selected: result.object[0], filial: false });
      const resultFilial = await this.service.listFilial();
      if (resultFilial.object.length > 0 && this.props.companySelected && this.props.companySelected.length > 0) {
        const newDataFilial = resultFilial.object.filter(x => x.idEmpresa == this.props.companySelected[0].id)
        if(newDataFilial) {
          this.setState({ datafilial: newDataFilial });
          this.props.setClientFilial({
            selected: newDataFilial[0],
            filial: true,
          });
        }
      }
    }

    if (
      result.object &&
      result.object.length === 0 &&
      (this.props.location.getFilial &&
        this.props.location.getFilial.object.length === 0)
    ) {
      this.setState({ showPlan: true });
    }

    if (result === undefined) {
      await this.service.doLogout();
      this.props.setCompanySelected([]);
      this.props.getCompanyPlan({});
      this.props.getDataFilial([]);
      this.props.history.push('/login');
    } else {
      this.setState({ loading: false, data: result.object });
      this.props.getDataUserCompany(result.object);
    }
  }

  async listFilial() {
    const result = await this.service.listFilial();
    this.setState({ datafilial: result.object });

    if (result.object.length > 0) {
      this.props.setClientFilial({ selected: result.object[0], filial: true });
    }
  }

  async lisPlans() {
    const result = await this.service.lisPlans();
    console.log('result', result);

    if (result === undefined) {
      await this.service.doLogout();
      this.props.history.push('/login');
      this.props.setCompanySelected([]);
      this.props.getCompanyPlan({});
      this.props.getDataFilial([]);
    } else {
      this.setState({ dataPlan: result.object });
      this.props.getCompanyPlan(result.object);
    }
  }

  async lisPlansAtivos() {
    const result = await this.service.lisPlansAtivos();

    if (result !== undefined && this.state.data.length !== 0) {
      const getPlan = result.object.find(item => {
        return this.state.companyData.id === item.idPlano;
      });
      this.setState({ companyPlan: getPlan });
    } else {
      console.log('error');
    }
  }

  createInvitation = async data => {
    const payload = {
      idEmpresa: data.idEmpresa,
      emailUser: data.email,
    };
    await this.service.createInvitation(payload);
  };

  error = () => {
    Modal.error({
      title: 'This is an error message',
      content: 'some messages...some messages...',
    });
  };

  onChange(item) {
    this.props.history.push({ pathname: '/registerCompany', plan: item });
    this.props.getDataPlans(item);
  }

  handleChange = async value => {
    const { datafilial } = this.state;

    const companyItem = this.props.dataUserCompany.filter(item => {
      return value === item.id;
    });

    //console.log('companyItem', companyItem);
    //this.props.setCompanySelected(companyItem);
    this.lisPlans();

    ////Redux selected company
    //this.props.setClientFilial(companyItem)
    //this.props.setClientFilial({ selected: companyItem[0], filial: false });
    //this.props.getDataFilial([]);

    if (companyItem.length > 0 && companyItem[0].planosInativos.length !== 0) {
      this.setState({ blocked: true, visible: true });
    } else {
      this.setState({ blocked: false, visible: false });
    }

    this.setState({ companyData: companyItem[0] });
    this.getCompanyPlan();

    const result = await this.service.listFilial();

    const newData = result.object.filter(item => {
      return value === item.idEmpresa;
    });
    this.setState({ ...datafilial, datafilial: newData, disabled: false });
    this.getNPSData();
  };

  handleChangeFilial = async value => {
    const result = await this.service.listFilial();

    const filial = result.object.filter(item => {
      return value === item.id;
    });
    this.setState({ companyData: filial[0] });

    //this.props.setClientFilial({ selected: filial[0], filial: true });
    //this.props.getDataFilial(filial);
    //this.props.getIdFilial(filial[0].id);
    this.listCompany();
    this.getNPSData();
    this.getYesterdayNPSData();
    this.getCommentsData();
    //this.setState({ ...datafilial, datafilial: newData, disabled: false })
  };

  getCompanyPlan = async () => {
    if(this.state.companyData && this.state.companyData.length > 0) {
      const result = await this.service.lisPlansAtivos();

      const planCompany = result.object.filter(plan => {
        return this.state.companyData.idPlano === plan.id;
      });

      console.log('planCompany', planCompany);
      this.setState({ planCompany: planCompany[0] });
    }
  };

  handleOk = e => {
    this.setState({
      visible: false,
    });
    this.props.history.push('/login');
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
    this.props.history.push('/login');
  };

  getTodayComments = comments => {
    const isToday = someDate => {
      const today = new Date();
      return (
        someDate.getDate() == today.getDate() &&
        someDate.getMonth() == today.getMonth() &&
        someDate.getFullYear() == today.getFullYear()
      );
    };

    const res = comments.filter(item => {
      return isToday(new Date(item.dataRespostas));
    });

    const currentState = this.state.commentsData;
    const aditionalCommentsData = {
      todayComments: res,
      passives: this.getPassives(res),
      promotors: this.getPromoting(res),
      detractors: this.getDetractors(res),
      todaysFormatedComments: this.getFormatedComments(res),
    };

    this.setState({
      commentsData: {
        ...Object.assign(currentState, aditionalCommentsData),
      },
    });
  };

  getPromoting = comments => {
    return comments.filter(item => item.nps >= 9).length;
  };

  getPassives = comments => {
    return comments.filter(item => item.nps >= 7 && item.nps < 9).length;
  };

  getDetractors = comments => {
    return comments.filter(item => item.nps < 7).length;
  };

  getFormatedComments = comments => {
    const getSpecifiedAnswer = (answerCode, currentComment) => {
      return currentComment.respostas.find(
        item => item.idPergunta === answerCode && item.valorChar !== null
      );
    };

    return comments.reduce(
      (allComments, currentComment) => {
        const suggestion = getSpecifiedAnswer(86, currentComment);
        const complain = getSpecifiedAnswer(87, currentComment);

        if (suggestion) {
          allComments['suggestions'] = [
            ...allComments['suggestions'],
            {
              answer: suggestion.valorChar,
              date: currentComment.dataRespostas,
              name: currentComment.nome,
              phone: currentComment.celular,
              email: currentComment.email,
              nomeFilial: currentComment.nomeFilial
            },
          ];

          allComments.commentCount += 1;
        }

        if (complain) {
          allComments['complains'] = [
            ...allComments['complains'],
            {
              answer: complain.valorChar,
              date: currentComment.dataRespostas,
              name: currentComment.nome,
              phone: currentComment.celular,
              email: currentComment.email,
              nomeFilial: currentComment.nomeFilial
            },
          ];

          allComments.commentCount += 1;
        }

        return allComments;
      },
      { commentCount: 0, suggestions: [], complains: [] }
    );
  };

  getPercentageChange = (yesterday, today) => {
    const points = parseInt(today) - parseInt(yesterday);
    const percentage = Math.round(
      (points / Math.abs(parseInt(yesterday))) * 100
    );
    console.log({
      npsOntem: parseInt(yesterday),
      npsHoje: parseInt(today),
      pontos: points,
      pontosDividoPorOntem: points / Math.abs(yesterday),
      calculoCompleto1: (points / Math.abs(yesterday)) * 100,
      calculoCompleto2: (points * 100) / Math.abs(yesterday),
    });

    if (parseInt(yesterday) === 0) {
      return (points > 0 ? '+' + points : points) + '%';
    }
    return (percentage > 0 ? '+' + percentage : percentage) + '%';
  };

  getArrow(color, direction) {
    const colorSelect = {
      red: ArrowRed,
      green: ArrowGreen,
      neutral: ArrowNeutral,
    };

    console.log(direction, direction === 'down');

    return (
      <img
        src={colorSelect[color]}
        style={
          direction === 'down'
            ? {
                transform: 'rotate(180deg)',
                width: '20px',
              }
            : { width: '20px' }
        }
      />
    );
  }

  render() {
    const { companyPlan, blocked, dataPlan, datafilial } = this.state;
    const clientFilial = this.props.clientFilial.filial;
    const filialData = this.props.clientFilial;

    console.log('companyPlan', this.props.companyPlan);

    const homeClient = {
      home: 'Início',
      user: 'Índice Blue',
      company: 'NPS',
      companyClient: 'Empresa',
      contracts: 'Evoluções',
      plans: 'Avaliações',
      configuration: 'Opções',
      logout: 'Sair',
      iconClient: {
        home: 'home',
        user: 'area-chart',
        company: 'pie-chart',
        companyClient: 'bank',
        contracts: 'line-chart',
        plans: 'message',
        configuration: 'setting',
        logout: 'logout',
      },
    };

    const routesClient = {
      home: 'client',
      user: 'indexBlue',
      company: 'nps',
      companyClient: 'companyClient',
      contracts: 'evolution',
      plans: 'comments',
      configuration: 'settings',
    };

    return (
      <Layout>
        {this.state.showPlan !== true ? (
          <Sidebar
            dataSidebar={homeClient}
            routes={routesClient}
            current="dashboard"
          />
        ) : null}
        <Layout>
          <Header dataUser={this.state.dataUser} />
          <Content
            style={{
              margin: '2px 2px',
            }}
          >
            <Row className="nopadding-col">
              <Col className="saudacao">
                <Breadcrumb pages={[{ title: this.state.userName ? `Olá, ${this.state.userName}.` : '' }]} />
              </Col>
            </Row>
            <br />
            <Spin
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              delay={500}
              spinning={this.state.loading}
              size="large"
            >
              {!companyPlan ? (
                <>
                  <Row>
                    {blocked === true ? (
                      <div>
                        <Col
                          xs={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 24 }}
                          xl={{ span: 24 }}
                          className="content-card"
                          style={{ marginBottom: 10 }}
                        >
                          <h1>Bem-vindo(a) à Blue!</h1>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 24 }}
                          xl={{ span: 24 }}
                          style={{ marginBottom: 10 }}
                        >
                          <Modal
                            style={{ marginTop: 300, maxWidth: 350, top: -30 }}
                            className="buttonNone"
                            title="Sistema bloqueado"
                            visible={this.state.visible}
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}
                          >
                            <h3>
                              Sua conta está temporariamente desativada. Entre
                              em contato com a Blue para voltar a monitorar a
                              Experiência do seu Cliente.
                            </h3>
                            <p>Fone: (61) 9 9953-4691</p>
                            <a
                              href="ajuda@blueexperiencias.com.br"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              http://ajuda@blueexperiencias.com.br
                            </a>
                          </Modal>
                        </Col>
                      </div>
                    ) : (
                      <Row
                        gutter={16}
                        style={{
                          padding: '1rem 0',
                        }}
                      >
                        <>
                          <div className="stats">
                            <div
                              className="statsCard"
                              onClick={() => console.log('this.state')}
                            >
                              <div className="up">
                                <div className="statsIcon">
                                  <img src={sun} alt="Avaliações de hoje" />
                                </div>
                                <span>
                                  {this.props.dataFilial.length === 0
                                    ? '--'
                                    : this.state.commentsData.todayComments
                                        .length}
                                </span>
                                <h3>Avaliações de hoje</h3>
                              </div>
                              <div className="bottom">
                                <div className="between green">
                                  <span>Promotores</span>
                                  <span>
                                    {this.state.commentsData.promotors}
                                  </span>
                                </div>
                                <div className="between yellow">
                                  <span>Neutros</span>
                                  <span>
                                    {this.state.commentsData.passives}
                                  </span>
                                </div>
                                <div className="between red">
                                  <span>Detratores</span>
                                  <span>
                                    {this.state.commentsData.detractors}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="statsCard">
                              <div className="up">
                                <div className="statsIcon">
                                  <img
                                    src={comment}
                                    alt="Comentários de hoje"
                                  />
                                </div>
                                <span>
                                  {this.props.dataFilial.length === 0
                                    ? '--'
                                    : this.state.commentsData
                                        .todaysFormatedComments.commentCount}
                                </span>
                                <h3>Comentários de hoje</h3>
                              </div>
                              <div className="bottom">
                                <div className="between green">
                                  <span>Elogios/Sugestões</span>
                                  <span>
                                    {
                                      this.state.commentsData
                                        .todaysFormatedComments.suggestions
                                        .length
                                    }
                                  </span>
                                </div>
                                <div className="between red">
                                  <span>Críticas/Reclamações</span>
                                  <span>
                                    {
                                      this.state.commentsData
                                        .todaysFormatedComments.complains.length
                                    }
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="statsCard">
                              <div className="up">
                                <div className="statsIcon">
                                  <img src={arrowGrow} alt="Evolução do NPS" />
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '2px',
                                    color:
                                      (this.state.yesterdayNPSData &&
                                        this.state.npsData &&
                                        this.state.npsData.nps -
                                          this.state.yesterdayNPSData.nps <
                                          0 &&
                                        'red') ||
                                      (this.state.yesterdayNPSData &&
                                        this.state.npsData &&
                                        parseInt(
                                          this.state.npsData.nps -
                                            this.state.yesterdayNPSData.nps
                                        ) > 0 &&
                                        '#1bc865'),
                                  }}
                                >
                                  <span
                                    style={{
                                      display: 'flex',
                                      letterSpacing: '-3px',
                                    }}
                                  >
                                    {this.state.yesterdayNPSData &&
                                      this.state.npsData && (
                                        <>
                                          {this.state.yesterdayNPSData &&
                                          this.state.npsData &&
                                          parseInt(
                                            this.state.npsData.nps -
                                              this.state.yesterdayNPSData.nps
                                          ) > 0
                                            ? (this.state.yesterdayNPSData &&
                                                this.state.npsData &&
                                                this.state.npsData.nps -
                                                  this.state.yesterdayNPSData
                                                    .nps <
                                                  0 &&
                                                this.getArrow('red')) ||
                                              (this.state.yesterdayNPSData &&
                                                this.state.npsData &&
                                                parseInt(
                                                  this.state.npsData.nps -
                                                    this.state.yesterdayNPSData
                                                      .nps
                                                ) > 0 &&
                                                this.getArrow('green'))
                                            : (this.state.yesterdayNPSData &&
                                                this.state.npsData &&
                                                this.state.npsData.nps -
                                                  this.state.yesterdayNPSData
                                                    .nps <
                                                  0 &&
                                                this.getArrow('red', 'down')) ||
                                              (this.state.yesterdayNPSData &&
                                                this.state.npsData &&
                                                parseInt(
                                                  this.state.npsData.nps -
                                                    this.state.yesterdayNPSData
                                                      .nps
                                                ) > 0 &&
                                                this.getArrow('green', 'down'))}
                                          {this.state.yesterdayNPSData &&
                                            this.state.npsData &&
                                            Math.abs(
                                              parseInt(this.state.npsData.nps) -
                                                parseInt(
                                                  this.state.yesterdayNPSData
                                                    .nps
                                                )
                                            )}
                                        </>
                                      )}

                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: 'fit-content',
                                        justifyContent: 'flex-end',
                                        paddingBottom: '18px',
                                        letterSpacing: 'normal',
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: '0.8rem',
                                        }}
                                      >
                                        (
                                        {(this.state.yesterdayNPSData &&
                                          this.state.npsData &&
                                          this.getPercentageChange(
                                            this.state.yesterdayNPSData.nps,
                                            this.state.npsData.nps
                                          )) ||
                                          0}
                                        )
                                      </span>
                                      <span
                                        style={{
                                          fontSize: '1.1rem',
                                          lineHeight: '1rem',
                                          color: 'rgba(0, 0, 0, 0.85)',
                                          marginLeft: '2px',
                                        }}
                                      >
                                        {' '}
                                        pontos
                                      </span>
                                    </div>
                                  </span>
                                </div>
                                <h3>Evolução do NPS</h3>
                              </div>
                              <div className="bottom">
                                <div className="between">
                                  <span>NPS ontem</span>
                                  <span>
                                    {this.state.yesterdayNPSData &&
                                      parseInt(this.state.yesterdayNPSData.nps)}
                                  </span>
                                </div>

                                <div className="between">
                                  <span>NPS hoje</span>
                                  <span>
                                    {this.state.npsData &&
                                      parseInt(this.state.npsData.nps)}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="statsCard">
                              <div className="up">
                                <div className="statsIcon">
                                  <img
                                    src={hourglass}
                                    alt="Avaliações de sempre"
                                  />
                                </div>
                                <span>
                                  {this.props.dataFilial.length === 0
                                    ? '--'
                                    : this.state.commentsData.allComments &&
                                      this.state.commentsData.allComments
                                        .length}
                                </span>
                                <h3>Avaliações de sempre</h3>
                              </div>
                              <div
                                className="bottom"
                                style={{ textAlign: 'center' }}
                              >
                                <div>
                                  <span>
                                    Total de opiniões ouvidas até hoje.
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="latestRates">
                            <h2>Últimas avaliações</h2>
                            <div className='rates'>
                              {this.state.commentsData.allComments
                                .sort((a, b) => (a.id > b.id ? -1 : 1))
                                .slice(0, 10)
                                .map((item, index) => (
                                  <div key={`comment-${index}`} className='rate'>
                                    <span className={this.props.filialId === 999999 ? 'span20' : 'span25'}>
                                    {item.dataRespostas ? (
                                        <>
                                          {new Date(
                                            item.dataRespostas
                                          ).toLocaleDateString('pt-br')}
                                          <br />
                                          {new Date(
                                            item.dataRespostas
                                          ).toLocaleTimeString('pt-br')}
                                        </>
                                      ) : (
                                        <i>"Anônimo"</i>
                                      )}
                                    </span>
                                    <span className={this.props.filialId === 999999 ? 'span20' : 'span25'}>
                                      {item.nome ? item.nome : <i>"Anônimo"</i>}
                                    </span>
                                    <span className={this.props.filialId === 999999 ? 'span20' : 'span25'}>
                                      {item.celular ? (
                                        item.celular
                                      ) : (
                                        <i>"Anônimo"</i>
                                      )}
                                    </span>
                                    {
                                      this.props.filialId === 999999 && (
                                        <span className={this.props.filialId === 999999 ? 'span20' : 'span25'}>
                                          {item.nomeFilial}
                                        </span>
                                      )
                                    }
                                    <span className={this.props.filialId === 999999 ? 'span20' : 'span25'}>
                                      <label
                                        className={`classification ${item.nps >=
                                          9 && 'bgGreen'} ${item.nps > 6 &&
                                          item.nps < 9 &&
                                          'bgYellow'} ${item.nps <= 6 && 'bgRed'}`}
                                      >
                                        {item.nps >= 9 && 'Promotor'}
                                        {item.nps > 6 &&
                                          item.nps < 9 &&
                                          'Neutro'}
                                        {item.nps <= 6 && 'Detrator'}
                                      </label>
                                    </span>
                                  </div>
                                ))
                              }
                            </div>
                          </div>

                          <div className="latestComments">
                            <h2>Últimos comentários</h2>
                            <div
                              style={{
                                overflowX: 'auto',
                              }}
                            >
                              <div className="positive">
                                <p>Elogios / Sugestões</p>

                                {this.state.commentsData.formatedComments
                                  .suggestions &&
                                  this.state.commentsData.formatedComments.suggestions
                                    .sort((a, b) => (a.id > b.id ? -1 : 1))
                                    .slice(0, 3)
                                    .map(item => (
                                      <div className="phrases">
                                        <span className="phrase">
                                          "{item.answer}"
                                        </span>
                                        <div className="userData">
                                          <span>{item.name}</span>
                                          <span>
                                            {new Date(item.date).toLocaleString(
                                              'pt-br'
                                            )}
                                          </span>
                                          <span>
                                            {item.phone ? (
                                              item.phone
                                            ) : (
                                              <i>"Anônimo"</i>
                                            )}
                                          </span>
                                          <span>
                                            {item.email ? (
                                              item.email
                                            ) : (
                                              <i>"Anônimo"</i>
                                            )}
                                          </span>
                                          <span>
                                            {
                                              this.props.filialId === 999999 && (
                                                  item.nomeFilial
                                              )
                                            }
                                          </span>
                                        </div>
                                      </div>
                                    ))}
                              </div>
                              <div className="negative">
                                <p>Críticas / Reclamações</p>
                                {this.state.commentsData.formatedComments
                                  .complains &&
                                  this.state.commentsData.formatedComments.complains
                                    .sort((a, b) => (a.id > b.id ? -1 : 1))
                                    .slice(0, 3)
                                    .map(item => (
                                      <div className="phrases">
                                        <span className="phrase">
                                          "{item.answer}"
                                        </span>
                                        <div className="userData">
                                          <span>{item.name}</span>
                                          <span>
                                            {new Date(item.date).toLocaleString(
                                              'pt-br'
                                            )}
                                          </span>
                                          <span>
                                            {item.phone ? (
                                              item.phone
                                            ) : (
                                              <i>"Anônimo"</i>
                                            )}
                                          </span>
                                          <span>
                                            {item.email ? (
                                              item.email
                                            ) : (
                                              <i>"Anônimo"</i>
                                            )}
                                          </span>
                                          <span>
                                            {
                                              this.props.filialId === 999999 && (
                                                  item.nomeFilial
                                              )
                                            }
                                          </span>
                                        </div>
                                      </div>
                                    ))}
                              </div>
                            </div>
                          </div>
                        </>
                      </Row>
                    )}
                  </Row>
                </>
              ) : (
                <Row
                  gutter={16}
                  style={{ height: '100vh', backgroundColor: '#FFF' }}
                >
                  <Col
                    md={{ span: 24 }}
                    xs={{ span: 24 }}
                    style={{ marginBottom: 10 }}
                    className="content-card"
                  >
                    <h1>Bem vindo à Blue!</h1>
                  </Col>
                  {this.state.showPlan ? (
                    <Content className="content card-header">
                      <div style={{ background: '#FFF', padding: '30px' }}>
                        <Row gutter={16}>
                          {dataPlan.map((item, index) => (
                            <Col key={item.id} lg={8} sm={12} xs={24}>
                              <Card
                                style={{
                                  marginTop: 10,
                                  backgroundColor: '#ECECEC',
                                }}
                                title={item.nome}
                                actions={[
                                  <Button
                                    type="primary"
                                    onClick={() => this.onChange(item)}
                                  >
                                    Contratar
                                  </Button>,
                                ]}
                                bordered={false}
                              >
                                <p>{item.funcionalidadePlano[0].text}</p>
                                <p>{`Quantidade de usuarios: ${item.qtdUsuarios}`}</p>
                                <p>{`Quantidade de respostas: ${item.qtdRespostas}`}</p>
                                <p>{`Valor do Plano: R$ ${item.valor}`}</p>
                              </Card>
                            </Col>
                          ))}
                        </Row>
                      </div>
                    </Content>
                  ) : null}
                </Row>
              )}
            </Spin>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

HomeClient.propTypes = {
  history: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUserData,
      getDataUserCompany,
      getDataPlans,
      getDataFilial,
      setClientFilial,
      getIdFilial,
      setCompanySelected,
      getCompanyPlan,
    },
    dispatch
  );

const mapStateToProps = store => ({
  userData: store.userData.data,
  dataUserCompany: store.userData.dataUserCompany,
  dataFilial: store.userData.dataFilial,
  clientFilial: store.userData.clientFilial,
  companySelected: store.userData.companySelected,
  companyPlan: store.userData.companyPlan,
  filialId: store.userData.filialId,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HomeClient));
