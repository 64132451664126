import Services from '../../services';

export default class Service extends Services {
  createPassword = async (_body) => {
    try {
      const response = await fetch(`${this.endPoint}Login/confirmaSenha`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(_body),
      });
      const json = await response.json();

      return json;
    } catch (err) {
      console.log('Error problem', err);
      this.doLogout()
    }
  };
}
