import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as jwt_decode from 'jwt-decode';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getDataPlans, getUserModal } from '../../redux/actions';
import {
  Layout,
  Row,
  Col,
  Card,
  Collapse,
  AutoComplete,
  Tag,
  Button,
  Switch,
  Table,
  Spin
} from 'antd';

import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Breadcrumb from '../../components/Breadcrumb';

import Service from './Service';
import './styles.css';

const { Content } = Layout;
const Panel = Collapse.Panel;

class ListCompany extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      data: [],
      loading: false,
      visible: false,
      dataAutoComplete: [],
      dataComplete: [],
    };

    this.service = new Service();
    this.listCompany = this.listCompany.bind(this);
    this.updateUser = this.updateUser.bind(this);
  }

  async componentDidMount() {
    if (!this.service.isLogged()) {
      this.props.history.push('/login');
    } else {
      const tokenLogin = localStorage.getItem('token');
      const decodeToken = jwt_decode(tokenLogin);
      this.setState({
        role: decodeToken.role,
        dataUser: decodeToken,
      });
      this.listCompany();
      this.props.getUserModal({});
    }
  }

  async listCompany() {
    this.setState({ loading: true })
    const result = await this.service.listCompany();
    console.log("result", result)

    const dataAutoComplete = result.object.map(nome => {
      return nome.nome;
    });

    this.setState({
      dataAutoComplete,
      dataComplete: result.object,
      companyData: result.object,
    });
    this.listCompanyFilial();

    if (result === undefined) {
      await this.service.doLogout();
      this.props.history.push('/login');
    } else {
      let newData = result.object.map(item => {
        return {
          ...item,
          situacao: item.ativa ? 'Ativa' : 'Desativada',
          planoSituacao:
            item.planosInativos.length === 0 ? 'Ativo' : 'Desativado',
        };
      });
      this.setState({ data: newData });
    }
    this.setState({ loading: false })
  }

  async listCompanyFilial() {
    const result = await this.service.listCompanyFilial();
    this.setState({ dataFilial: result.object });
  }

  async updateUser(data) {
    if (data) {
      await this.service.updateUser(data.id, data);
      this.listUser();
    }
  }

  async activeUser(id) {
    await this.service.activeUser(id);
  }

  async deleteAdmin(id) {
    const result = await this.service.deleteUser(id);
    this.setState({ data: result.object }, () => {
      this.listUser();
    });
  }

  async updateAdmin(id, record) {
    const payloadModal = {
      visible: true,
      data: record,
    };
    this.props.getUserModal(payloadModal);
  }

  async updatePasswordAdmin(id) {
    this.props.history.push(`/administrators/changepass/${id}`);
  }

  openModal(item) {
    this.setState({
      idPlan: item.id,
      plan: item,
    });

    const payloadModal = {
      visible: true,
      data: item,
    };
    this.props.getModalTrue(payloadModal);
    this.props.getTitleModal('Editar Plano');
  }

  onChangeChecked = async (planId, record) => {
    const activePlan = this.state.data.map(plan => {
      return plan.id === planId ? { ...plan, ativa: !plan.ativa } : plan;
    });

    const filterActivePlan = activePlan.filter(plan => {
      return plan.id === planId;
    });

    if (record.idVinculoPlano === 0) {
      const payload = {
        idVinculoPlano: filterActivePlan[0].planosInativos[0].idVinculoPlano,
        motivo: 'Falta de pagamento',
      };

      await this.service.updateCompanyPlan(activePlan.idVinculoPlano, payload);

      this.listCompany();
    } else {
      const payload = {
        idVinculoPlano: filterActivePlan[0].idVinculoPlano,
        motivo: 'Falta de pagamento',
      };

      await this.service.updateCompanyPlan(activePlan.idVinculoPlano, payload);
      this.listCompany();
    }
  };

  changeSelectUser = value => {
    const userName = this.state.data.map(item => {
      return item;
    });

    const filterUser = userName.filter(item => item.nome === value);
    this.setState({ data: filterUser });
  };

  onSearchUser = value => {
    if (value === '') {
      this.setState({ data: this.state.dataComplete });
    }
  };

  onChangeAll = () => {
    this.listCompany();
  };

  onChangeActive = () => {
    const filterTrue = this.state.data.filter(item => {
      return item.ativa === true;
    });
    this.setState({ data: filterTrue });
  };

  onChangeNotActive = () => {
    const filterFalse = this.state.data.filter(item => {
      return item.ativa === false;
    });
    this.setState({ data: filterFalse });
  };

  render() {
    const { companyData, data, dataAutoComplete, dataFilial } = this.state;

    const home = {
      home: 'Início',
      user: 'Usuários',
      company: 'Empresas',
      contracts: 'Contrato',
      plans: 'Planos',
      logout: 'Sair',
      iconClient: {
        home: 'dashboard',
        user: 'user',
        company: 'bank',
        contracts: 'profile',
        plans: 'tag',
        logout: 'logout',
      },
    };

    const routesClient = {
      home: '/',
      user: 'administrators',
      company: 'companys',
      contracts: 'contracts',
      plans: 'plans',
    };

    const columns = [
      {
        title: 'Empresa',
        dataIndex: 'nome',
        key: 'nome',
      },
      {
        title: 'Responsável',
        dataIndex: 'pessoas[0].nome',
        key: 'pessoas[0].nome',
      },
      {
        title: 'Telefone',
        dataIndex: 'pessoas[0].telefones[0].numero',
        key: 'pessoas[0].telefones[0].numero',
      },
      {
        title: 'Cpf',
        dataIndex: 'pessoas[0].cpfcnpj',
        key: 'pessoas[0].cpfcnpj',
      },
      {
        title: 'E-mail',
        dataIndex: 'pessoas[0].email',
        key: 'pessoas[0].email',
      },
      {
        title: 'Segmento',
        dataIndex: 'descricaoSegmento',
        key: 'descricaoSegmento',
      },
      {
        title: 'Respostas',
        dataIndex: 'numeroRespostas',
        key: 'numeroRespostas',
      },
      {
        title: 'Situação do Plano',
        dataIndex: 'id',
        key: 'id',
        render: (dataIndex, record) => (
          <span>
            {record.ativa === true ? (
              <Tag color="green">Ativo</Tag>
            ) : (
              <Tag color="red">Desativado</Tag>
            )}
            <Switch
              checked={record.ativa}
              onChange={() => this.onChangeChecked(dataIndex, record)}
            />
          </span>
        ),
      },
    ];

    return (
      <Layout>
        <Sidebar dataSidebar={home} routes={routesClient} current="companys" />
        <Layout>
          <Header dataUser={this.state.dataUser} />
          <Row className="nopadding-col">
            <Col md={{ span: 8 }} xs={{ span: 24 }}>
              <Breadcrumb pages={[{ title: 'Empresas' }]} />
            </Col>
            <Col md={{ span: 8 }} xs={{ span: 24 }}>
              <div className="new-item">
                <AutoComplete
                  allowClear
                  style={{ width: 200 }}
                  dataSource={dataAutoComplete}
                  placeholder="Nome da empresa"
                  filterOption={(inputValue, option) =>
                    option.props.children
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                  onSelect={value => this.changeSelectUser(value)}
                  onSearch={value => this.onSearchUser(value)}
                />
              </div>
            </Col>
          </Row>
          <Spin
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            delay={500}
            spinning={this.state.loading}
            size="large"
          >
            <Content className="content">
              <div style={{ background: '#FFF', padding: '30px' }}>
                <Row className="nopadding-col" style={{ paddingBottom: 20 }}>
                  <Col md={{ span: 4 }} xs={{ span: 8 }}>
                    <Button type="primary" onClick={this.onChangeAll}>
                      Todas
                    </Button>
                  </Col>
                  <Col md={{ span: 4 }} xs={{ span: 8 }}>
                    <Button type="primary" onClick={this.onChangeActive}>
                      Ativas
                    </Button>
                  </Col>
                  <Col md={{ span: 6 }} xs={{ span: 8 }}>
                    <Button type="primary" onClick={this.onChangeNotActive}>
                      Inativas
                    </Button>
                  </Col>
                </Row>
                <Table
                  className="table"
                  columns={columns}
                  dataSource={data}
                  rowKey={item => item.id}
                  scroll={{ x: 500 }}
                  //rowClassName={record => this.changeTeste(record)}
                />

                <div>
                  <h1>Empresas / Filiais / QRcode</h1>
                  <Collapse accordion>
                    {companyData &&
                      companyData.map(item => {                      
                        return (
                          <Panel header={`${item.nome}`} key={item.id}>
                            <Card style={{ maxWidth: 320, marginBottom: 20 }}>
                              <h1>INFORMAÇÕES OU RESPONSÁVEL</h1>
                              <p>{`Responsavel: ${item &&
                                item.pessoas && item.pessoas.length > 0 ?
                                item.pessoas[0].nome : ''}`}</p>
                                <p>{`CNPJ: ${item &&
                                item.cpfcnpj}`}</p>
                              <p>{`Plano contratado: ${item.descricaoPlano}`}</p>
                              <p>{`Estado: ${item.estado}`}</p>
                              <p>{`Cidade: ${item.cidade}`}</p>
                              <p>{`Endereço: ${item.endereco}`}</p>
                              <p>{`Telefone: ${(item &&
                              item.pessoas &&                              
                              item.pessoas.length > 0 && item.pessoas[0].telefones.length > 0) ? item.pessoas[0].telefones[0].numero : '' }`}</p>
                              <p>{`Email: ${item &&
                              item.email}`}</p>
                            </Card>
                            <Collapse
                              defaultActiveKey="1"
                              onChange={this.callback}
                            >
                              {dataFilial &&
                                dataFilial
                                  .filter(_item => {
                                    return _item.idEmpresa === item.id;
                                  })
                                  .map(_item => {
                                    return (
                                      <Panel
                                        header={`${_item.nome}`}
                                        key={_item.id}
                                      >
                                        <Row>
                                          <Col
                                            xs={{ span: 24 }}
                                            sm={{ span: 24 }}
                                            md={{ span: 24 }}
                                            lg={{ span: 10 }}
                                          >
                                            <Card style={{ maxWidth: 320 }}>
                                              <p>{`Estado: ${_item.estado}`}</p>
                                              <p>{`Cidade: ${_item.cidade}`}</p>
                                              <p>{`Endereço: ${_item.endereco}`}</p>
                                              <p>{`Telefone: ${_item.telefones ? _item.telefones[0].numero : ''}`}</p>
                                            </Card>
                                          </Col>
                                          <Col
                                            xs={{ span: 24 }}
                                            sm={{ span: 24 }}
                                            md={{ span: 10, offset: 2 }}
                                            lg={{ span: 10, offset: 2 }}
                                          >
                                            {!this.state.showLink ? (
                                              <div>
                                                <Row
                                                  style={{
                                                    textAlign: 'center',
                                                    marginTop: 60,
                                                  }}
                                                >
                                                  <Col>
                                                    <h2>
                                                      Link para compartilhar o
                                                      Questionario:
                                                    </h2>
                                                  </Col>
                                                  <Col>
                                                    <a
                                                      href={`http://app.blueexperiencias.com.br/blue/questionario/${item.id}/${_item.id}`}
                                                      rel="noopener noreferrer"
                                                      target="_blank"
                                                    >
                                                      http://app.blueexperiencias.com.br/blue/questionario/
                                                      {item.id}/{_item.id}
                                                    </a>
                                                  </Col>
                                                </Row>
                                              </div>
                                            ) : null}
                                          </Col>
                                        </Row>
                                      </Panel>
                                    );
                                  })}
                            </Collapse>
                          </Panel>
                        );
                      })}
                  </Collapse>
                </div>
              </div>
            </Content>
          </Spin>
        </Layout>
      </Layout>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getDataPlans, getUserModal }, dispatch);

const mapStateToProps = store => ({
  dataPlans: store.userData.dataPlans,
  userModal: store.userData.userModal,
  userDataUpdate: store.userData.userDataUpdate,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ListCompany));
