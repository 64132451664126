export default class Service {
  constructor() {
    if (window.location.hostname === 'localhost') {
      //this.endPoint = 'https://api.blueexperiencias.com.br/api/';
      this.endPoint = `http://${window.location.hostname}:8080/api/`;
      this.endPointQuestionario = `http://${window.location.host}/blue/questionario`;
    } else {
      this.endPoint = 'https://api.blueexperiencias.com.br/api/';
      this.endPointQuestionario = `https://app.blueexperiencias.com.br/blue/questionario`;
    }
  }

  isLogged = () =>
    localStorage.getItem('token') !== null &&
    localStorage.getItem('token') !== 'null' &&
    localStorage.getItem('token') !== '';

  doLogout = () => {
    localStorage.setItem('token', 'null');
    localStorage.setItem('user', 'null');

    window.location = '/login';
  };

  async getCEP(cep) {
    cep = cep.replace('.', '').replace('-', '');
    try {
      const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`, {
        method: 'get',
      });
      const responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  acceptInvite = async body => {
    const tokenLogin = localStorage.getItem('token');
    try {
      const response = await fetch(`${this.endPoint}Convite`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      const json = await response.json();

      return json;
    } catch (err) {
      console.log('Error problem', err);
      this.doLogout();
    }
  };

  userInvitation = async () => {
    const tokenLogin = localStorage.getItem('token');
    try {
      const response = await fetch(`${this.endPoint}Convite`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'text/plain',
        },
      });

      const json = await response.json();

      return json;
    } catch (err) {
      console.log('Error problem', err);
      this.doLogout();
    }
  };

  getCompany = async tokenValue => {
    const tokenLogin = localStorage.getItem('token');
    try {
      const response = await fetch(`${this.endPoint}Empresa`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'text/plain',
        },
      });

      const json = await response.json();

      return json;
    } catch (err) {
      console.log('Error problem', err);
      this.doLogout();
    }
  };

  getFilial = async () => {
    const tokenLogin = localStorage.getItem('token');
    try {
      const response = await fetch(`${this.endPoint}Filial`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'text/plain',
        },
      });

      const json = await response.json();

      return json;
    } catch (err) {
      console.log('Error problem', err);
      this.doLogout();
    }
  };

  getAppVersion = async () => {
    const tokenLogin = localStorage.getItem('token');
    try {
      const response = await fetch(`${this.endPoint}Versao`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'application/json',
        },
      });

      const json = await response.json();

      return json;
    } catch (err) {
      console.warn('Erro ao obter versão do aplicativo', err);
    }
  };

  getToken = () => localStorage.getItem('token');

  setToken = token => localStorage.setItem('token', token);

  getLoggedUser = () => JSON.parse(localStorage.getItem('user'));

  setLoggedUser = u => localStorage.setItem('user', JSON.stringify(u));

  getGruposUsuario = async () => {
    const tokenLogin = localStorage.getItem('token');
    try {
      const response = await fetch(`${this.endPoint}Empresa/listar-grupos-usuario`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'text/plain',
        },
      });

      const json = await response.json();

      return json;
    } catch (err) {
      console.log('Error problem', err);
      this.doLogout();
    }
  }

  listFilial = async (tokenValue) => {
    const tokenLogin = localStorage.getItem('token');
    try {
      const response = await fetch(`${this.endPoint}Filial`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'text/plain',
        },
      });

      const json = await response.json();

      return json;
    } catch (err) {
      console.log('Error problem', err);
      this.doLogout()
    }
  };

  getCompanyById = async (id) => {
    const tokenLogin = localStorage.getItem('token');
    try {
      const response = await fetch(`${this.endPoint}Empresa/${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'text/plain',
        },
      });

      const json = await response.json();

      return json;
    } catch (err) {
      console.log('Error problem', err);
      this.doLogout()
    }
  };

  getFilialId = async (id) => {
    const tokenLogin = localStorage.getItem('token');
    try {
      const response = await fetch(`${this.endPoint}Filial/${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'text/plain',
        },
      });

      const json = await response.json();

      return json;
    } catch (err) {
      console.log('Error problem', err);
      this.doLogout()
    }
  };
}
