import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as jwt_decode from 'jwt-decode';
import { withRouter } from 'react-router-dom';
import { Layout, Row, Col, Card } from 'antd';
import ReactHighcharts from 'react-highcharts';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUserData } from '../../redux/actions';

import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Breadcrumb from '../../components/Breadcrumb';

import Service from './Service';

import './styles.css';

const { Content } = Layout;

var config1 = {
  title: {
    text: 'Índice Blue',
  },
  yAxis: {
    title: 'Totais',
  },
  xAxis: {
    categories: ['01/01', '02/01', '03/01', '04/01', '05/01', '06/01', '07/01'],
  },
  credits: {
    enabled: false,
  },
  series: [
    {
      name: 'Positivos',
      color: '#2aa1bb',
      data: [120, 134, 103, 126, 84, 93, 135],
    },
    {
      name: 'Negativos',
      color: '#0cd28f',
      data: [65, 86, 73, 105, 94, 53, 95],
    },
  ],
};

class Evolution extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      data: {},
    };

    this.service = new Service();
  }

  componentDidMount() {
    if (!this.service.isLogged()) {
      this.props.history.push('/login');
    } else {
      const token = this.props.userData.object.token;
      const decodeToken = jwt_decode(token);
      this.setState({
        role: decodeToken.role,
        dataUser: decodeToken,
      });
    }
  }

  render() {
    const homeClient = {
      home: 'Início',
      user: 'Índice Blue',
      company: 'NPS',
      companyClient: 'Empresa',
      contracts: 'Evoluções',
      plans: 'Avaliações',
      configuration: 'Opções',
      logout: 'Sair',
      iconClient: {
        home: 'home',
        user: 'area-chart',
        company: 'pie-chart',
        companyClient: 'bank',
        contracts: 'line-chart',
        plans: 'message',
        configuration: 'setting',
        logout: 'logout',
      },
    };

    const routesClient = {
      home: 'client',
      user: 'indexBlue',
      company: 'nps',
      companyClient: 'companyClient',
      contracts: 'evolution',
      plans: 'comments',
      configuration: 'settings',
    };

    return (
      <Layout>
        <Sidebar
          dataSidebar={homeClient}
          routes={routesClient}
          current="dashboard"
        />
        <Layout>
          <Header dataUser={this.state.dataUser} />

          <Content
            style={{
              margin: '24px 16px',
              padding: 24,
            }}
          >
            <Row className="nopadding-col">
              <Col xs={{ span: 24 }} md={{ span: 16 }}>
                <Breadcrumb pages={[{ title: 'Evoluções' }]} />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }}>
                <Card title="Blue ativos" className="tcenter">
                  <h1>Blue dados</h1>
                </Card>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }}>
                <Card title="Blue registrados" className="tcenter">
                  <h1>Blue dados</h1>
                </Card>
              </Col>
            </Row>
            <Row gutter={10} style={{ marginBottom: 50, marginTop: 30 }}>
              <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                <ReactHighcharts config={config1} />
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

Evolution.propTypes = {
  history: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getUserData }, dispatch);

const mapStateToProps = store => ({
  userData: store.userData.data,
  clientFilial: store.userData.clientFilial,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Evolution));
