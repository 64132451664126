import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Home from './pages/Home';
import Login from './pages/Login';
import CreatePassword from './pages/CreatePassword';
import RecoverPassword from './pages/RecoverPassword';
import Forgot from './pages/Login/Forgot';
import Register from './pages/Register';
import ListUsers from './pages/ListUsers';
import ListContract from './pages/Contract';
import ListCompany from './pages/Company';
import CompanyClient from './pages/CompanyClient';
import HomeClient from './pages/Home/HomeClient'

import Comments from './pages/Comments'
import Evolution from './pages/Evolution'
import IndexBlue from './pages/IndexBlue'
import Nps from './pages/Nps'
import SettingsBlue from './pages/SettingsBlue'
// import ModalContracts from './components/ModalContracts';
import ListPlans from './pages/Plans';
import RegisterCompany from './pages/RegisterCompany';
import PlansChoice from './pages/PlansChoice';
import Invitation from './pages/Invitation';

import Quiz from './pages/Quiz';
import QuizSteps from './pages/QuizSteps';
import QuizThanks from './pages/QuizThanks'

import Main from './pages/Main';

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/main" exact component={Main} />
      <Route path="/blue/questionario/:idEmpresa/:idFilial" exact component={Quiz} />
      <Route path="/blue/questionario/:idEmpresa/:idFilial/steps" exact component={QuizSteps} />
      {/* <Route path="/blue/questionario/:idFilial" exact component={QuizSteps} /> */}
      <Route path="/blue/questionario/:idEmpresa/:idFilial/obrigado/:idBeneficio?" exact component={QuizThanks} />
      <Route path="/invitation" exact component={Invitation} />
      <Route path="/login" exact component={Login} />
      <Route path="/forgot" exact component={Forgot} />
      <Route path="/register" exact component={Register} />
      <Route path="/client" exact component={HomeClient} />
      {/* <Route path="/register/:id/:guid" exact component={CreatePassword} /> */}
      <Route path="/createPassword/:id/:guid" exact component={CreatePassword} />
      <Route path="/createPassword" exact component={CreatePassword} />
      <Route path="/RecoverPassword" exact component={RecoverPassword} />
      <Route path="/RecoverPassword/:id/:guid" exact component={RecoverPassword} />
      <Route path="/administrators" exact component={ListUsers} />
      <Route path="/companys" exact component={ListCompany} />
      <Route path="/contracts" exact component={ListContract} />
      <Route path="/plans" exact component={ListPlans} />
      <Route path="/registerCompany" exact component={RegisterCompany} />
      <Route path="/plansChoice" exact component={PlansChoice} />
      <Route path="/companyClient" exact component={CompanyClient} />

      <Route path="/comments" exact component={Comments} />
      <Route path="/indexBlue" exact component={IndexBlue} />
      <Route path="/nps" exact component={Nps} />
      <Route path="/evolution" exact component={Evolution} />
      <Route path="/settings" exact component={SettingsBlue} />
      {/* <Route path="/modal" exact component={ModalContracts} /> */}
    </Switch>
  </BrowserRouter>
);

export default Routes;
