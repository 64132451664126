import React, { Component } from 'react';
import { Modal, Button, Input, Select } from 'antd';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getModalTrue, getTitleModal } from '../../redux/actions';

class ModalContracts extends Component {
  constructor(props) {
    super(props);    

    this.state = {
      ModalText: 'Novo Plano',
      visible: false,
      confirmLoading: false,
      dataModal: {
        contratoId: '',
        idPlan: '',
        nomePlano: '',
        title: '',
        numberContrato: '',
        valorPlano: '',
        qtdRespostas: '',
        qtdUsuarios: '',
        valorFunctions: '',
        description: '',
        contractNumber: 0,
        valuePlan: ''
      },
      loading: false,
      modalTitle: '',
      buttonTitle: ''
    };
  }

  componentDidMount() {
    this.setState({
      modalTitle: this.props.titleModal,
      buttonTitle: this.props.titleButton
    })
  }

  componentWillReceiveProps(props) {
    if(props.modalState && props.modalState.visible === true) {
      this.setState({
        visible: props.modalState.visible,
        dataModal: {
          contratoId: props.modalState.data.id,
          idPlan: props.modalState.data.id,
          nomePlano: props.modalState.data.nome,
          title: props.modalState.data.titulo,
          numberContrato: '', // @todo probably useless
          valorPlano: props.modalState.data.valor,
          qtdRespostas: props.modalState.data.qtdRespostas,
          qtdUsuarios: props.modalState.data.qtdUsuarios,
          valorFunctions: '',
          description: props.modalState.data.descricao,
          contractNumber: props.modalState.data.idContrato,
        },
      })
    } else {
      this.setState({
        visible: false
      })
    }
  }

  showModal = () => {
    this.setState({
      visible: true,
      dataModal: {
        contratoId: '',
        idPlan: '',
        nomePlano: '',
        title: '',
        numberContrato: '',
        valorPlano: '',
        qtdRespostas: '',
        qtdUsuarios: '',
        valorFunctions: '',
        description: '',
        contractNumber: '',
      },
    });
  };

  handleOk = () => {
    this.setState({
      // ModalText: 'The modal will be closed after two seconds',
      confirmLoading: true,
    });
    setTimeout(() => {
      this.setState({
        visible: false,
        confirmLoading: false,
      });

      this.onChange();
      this.props.getTitleModal();
      this.props.getModalTrue(false);

    }, 2000);
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
    this.props.getModalTrue();
    this.props.getTitleModal();
  };

  onSelectedFunction = (value, key) => {
    this.setState({ textPlan: key.key, valuePlan: value })
  }

  onChange = async () => {
    const {      
      textPlan,      
      dataModal,
      valuePlan,
    } = this.state;
    const { onCreateContract, onCreatePlans } = this.props;

    if(onCreateContract) {
      if(dataModal.contratoId) {
        const payloadEditContract = {
          id: dataModal.contratoId,
          titulo: dataModal.title,
          descricao: dataModal.description,
        };
        onCreateContract(payloadEditContract)
      } else {
        const payloadEditContract = {
          id: dataModal.contratoId,
          titulo: dataModal.title,
          descricao: dataModal.description,
        };

        onCreateContract(payloadEditContract)
      }

    } else if (onCreatePlans) {
      const payload = {
        id: this.state.dataModal.idPlan,
        idContrato: this.state.dataModal.contractNumber,
        nome: this.state.dataModal.nomePlano,
        valor: parseFloat(this.state.dataModal.valorPlano),
        qtdRespostas: parseInt(this.state.dataModal.qtdRespostas),
        qtdUsuarios: parseInt(this.state.dataModal.qtdUsuarios),
        funcionalidadePlano: [
          {
            value: parseInt(valuePlan),
            text: textPlan,
          },
        ],
      };
      onCreatePlans(payload);
    }
  };

  render() {
    const { TextArea } = Input;
    const { contractId } = this.props;
    const Option = Select.Option;    

    const {
      visible,
      confirmLoading,      
      buttonTitle,
      dataModal,
    } = this.state;

    return (
      <div>
        <Button type="primary" className="btn-add" icon="plus" onClick={this.showModal}>
          {buttonTitle}
        </Button>
        <Modal
          title={this.props.titleModal || buttonTitle}
          visible={visible}
          onOk={this.handleOk}
          confirmLoading={confirmLoading}
          onCancel={this.handleCancel}
          centered
        >
          {/* <p>{ModalText}</p> */}
          <div>
            {this.props.onCreateContract ? (
              <form className="form">
                <Input
                  placeholder={"Titulo"}
                  label="tetseeee"
                  value={dataModal.title}
                  style={{ marginBottom: 20 }}
                  onChange={(e) => {
                    this.setState({
                      dataModal: {...this.state.dataModal, title: e.target.value},
                    });
                  }}
                  addonBefore="Titulo"
                />

                <TextArea
                  placeholder={"Descrição"}
                  value={dataModal.description}
                  style={{ height: 300 }}
                  onChange={(e) => {
                    this.setState({
                      dataModal: {...this.state.dataModal, description: e.target.value},
                    });
                  }}
                />
              </form>
            ) : (
              <form className="form">
                <Select
                  style={{ marginBottom: 20 }}
                  placeholder="Selecione um contrato"
                  onChange={(value) => {
                      this.setState({
                        dataModal: {...this.state.dataModal, contractNumber: value},
                      });
                  }}
                  value={dataModal.contractNumber || "Selecione um contrato"}
                >
                  {contractId.map(item => (
                    <Option key={item.id} value={item.id}>{item.titulo}</Option>
                  ))}
                </Select>
                <Input
                  placeholder="Nome do plano"
                  value={dataModal.nomePlano}
                  style={{ marginBottom: 20 }}
                  onChange={(e) => {
                    this.setState({
                      dataModal: {...this.state.dataModal, nomePlano: e.target.value},
                    });
                  }}
                  addonBefore="Nome do Plano"
                />
                <Input
                  placeholder="Valor do plano"
                  type="number"
                  value={dataModal.valorPlano}
                  style={{ marginBottom: 20 }}
                  addonBefore="Valor do plano"
                  onChange={(e) => {
                    this.setState({
                      dataModal: {...this.state.dataModal, valorPlano: e.target.value},
                    });
                  }}

                />
                <Input
                  placeholder="Quantidade de respostas"
                  type="number"
                  value={dataModal.qtdRespostas}
                  style={{ marginBottom: 20 }}
                  onChange={(e) => {
                    this.setState({
                      dataModal: {...this.state.dataModal, qtdRespostas: e.target.value},
                    });
                  }}
                  addonBefore="Quantidade de respostas"
                />
                <Input
                  placeholder="Quantidade de usuarios"
                  type="number"
                  value={dataModal.qtdUsuarios}
                  style={{ marginBottom: 20 }}
                  onChange={(e) => {
                    this.setState({
                      dataModal: {...this.state.dataModal, qtdUsuarios: e.target.value},
                    });
                  }}
                  addonBefore="Quantidade de usuarios"
                />
                {/* <Select
                  style={{ marginBottom: 20 }}
                  defaultValue={this.state.textPlan && (this.state.textPlan || "Funções do plano")}
                  onChange={(value, key) => this.onSelectedFunction(value, key)}
                >
                  {dataPlansFunctions && dataPlansFunctions.object && dataPlansFunctions.object.map(item => (
                    <Option value={item.value} key={item.text}>{item.text}</Option>
                  ))}
                </Select>
                <TextArea
                  placeholder={(this.props.dataPlan && this.props.dataPlan.funcionalidadePlano[0].text) || "Descrição do plano"}
                  value={description}
                  style={{ height: 200 }}
                  onChange={(e) => {
                    this.setState({
                      description: e.target.value,
                    });
                  }}
                /> */}
              </form>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ getModalTrue, getTitleModal }, dispatch);
const mapStateToProps = store => ({
  modalState: store.userData.modalState,
  contractId: store.userData.contractId,
  titleModal: store.userData.titleModal,
  dataPlansFunctions:  store.userData.dataPlansFunctions
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalContracts);
