import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import * as jwt_decode from 'jwt-decode';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getDataPlans } from '../../redux/actions';

import { Layout, Card, Col, Row, Button } from 'antd';

import Header from '../../components/Header';
import Breadcrumb from '../../components/Breadcrumb';
import Sidebar from '../../components/Sidebar';

import Service from './Service';
import './styles.css';

const { Content } = Layout;

class PlansChoice extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      data: [],
    };

    this.service = new Service();
    this.lisPlans = this.lisPlans.bind(this);
  }

  async componentDidMount() {
    if (!this.service.isLogged()) {
      this.props.history.push('/login');
    } else {
      const token = this.props.userData.object.token;
      const decodeToken = jwt_decode(token);
      this.setState({
        role: decodeToken.role,
        dataUser: decodeToken,
        visible: false,
      });

      this.lisPlans();
    }
  }

  async lisPlans() {
    const result = await this.service.lisPlans();

    if (result === undefined) {
      await this.service.doLogout();
      this.props.history.push('/login');
    } else {
      this.setState({ data: result.object });
    }
  }

  onChange(item) {
    console.log('item', item);
    this.props.history.push({ pathname: '/registerCompany', plan: item });
    this.props.getDataPlans(item);
  }

  render() {
    const { data } = this.state;

    const homeClient = {
      home: 'Início',
      user: 'Índice Blue',
      company: 'NPS',
      companyClient: 'Empresa',
      contracts: 'Evoluções',
      plans: 'Avaliações',
      configuration: 'Opções',
      logout: 'Sair',
      iconClient: {
        home: 'home',
        user: 'area-chart',
        company: 'pie-chart',
        companyClient: 'bank',
        contracts: 'line-chart',
        plans: 'message',
        configuration: 'setting',
        logout: 'logout',
      },
    };

    const routesClient = {
      home: 'client',
      user: 'indexBlue',
      company: 'nps',
      companyClient: 'companyClient',
      contracts: 'evolution',
      plans: 'comments',
      configuration: 'settings',
    };

    return (
      <Layout>
        {!this.props.location.isRegister ? (
          <Sidebar
            dataSidebar={homeClient}
            routes={routesClient}
            current="dashboard"
          />
        ) : null}
        <Layout style={{ height: '100vh' }}>
          <Header dataUser={this.state.dataUser} />
          <Breadcrumb pages={[]} title="ESCOLHA DO PLANO" />

          <Content className="content card-header">
            <div
              style={{ background: '#FFF', padding: '30px', height: '80vh' }}
            >
              <Row gutter={16}>
                {data.map((item, index) => (
                  <Col key={item.id} lg={8} sm={12} xs={24}>
                    <Card
                      style={{ marginTop: 10, backgroundColor: '#ECECEC' }}
                      title={item.nome}
                      actions={[
                        <Button
                          type="primary"
                          onClick={() => this.onChange(item)}
                        >
                          Contratar
                        </Button>,
                      ]}
                      bordered={false}
                    >
                      <p>
                        {item.funcionalidadePlano.length === 0
                          ? ''
                          : item.funcionalidadePlano[0].text}
                      </p>
                      <p>{`Quantidade de usuarios: ${item.qtdUsuarios}`}</p>
                      <p>{`Quantidade de respostas: ${item.qtdRespostas}`}</p>
                      <p>{`Valor do Plano: R$ ${item.valor}`}</p>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getDataPlans }, dispatch);

const mapStateToProps = store => ({
  userData: store.userData.data,
  dataPlans: store.userData.dataPlans,
  dataUserCompany: store.userData.dataUserCompany,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PlansChoice));
