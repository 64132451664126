import React, { Component } from 'react';
import { Form, Input, Icon, Modal, Button } from 'antd';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getModalTrue } from '../../redux/actions';

class ModalInvitation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // ModalText: this.props.dataPlans.contrato.descricao,
      visible: false,
      confirmLoading: false,
      idEmpresa: null,
      idFilial: null,
    };
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = async () => {
    const status = await this.onChange()
    if(status) {
      this.setState({
        visible: false,
      });
      this.props.getModalTrue(false);
    }
  };

  handleCancel = async () => {
    await this.setState({
      visible: false,
    });
    this.props.form.resetFields()
    this.setState({
      visible: false,
    });
  };

  componentWillReceiveProps(props) {
    if (props.dataCompanyFilial) {
      if(props.dataCompanyFilial.idEmpresa) {
        this.setState({
          visible: props.visible,
          idEmpresa: null,
          idFilial: props.dataCompanyFilial.id
        })
      } else {
        this.setState({
          visible: props.visible,
          idEmpresa: props.dataCompanyFilial.id,
          idFilial: null
        })
      }
    } else {
      this.setState({
        visible: false
      })
    }
  }

  // componentDidUpdate(prevProps) {
  //   if (this.props.visible !== prevProps.visible) {
  //     this.fetchData(this.props.visible);
  //   }
  // }
  //
  // fetchData(data) {
  //   this.setState({ visible: data })
  // }

  componentWillUnmount() {
    this.setState({ visible: false})
  }

  onChange = async () => {
    this.props.form.validateFields(async (err, values) => {
      if(!err) {
        this.setState({ confirmLoading: true })

        const payload = {
          idEmpresa: this.state.idEmpresa,
          idFilial: this.state.idFilial,
          emailUser: values.email,
        }

        const status = await this.props.createInvitation(payload);
        if(status) {
          await this.setState({
            visible: false,
            confirmLoading: false
          });
          this.props.form.resetFields()
        } else {
          this.setState({ confirmLoading: false })
        }

        return status
      } else {
        return false
      }
    })
  }

  render() {
    const { visible, confirmLoading } = this.state;
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8, offset: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16, offset: 4 },
      },
    };

    return (
      <div>
        <Button type="primary" icon="plus" onClick={this.showModal}>
          Enviar convite
        </Button>
        <Modal
          title="Enviar convite"
          visible={visible}
          onOk={this.handleOk}
          confirmLoading={confirmLoading}
          onCancel={this.handleCancel}
        >
          <div>
            <Form {...formItemLayout}>
              <Form.Item>
                {getFieldDecorator('email', {
                  rules: [
                    {
                      type: 'email',
                      message: 'Forneça um e-mail válido!',
                    },
                    {
                      required: true,
                      message: 'E-mail obrigatório!',
                    },
                  ],
                })(
                  <Input
                    placeholder="Email"
                    type="email"
                    prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  />,
                )}
              </Form.Item>
              {/* <Form.Item>
                <Dropdown overlay={menu}>
                  <Button style={{ marginRight: 15}}>
                    Empresa <Icon type="down" />
                  </Button>
                </Dropdown>
              </Form.Item> */}
            </Form>
          </div>
        </Modal>
      </div>
    );
  }
}


const mapDispatchToProps = dispatch => bindActionCreators({ getModalTrue }, dispatch);

const mapStateToProps = store => ({
  modalState: store.userData.modalState,
});

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(ModalInvitation));
