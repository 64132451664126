import React, { Component } from 'react';
import {
  Layout,
  Row,
  Col,
  Typography,
  Steps,
  Button,
  message,
  Icon,
  Input,
  Form,
} from 'antd';
import InputMask from 'react-input-mask';
import './styles.css';
import RateComponent from '../../components/RateComponent';

import btnInsta from '../../images/btnInstagram.png';
import btnZap from '../../images/btnWhatsApp.png';

import Service from './Service';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getUserData,
  getDataUserCompany,
  getModalTrue,
} from '../../redux/actions';
import { obterLogo } from '../../components/ModalLogomarca/services';
import { consultarTipoExibicao } from '../../components/QuestionsClient/services';
import { validaEmail } from '../../utils';

const { Header, Content } = Layout;
const { Title } = Typography;
const { TextArea } = Input;
const { Step } = Steps;
const arr = new Array('', '', '', '', '', '', '', '', '', '', '');

class QuizSteps extends Component {
  constructor(props) {
    super(props);

    this.state = {
      current: 0,
      indice_blue: null,
      showButton: true,
      critica: '',
      elogio: '',
      nome: '',
      celular: '',
      email: '',
      arrResponse: [],
      subPerguntas: [],
      temRedeSocial: false,
      exibirGoogle: false,
      exibirTrip: false,
      exibirInsta: false,
      exibirWhatsApp: false,
      urlGoogle: '',
      urlTrip: '',
      urlInsta: '',
      urlWhats: '',
      textoInsta: '',
      textoZap: '',
      exibirRedes: false,
      rateValue: [],
      checkSubPerguntas: [],
      idEmpresa: null,
      idFilial: null,
      logoCliente: '',
      tipoExibicaoQuesito: 1,
      identificacaoObrigatoria: false,
      unidadeSemNome: false,
      respostaUnica: false,
      planoEmpresa: 0,
    };

    this.service = new Service();
  }

  async componentDidMount() {
    const { idEmpresa, idFilial } = this.props.match.params;
    if (idEmpresa && idFilial) {
      const unidadeSemNome = await this.service.getUnidadeSemNome(idFilial);
      await this.setState({
        idEmpresa: Number(idEmpresa),
        idFilial: Number(idFilial),
        unidadeSemNome: unidadeSemNome,
      });
      this.getQuestionsFilial();
      this.handleChangeFilial();
      await this.carregarLogo(Number(idEmpresa), Number(idFilial));
      await this.carregarTipoExibicao(Number(idEmpresa));
      await this.carregarIdentificacaoObrigatoria(Number(idFilial));

      const url = window.location.href;

      let quiosque = undefined;

      if (url.includes('quiosque')) {
        quiosque = url;
      }

      await this.getQuestionsFilial();
      await this.carregarLogo(Number(idEmpresa), Number(idFilial));

      const resultRedes = await this.service.listarRedeSocialPorFilial(
        idFilial
      );

      const redes = resultRedes.object;

      let google = false;
      let googleUrl = '';
      let trip = false;
      let tripUrl = '';
      let insta = false;
      let instaUrl = '';
      let zap = false;
      let zpUrl = '';
      let exibirRedes = false;
      let txtCritica = '';
      let txtElogio = '';
      let txtInsta = '';
      let txtZap = '';

      if (!quiosque) {
        if (redes && redes.length > 0) {
          exibirRedes = true;

          const goo = redes.filter(x => x.tipo === 'Google');

          if (goo && goo.length > 0) {
            google = true;
          } else {
            google = false;
          }

          const trp = redes.filter(x => x.tipo === 'TripAdvisor');

          if (trp && trp.length > 0) {
            trip = true;
          } else {
            trip = false;
          }

          const ig = redes.filter(x => x.tipo === 'Instagram');
          if (ig && ig.length > 0) {
            insta = true;
            instaUrl = ig[0].urlAcesso;
            txtInsta = ig[0].texto;
          } else {
            insta = false;
          }

          const zp = redes.filter(x => x.tipo === 'WhatsApp');

          if (zp && zp.length > 0) {
            zap = true;
            zpUrl = zp[0].urlAcesso;
            txtZap = zp[0].texto;
          } else {
            zap = false;
          }
        }
      }

      this.setState({
        loading: false,
        temRedeSocial: exibirRedes,
        exibirGoogle: google,
        exibirInsta: insta,
        exibirTrip: trip,
        exibirWhatsApp: zap,
        urlGoogle: googleUrl,
        urlTrip: tripUrl,
        urlInsta: instaUrl,
        urlWhats: zpUrl,
        critica: txtCritica,
        elogio: txtElogio,
        textoInsta: txtInsta,
        textoZap: txtZap,
      });
    } else {
      // TODO: sair dessa tela
      console.log('TODO: sair dessa tela');
    }
  }

  handleInsta() {
    window.open(this.state.urlInsta, '_blank');
  }

  handleWhatsApp() {
    window.open(this.state.urlWhats, '_blank');
  }

  handleChangeFilial = async () => {
    await this.service.listFilial();

    // const filial = result.object.filter(item => this.state.idFilial === item.id)
    // this.setState({ filial })
  };

  getQuestionsFilial = async () => {
    // const idFilial = this.state.idFilial;
    // const result = await this.service.getQuestionsFilial(idFilial);
    // console.log('result', result);
    const data = this.props.questionsFilial.perguntas;

    const racionais =
      data &&
      data.filter(_racionais => {
        return _racionais.idSegmentoPergunta === 1;
      });

    const emocionais =
      data &&
      data.filter(_emocionais => {
        return _emocionais.idSegmentoPergunta === 2;
      });

    const nps =
      data &&
      data.filter(nps => {
        return nps.idSegmentoPergunta === 3;
      });

    const criticas =
      data &&
      data.filter(criticas => {
        return criticas.idSegmentoPergunta === 4;
      });

    const elogios =
      data &&
      data.filter(elogios => {
        return elogios.descricao === 'Crítica';
      });

    this.setState({
      dataQuestions: data,
      emocionais,
      racionais,
      nps,
      criticas,
      elogios,
    });
  };

  next() {
    const current = this.state.current + 1;

    this.setState({ current });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }

  changeRate = async (data, ask, index) => {
    const { rateValue } = this.state;
    rateValue[index] = data;
    this.setState({ rateValue });

    if (data <= 3) {
      this.setState({ subPerguntas: ask.subPergunta });
    } else {
      this.setState({ subPerguntas: 5 });
    }

    let arrResponse = [];
    arrResponse = this.state.arrResponse;
    let currentResponse = this.state.arrResponse
      .filter(item => item.idPergunta === ask.id)
      .shift();

    if (currentResponse) {
      currentResponse.valorInteiro = data;
      arrResponse[arrResponse.indexOf(currentResponse)] = currentResponse;
    } else {
      arrResponse.push(this.parseResponse(data, 'int', ask));
    }
    await this.setState({ arrResponse });
  };

  changeIndiceBlue = async (data, ask) => {
    let arrResponse = [];
    arrResponse = this.state.arrResponse;
    let currentResponse = this.state.arrResponse
      .filter(item => item.idPergunta === ask.id)
      .shift();
    if (currentResponse) {
      currentResponse.valorInteiro = data;
      arrResponse[arrResponse.indexOf(currentResponse)] = currentResponse;
    } else {
      arrResponse.push(this.parseResponse(data, 'int', ask));
    }
    await this.setState({ arrResponse, indice_blue: data });
  };

  changeText = async (data, ask) => {
    let arrResponse = [];
    arrResponse = this.state.arrResponse;
    let currentResponse = this.state.arrResponse
      .filter(item => item.idPergunta === ask.id)
      .shift();

    if (currentResponse) {
      currentResponse.valorChar = data;
      arrResponse[arrResponse.indexOf(currentResponse)] = currentResponse;
    } else {
      arrResponse.push(this.parseResponse(data, 'char', ask));
    }
    await this.setState({ arrResponse });
  };

  changeTextCritica = async (data, ask) => {
    let arrResponse = [];
    arrResponse = this.state.arrResponse;
    let currentResponse = this.state.arrResponse
      .filter(item => item.idPergunta === ask.id)
      .shift();

    if (currentResponse) {
      currentResponse.valorChar = data;
      arrResponse[arrResponse.indexOf(currentResponse)] = currentResponse;
    } else {
      arrResponse.push(this.parseResponse(data, 'char', ask));
    }
    await this.setState({ arrResponse });
  };

  changeBool = async (data, ask) => {
    let arrResponse = [];
    arrResponse = this.state.arrResponse;
    let currentResponse = this.state.arrResponse
      .filter(item => item.idPergunta === ask.id)
      .shift();

    if (currentResponse) {
      currentResponse.valorBoolean = data;
      arrResponse[arrResponse.indexOf(currentResponse)] = currentResponse;
    } else {
      arrResponse.push(this.parseResponse(data, 'bool', ask));
    }
    await this.setState({ arrResponse });
  };

  changeSubAsk = async (data, ask) => {
    let arrResponse = this.state.arrResponse;
    let indexToRemove = arrResponse.findIndex(
      item => item.idSubPergunta === ask.id
    );
    if (indexToRemove >= 0) {
      arrResponse.splice(indexToRemove, 1);
    } else {
      arrResponse.push(this.parseSubResponse(ask.ativa, 'bool', ask));
    }
    await this.setState({ arrResponse });
  };

  getValue(ask, type) {
    let currentResponse = this.state.arrResponse
      .filter(item => item.idPergunta === ask.id)
      .shift();

    if (currentResponse) {
      return type === 'int'
        ? currentResponse.valorInteiro
        : type === 'char'
        ? currentResponse.valorChar
        : currentResponse.valorBoolean;
    }
    return '';
  }

  getValueCritica(ask, type) {
    let currentResponse = this.state.arrResponse
      .filter(item => item.idPergunta === ask.id)
      .shift();

    if (currentResponse) {
      return type === 'int'
        ? currentResponse.valorInteiro
        : type === 'char'
        ? currentResponse.valorChar
        : currentResponse.valorBoolean;
    }
    return '';
  }

  parseResponse(data, type, ask) {
    let _payload = {
      idPergunta: ask.id,
    };
    _payload[
      type === 'int'
        ? 'valorInteiro'
        : type === 'char'
        ? 'valorChar'
        : 'valorBoolean'
    ] = data;
    return _payload;
  }

  parseSubResponse(data, type, ask) {
    let _payload = {
      idSubPergunta: ask.id,
    };
    _payload[
      type === 'int'
        ? 'valorInteiro'
        : type === 'char'
        ? 'valorChar'
        : 'valorBoolean'
    ] = data;
    return _payload;
  }

  onChangeForm = async () => {
    this.setState({ loading: true });

    if (this.state.identificacaoObrigatoria) {
      let possuiErros = false;
      if (!this.state.nome || this.state.nome.length < 3) {
        message.warn('Informe o seu nome.');
        possuiErros = true;
      }
      if (!this.state.email || !validaEmail(this.state.email)) {
        message.warn('Informe o seu e-mail.');
        possuiErros = true;
      }
      let celular = !this.state.celular
        ? ''
        : this.state.celular.replace(/\D/g, '');
      if (!celular || celular.length < 8) {
        message.warn('Informe o seu telefone.');
        possuiErros = true;
      }

      if (possuiErros) {
        this.setState({ loading: false });
        return;
      }
    }

    let payload = {
      idFilial: this.state.idFilial,
      nome: this.state.nome,
      celular: this.state.celular,
      email: this.state.email,
      indiceBlue: 0,
      nps: 0,
      dataRespostas: new Date(),
      verificado: true,
      respostas: this.state.arrResponse,
    };

    let retorno = await this.service.postQuestionsAnswers(payload);

    this.setState({ loading: false });

    if (!retorno || !retorno.object || !retorno.object.ok) {
      message.error(
        retorno && retorno.mensage
          ? retorno.mensage
          : 'Falha ao responder! Tente novamente.'
      );
      return;
    }

    message.success('Avaliação registrada com sucesso!');

    this.saveValueLocalStorage(this.state.idEmpresa, this.state.idFilial, true);

    let urlObrigado = `/blue/questionario/${this.state.idEmpresa}/${this.state.idFilial}/obrigado`;

    if (retorno.object.beneficioVoucher) {
      urlObrigado += `/${retorno.object.beneficioVoucher}`;
    }

    this.props.history.push(urlObrigado, {
      nota: this.state.indice_blue,
      response: this.state.arrResponse,
    });
  };

  onChangeCheck(checkedValues) {
    this.setState({
      ...this.state.checkSubPerguntas,
      checkSubPerguntas: checkedValues.target.value,
    });
  }

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({
          loading: true,
          email: values.email,
          celular: values.celular,
        });
        this.onChangeForm();
      }
    });
  };

  saveValueLocalStorage(idEmpresa, idFilial, valor) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 7); // Adiciona 7 dias de expiração

    // Formata a data de expiração para o formato de cookie
    const expires = expirationDate.toUTCString();

    // Constrói o valor do cookie
    const cookieValue = `${idEmpresa}:${idFilial}=${JSON.stringify(
      valor
    )};expires=${expires};path=/`;

    // Define o cookie
    document.cookie = cookieValue;
  }

  getNomeFilial(nome) {
    return this.state.unidadeSemNome ? '' : `(${nome})`;
  }

  carregarLogo = async (idEmpresa, idFilial) => {
    let url = '';
    let planoEmpresa = 0;
    const resultFilial = await obterLogo(idEmpresa, idFilial);
    planoEmpresa = resultFilial.planoEmpresa;
    url = resultFilial.url;
    if (!resultFilial.url) {
      const resultEmpresa = await obterLogo(idEmpresa, 0);
      url = resultEmpresa.url;
      planoEmpresa = resultEmpresa.planoEmpresa;
    }
    this.setState({
      logoCliente: url,
    });

    if (planoEmpresa === 4 || planoEmpresa === 5 || planoEmpresa === 6) {
      this.getRespostaUnica();
    }
  };

  carregarTipoExibicao = async idEmpresa => {
    let tipo = 1;
    const result = await consultarTipoExibicao(idEmpresa);
    if (result) {
      tipo = result.object;
    }
    this.setState({
      tipoExibicaoQuesito: tipo,
    });
  };

  carregarIdentificacaoObrigatoria = async idFilial => {
    const result = await this.service.getIdentificacaoObrigatoria(idFilial);
    this.setState({
      identificacaoObrigatoria: result,
    });
  };

  getValueLocalStorage(idEmpresa, idFilial) {
    // Obtém todos os cookies e divide-os em um array
    const cookies = document.cookie.split(';');
    // Loop através de todos os cookies
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();

      // Verifica se o cookie começa com o nome desejado
      if (cookie.startsWith(`${idEmpresa}:${idFilial}=`)) {
        // Obtém o valor do cookie
        const cookieValue = cookie.substring(
          `${idEmpresa}:${idFilial}=`.length,
          cookie.length
        );

        // Retorna o valor decodificado
        return JSON.parse(decodeURIComponent(cookieValue));
      }
    }

    // Se o cookie não for encontrado, retorna null
    return null;
  }

  getRespostaUnica() {
    const { idEmpresa, idFilial } = this.props.match.params;

    const respostaFilial = this.getValueLocalStorage(idEmpresa, idFilial);

    this.setState({
      respostaUnica: respostaFilial,
    });
  }

  render() {
    const { current } = this.state;
    const { loading } = this.state;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8, offset: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16, offset: 4 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 14,
          offset: 10,
        },
      },
    };

    let company = 'esta empresa';
    if (this.props.questionsFilial) {
      company =
        `${this.props.questionsFilial.filial.nomeEmpresa} ` +
        this.getNomeFilial(this.props.questionsFilial.filial.nome);
    }

    let steps = [
      {
        title: `De 0 a 10, o quanto você recomendaria <strong>${company}</strong> para um amigo?`,
        show: true,
        question1: (
          <Row style={{ marginTop: 20, marginBottom: 10 }}>
            <Col span={22} offset={1}>
              <div className="indice-blue">
                {arr.map((i, key) => {
                  return (
                    <div
                      key={key}
                      className={
                        this.state.indice_blue === key
                          ? 'indice-blue-item selected'
                          : 'indice-blue-item'
                      }
                      onClick={() => this.changeIndiceBlue(key, { id: 85 })}
                    >
                      {key}
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>
        ),
        question2: (
          <Row style={{ marginBottom: 50 }}>
            <Col span={22} offset={1}>
              <p style={{ marginTop: 25, marginBottom: 0, fontSize: 16 }}>
                Caso tenha algum motivo específico para ter dado essa nota,
                conta pra gente.
              </p>
            </Col>
          </Row>
        ),
        question3: (
          <Row>
            <Row>
              <Col span={4}>
                <Icon
                  type="like"
                  style={{ fontSize: 30, color: 'green', marginTop: 15 }}
                />
              </Col>
              <Col style={{ marginBottom: 10 }} span={18}>
                <TextArea
                  rows={3}
                  placeholder="Elogios / Sugestões / Comentários"
                  onChange={e => this.changeText(e.target.value, { id: 86 })}
                  value={this.getValue({ id: 86 }, 'char')}
                />
              </Col>
            </Row>
            <Row>
              <Col span={4}>
                <Icon
                  type="dislike"
                  style={{ fontSize: 30, marginTop: 15, color: 'red' }}
                />
              </Col>
              <Col span={18}>
                <TextArea
                  rows={3}
                  placeholder="Críticas / Reclamações"
                  onChange={e =>
                    this.changeTextCritica(e.target.value, { id: 87 })
                  }
                  value={this.getValueCritica({ id: 87 }, 'char')}
                />
              </Col>
            </Row>
          </Row>
        ),
        question4: 'Image2 Input2',
      },
      {
        title: 'Qual o seu grau de <strong>satisfação</strong> sobre:',
        show:
          this.state.tipoExibicaoQuesito === 1 ||
          this.state.tipoExibicaoQuesito === 2,
        question1: (
          <div>
            {this.state.racionais &&
              this.state.racionais.map(item => {
                return (
                  <div key={item.id}>
                    <h3>{item.descricao}</h3>
                    <div style={{ marginBottom: 30 }}>
                      <RateComponent
                        changeSubAsk={this.changeSubAsk.bind(this)}
                        changeRate={this.changeRate.bind(this)}
                        item={item}
                        arrResponse={this.state.arrResponse}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        ),
      },
      {
        title: 'Qual o seu grau de <strong>felicidade</strong> sobre:',
        show:
          this.state.tipoExibicaoQuesito === 1 ||
          this.state.tipoExibicaoQuesito === 3,
        question1: (
          <div>
            {this.state.emocionais &&
              this.state.emocionais.map(item => {
                return (
                  <div key={item.id}>
                    <h3>{item.descricao}</h3>
                    <div style={{ marginBottom: 30 }}>
                      <RateComponent
                        changeSubAsk={this.changeSubAsk.bind(this)}
                        state={this.state}
                        changeRate={this.changeRate.bind(this)}
                        item={item}
                        arrResponse={this.state.arrResponse}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        ),
      },
      {
        title: 'Para finalizar...',
        show: true,
        question1: (
          <div>
            <Row>
              {this.props.questionsFilial &&
              this.props.questionsFilial.beneficio ? (
                <Col>
                  <p
                    style={{
                      fontSize: '11pt',
                      marginTop: 20,
                      marginBottom: 0,
                      fontWeight: 'lighter',
                    }}
                  >
                    Precisamos saber para quem estamos enviando o prêmio.
                  </p>
                  {!this.state.identificacaoObrigatoria && (
                    <p style={{ fontSize: '11pt', marginTop: 0 }}>
                      (É opcional, mas o cupom só será gerado se você preencher
                      seus dados válidos)
                    </p>
                  )}
                </Col>
              ) : (
                <>
                  {!this.state.identificacaoObrigatoria && (
                    <Col>
                      <h4>
                        É opcional, mas gostamos de saber quem nos ajudou.
                      </h4>
                    </Col>
                  )}
                </>
              )}
              <Col
                span={20}
                offset={2}
                style={{ marginBottom: 10, marginTop: 20 }}
              >
                <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                  <Form.Item>
                    <Input
                      placeholder={'Nome'}
                      prefix={
                        <Icon
                          type="user"
                          style={{ color: 'rgba(0,0,0,.25)' }}
                        />
                      }
                      onChange={e => {
                        this.setState({ nome: e.target.value });
                      }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Input
                      placeholder="E-mail"
                      type="email"
                      prefix={
                        <Icon
                          type="mail"
                          style={{ color: 'rgba(0,0,0,.25)' }}
                        />
                      }
                      onChange={e => {
                        this.setState({ email: e.target.value });
                      }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <span className="ant-input-affix-wrapper">
                      <span className="ant-input-prefix">
                        <Icon
                          type="phone"
                          style={{ color: 'rgba(0,0,0,.25)' }}
                        />
                      </span>
                      <InputMask
                        className="ant-input"
                        mask="(99) 99999-9999"
                        placeholder="Celular"
                        onChange={e => {
                          this.setState({ celular: e.target.value });
                        }}
                      />
                    </span>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
            <Row
              style={{
                fontWeight: 'lighter',
                color: '#003060',
                fontSize: '10pt',
                padding: 8,
              }}
            >
              {/* {this.props.questionsFilial.beneficio ? (
              <p>
                Ao preencher e clicar em "Concluir", você <strong>concorda</strong> em compartilhar seus dados com nossa empresa para fins de <strong>comunicação</strong> e/ou <strong>envio do voucher</strong>.<br/><a target="_blank" href="https://blueexperiencias.com.br/politicas-privacidade" style={{ textDecoration: 'underline' }} >(Políticas de Privacidade)</a>
              </p>
            ) : (
              <p> */}
              Não se preocupe! Seus dados estarão em sigilo e segurança. Caso
              você concorde e escolha informá-los, eles podem ser usados para
              nos ajudar a melhorar a sua experiência ou para comunicação.
              <br />
              <a
                target="_blank"
                href="https://blueexperiencias.com.br/politicas-privacidade"
                style={{ textDecoration: 'underline' }}
              >
                (Políticas de Privacidade)
              </a>
              {/* </p>
            )} */}
              {this.props.questionsFilial.beneficio && (
                <>
                  {this.props.questionsFilial.beneficio
                    .tempoProximoPremioMesmoEmailDias > 0 ? (
                    <></>
                  ) : (
                    <p>
                      É possível gerar <strong>1 cupom por e-mail</strong>.
                    </p>
                  )}
                </>
              )}
            </Row>
          </div>
        ),
      },
    ];

    steps = steps.filter(x => x.show);

    let stepComplete = false;
    let stageToKey = ['nps', 'racionais', 'emocionais'];
    var key = stageToKey[this.state.current];
    if (key === 'racionais' && this.state.tipoExibicaoQuesito === 3) {
      key = 'emocionais';
    } else if (key === 'emocionais' && this.state.tipoExibicaoQuesito === 2) {
      key = 'racionais';
    }
    let questions = this.state[key];
    var answered = 0;

    if (questions != undefined) {
      for (var i = 0; i < questions.length; i++) {
        for (var i2 = 0; i2 < this.state.arrResponse.length; i2++) {
          let response = this.state.arrResponse[i2];
          if (
            questions[i].id == response.idPergunta &&
            (response.valorInteiro > 0 || key == 'nps')
          ) {
            answered++;
            continue;
          }
        }
      }

      stepComplete = answered >= questions.length;
    }

    return !this.state.respostaUnica ? (
      <Layout>
        <Content
          style={{
            padding: '24px 16px 0',
            height: '90vh',
            backgroundColor: '#FFF',
          }}
        >
          <Row
            style={{
              flexDirection: 'row',
              textAlign: 'center',
              margin: '24px 16px 0',
            }}
          >
            <Col
              xs={{ span: 24 }}
              md={{ span: 10, offset: 8 }}
              lg={{ span: 10, offset: 8 }}
            >
              <Steps
                style={{
                  textAlign: 'center',
                  margin: 'auto',
                }}
                current={current}
                size="small"
              >
                {steps.map((item, key) => (
                  <Step key={key} />
                ))}
              </Steps>
              <div className="steps-content">
                <div style={{ margin: 8 }}>
                  <h2
                    style={{ marginBottom: 30 }}
                    dangerouslySetInnerHTML={{
                      __html: steps[current] ? steps[current].title : '',
                    }}
                  ></h2>
                </div>
                <div>
                  <h4>{steps[current].question1}</h4>
                </div>
                <div>
                  <h4>{steps[current].question2}</h4>
                </div>
                <div>
                  <h4>{steps[current].question3}</h4>
                </div>
              </div>
              <div className="steps-action">
                {this.state.showButton && current > 0 && (
                  <Button
                    style={{ marginRight: 10 }}
                    onClick={() => this.prev()}
                  >
                    Voltar
                  </Button>
                )}
                {this.state.showButton &&
                  current === steps.filter(x => x.show).length - 1 && (
                    <Button
                      type="secondary"
                      loading={this.state.loading}
                      onClick={() => this.onChangeForm()}
                    >
                      Concluir
                    </Button>
                  )}
                {this.state.showButton && current < steps.length - 1 && (
                  <Button
                    className="next-step"
                    type="secondary"
                    onClick={() => this.next()}
                    disabled={!stepComplete}
                  >
                    Próximo
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Content>
      </Layout>
    ) : (
      <Layout>
        <Content
          style={{
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '100px',
            backgroundColor: '#FFF',
          }}
        >
          {this.state.logoCliente && (
            <Row
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img src={this.state.logoCliente} class="logo-cliente" />
            </Row>
          )}

          <Row>
            <p style={{ fontSize: '22pt', textAlign: 'center' }}>
              <strong>Obrigado!</strong> <br></br> Você já fez esta avaliação
              recentemente.
            </p>
          </Row>

          {this.state.temRedeSocial && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                width: '100%',
                paddingLeft: '10%',
              }}
            >
              <Button
                onClick={() => this.handleInsta()}
                className="btn-rede-social follow-us"
              >
                <img src={btnInsta} alt="Siga a gente no instagram" />
                <label>{this.state.textoInsta}</label>
              </Button>
              <Button
                onClick={() => this.handleWhatsApp()}
                className="btn-rede-social follow-us"
              >
                <img src={btnZap} alt="Fale com a gente no whatsapp" />
                <label>{this.state.textoZap}</label>
              </Button>
            </div>
          )}
        </Content>
      </Layout>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { getUserData, getDataUserCompany, getModalTrue },
    dispatch
  );

const mapStateToProps = store => ({
  userData: store.userData.data,
  dataUserCompany: store.userData.dataUserCompany,
  clientFilial: store.userData.clientFilial,
  filialId: store.userData.filialId,
  questionsFilial: store.userData.questionsFilial,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(QuizSteps));
