import Services from '../../services';

export default class Service extends Services {
  listCompany = async (tokenValue) => {
    const tokenLogin = localStorage.getItem('token');
    try {
      const response = await fetch(`${this.endPoint}Empresa`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'text/plain',
        },
      });

      const json = await response.json();

      return json;
    } catch (err) {
      console.log('Error problem', err);
      this.doLogout()
    }
  };

  listCompanyFilial = async (tokenValue) => {
    const tokenLogin = localStorage.getItem('token');
    try {
      const response = await fetch(`${this.endPoint}Filial`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'text/plain',
        },
      });

      const json = await response.json();

      return json;
    } catch (err) {
      console.log('Error problem', err);
      this.doLogout()
    }
  };

  updateCompanyPlan = async (id, body) => {
    const tokenLogin = localStorage.getItem('token');
    try {
      const response = await fetch(`${this.endPoint}Empresa/${id}/alterarsituacaoplano`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      const json = await response.json();

      return json;
    } catch (err) {
      console.log('Error problem', err);
      this.doLogout()
    }
  };
}
