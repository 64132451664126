import React, { useState, useEffect } from 'react';
import { Row, Col, Checkbox, Icon, Input, Button, Spin, message, Card, Select, Tooltip, Modal } from 'antd';
import { excluirRedeSocial, listarPorFilial, salvarRedeSocial } from './service';
import './styles.css';
import btnInsta from '../../images/btnInstagram.png';
import btnZap from '../../images/btnWhatsApp.png';
const { Option } = Select;
function RedeSocial(props) {
    
    const { filial } = props;
    const [temAlteracao, setTemAlteracao] = useState(false);
    const [carregando, setCarregando] = useState(false);
    const [consultando, setConsultando] = useState(false);
    const [listaOpcoes, setListaOpcoes] = useState([
        { id: 1, texto: 'Apenas para promotores (Notas 9 ou 10).'},
        { id: 2, texto: 'Apenas para promotores ou neutros (Notas 7, 8, 9 ou 10).'},
        { id: 3, texto: 'Para promotores, neutros e detratores (Notas de 0 a 10).'}
    ]);
    const [openGoogle, setOpenGoogle] = useState(false);
    const [openTrip, setOpenTrip] = useState(false);
    const [openInsta, setOpenInsta] = useState(false);
    const [openZap, setOpenZap] = useState(false);
    
    const [urlInstagram, setUrlInstagram] = useState('');
    const [exibirInstagram, setExibirInstagram] = useState(false);
    const [idInstagram, setIdInstagram] = useState(0);
    const [textoInsta, setTextoInsta] = useState('');

    const [urlWhatsApp, setUrlWhatsApp] = useState('');
    const [exibirWhatsApp, setExibirWhatsApp] = useState(false);
    const [idWhatsApp, setIdWhatsApp] = useState(0);
    const [textoWhats, setTextoWhats] = useState('');

    const [urlGoogle, setUrlGoogle] = useState('');
    const [exibirGoogle, setExibirGoogle] = useState(false);
    const [idGoogle, setIdGoogle] = useState(0);

    const [urlTrip, setUrlTrip] = useState('');
    const [exibirTrip, setExibirTrip] = useState(false);
    const [idTrip, setIdTrip] = useState(0);

    const [apenasPromotoresGoogle, setApenasPromotoresGoogle] = useState(false);
    const [promotoresNeutrosGoogle, setPromotoresNeutrosGoogle] = useState(false);
    const [todasGoogle, setTodasGoogle] = useState(false);
    const [naoSugerirCriticasGoogle, setNaoSugerirCriticasGoogle] = useState(false);

    const [apenasPromotoresTripAdvisor, setApenasPromotoresTripAdvisor] = useState(false);
    const [promotoresNeutrosTripAdvisor, setPromotoresNeutrosTripAdvisor] = useState(false);
    const [todasTripAdvisor, setTodasTripAdvisor] = useState(false);
    const [naoSugerirCriticasTripAdvisor, setNaoSugerirCriticasTripAdvisor] = useState(false);

    const handleUrl = (type, value) => {
        setTemAlteracao(true);
        switch(type) {
            case "Instagram":
                setUrlInstagram(value);
                break;
            case "WhatsApp":
                setUrlWhatsApp(value);
                break;
            case "Google":
                setUrlGoogle(value);
                break;
            case "Trip":
                setUrlTrip(value);
                break;
        }
    }

    const handleRedeSocial = async () => {
        setCarregando(true);
        let sucesso = false;
        if(exibirInstagram) {
            sucesso = await sendInstagram();
        } else if (idInstagram > 0) {
            sucesso = await excluirRedeSocial(idInstagram);
        }
        if(exibirWhatsApp){
            sucesso = await sendWhatsApp();
        } else if (idWhatsApp > 0) {
            sucesso = await excluirRedeSocial(idWhatsApp);
        }
        if(exibirGoogle) {
            sucesso = await sendGoogle();
        } else if (idGoogle > 0) {
            sucesso = await excluirRedeSocial(idGoogle);
        }
        if(exibirTrip){
            sucesso = await sendTrip();
        } else if (idTrip > 0) {
            sucesso = await excluirRedeSocial(idTrip);
        }

        setCarregando(false);
        if(sucesso) {
            message.success('Redes sociais registradas com sucesso');
            carregarDados();
        }
        else
            message.error('Houve um erro ao registrar alteração nas redes sociais');
    }

    const sendInstagram = async () => {
        const request = {
            id: idInstagram,
            tipo: 'Instagram',
            filialId: filial,
            urlAcesso: urlInstagram,
            exibirApenasPromotores: true,
            exibirPromotoresNeutros: true,
            exibirParaTodas: true,
            exibirParaCriticas: true,
            texto: textoInsta
        }

        if(!urlInstagram) {
            message.warning('Por favor, informe a URL do Instagram');
            return false;
        }

        if(!textoInsta) {
            message.error('Por favor, informe o texto descritivo para o Instagram');
            return false;
        }
        

        const result = await salvarRedeSocial(request);
        return result ? result.object : false;
    }

    const sendWhatsApp = async () => {
        const request = {
            id: idWhatsApp,
            tipo: 'WhatsApp',
            filialId: filial,
            urlAcesso: urlWhatsApp,
            exibirApenasPromotores: true,
            exibirPromotoresNeutros: true,
            exibirParaTodas: true,
            exibirParaCriticas: true,
            texto: textoWhats
        }

        if(!urlWhatsApp) {
            message.warning('Por favor, informe a URL do WhatsApp');
            return false;
        }

        const result = await salvarRedeSocial(request);
        return result ? result.object : false;
    }

    const sendGoogle = async () => {
        const request = {
            id: idGoogle,
            tipo: 'Google',
            filialId: filial,
            urlAcesso: urlGoogle,
            exibirApenasPromotores: apenasPromotoresGoogle,
            exibirPromotoresNeutros: promotoresNeutrosGoogle,
            exibirParaTodas: todasGoogle,
            exibirParaCriticas: naoSugerirCriticasGoogle
        }

        if(!urlGoogle) {
            message.warning('Por favor, informe a URL do Google');
            return false;
        }

        if(apenasPromotoresGoogle == false && promotoresNeutrosGoogle == false && todasGoogle == false) {
            message.warning('Por favor, selecione pelo menos uma opção de exibição do link do google');
            return false;
        }

        const result = await salvarRedeSocial(request);
        return result ? result.object : false;
    }

    const sendTrip = async () => {
        const request = {
            id: idTrip,
            tipo: 'TripAdvisor',
            filialId: filial,
            urlAcesso: urlTrip,
            exibirApenasPromotores: apenasPromotoresTripAdvisor,
            exibirPromotoresNeutros: promotoresNeutrosTripAdvisor,
            exibirParaTodas: todasTripAdvisor,
            exibirParaCriticas: naoSugerirCriticasTripAdvisor
        }

        if(!urlTrip) {
            message.warning('Por favor, informe a URL do TripAdvisor');
            return false;
        }

        if(apenasPromotoresTripAdvisor == false && promotoresNeutrosTripAdvisor == false && todasTripAdvisor == false) {
            message.warning('Por favor, selecione pelo menos uma opção de exibição do link do tripAdvisor');
            return false;
        }

        const result = await salvarRedeSocial(request);
        return result ? result.object : false;
    }

    const carregarDados = () => {
        if(filial) {
            setConsultando(true);
            listarPorFilial(filial).then(result => {
                if(result.object) {
                    setTemAlteracao(true);
                    const insta = result.object.filter(x => x.tipo === 'Instagram');
                    if(insta && insta.length > 0) {
                        setExibirInstagram(true);
                        setUrlInstagram(insta[0].urlAcesso);
                        setIdInstagram(insta[0].id);
                        setTextoInsta(insta[0].texto);
                    } else {
                        setExibirInstagram(false);
                        setUrlInstagram('');
                        setIdInstagram(0);
                        setTextoInsta('');
                    }

                    const zap = result.object.filter(x => x.tipo === 'WhatsApp');
                    if(zap && zap.length > 0) {
                        setExibirWhatsApp(true);
                        setUrlWhatsApp(zap[0].urlAcesso);
                        setIdWhatsApp(zap[0].id);
                        setTextoWhats(zap[0].texto);
                    } else {
                        setExibirWhatsApp(false);
                        setUrlWhatsApp('');
                        setIdWhatsApp(0);
                        setTextoWhats('');
                    }

                    const google = result.object.filter(x => x.tipo === 'Google');
                    if(google && google.length > 0) {
                        setExibirGoogle(true);
                        setUrlGoogle(google[0].urlAcesso);
                        setIdGoogle(google[0].id);
                        setApenasPromotoresGoogle(google[0].exibirApenasPromotores);
                        setPromotoresNeutrosGoogle(google[0].exibirPromotoresNeutros);
                        setTodasGoogle(google[0].exibirParaTodas);
                        setNaoSugerirCriticasGoogle(google[0].exibirParaCriticas);
                    } else {
                        setExibirGoogle(false);
                        setUrlGoogle('');
                        setIdGoogle(0);
                        setApenasPromotoresGoogle(false);
                        setPromotoresNeutrosGoogle(false);
                        setTodasGoogle(false);
                        setNaoSugerirCriticasGoogle(false);
                    }

                    const trip = result.object.filter(x => x.tipo === 'TripAdvisor');
                    if(trip && trip.length > 0) {
                        setExibirTrip(true);
                        setUrlTrip(trip[0].urlAcesso);
                        setIdTrip(trip[0].id);
                        setApenasPromotoresTripAdvisor(trip[0].exibirApenasPromotores);
                        setPromotoresNeutrosTripAdvisor(trip[0].exibirPromotoresNeutros);
                        setTodasTripAdvisor(trip[0].exibirParaTodas);
                        setNaoSugerirCriticasTripAdvisor(trip[0].exibirParaCriticas);
                    } else {
                        setExibirTrip(false);
                        setUrlTrip('');
                        setIdTrip(0);
                        setApenasPromotoresTripAdvisor(false);
                        setPromotoresNeutrosTripAdvisor(false);
                        setTodasTripAdvisor(false);
                        setNaoSugerirCriticasTripAdvisor(false);
                    }
                }
            }).finally(() => {
                setConsultando(false);
            })
        }
    }

    useEffect(() => {
        carregarDados();
    },[]);

    function valorSelecionadoOpcoes(rede) {
        if(rede === 'Google')  {
            if(apenasPromotoresGoogle)
                return listaOpcoes[0].id;
            else if(promotoresNeutrosGoogle)
                return listaOpcoes[1].id;
            else if(todasGoogle) {
                return listaOpcoes[2].id;
            }
            else {
                return 0;
            }
        }
        else if(rede === 'TripAdvisor') {
            if(apenasPromotoresTripAdvisor)
                return listaOpcoes[0].id;
            else if(promotoresNeutrosTripAdvisor)
                return listaOpcoes[1].id;
            else if(todasTripAdvisor) {
                return listaOpcoes[2].id;
            } 
            else {
                return 0;
            }
        }
        else {
            return 0;
        }
    }

    function handleChangeOpcoes(rede, valor) {
        if(rede === 'Google')  {
            if(valor == listaOpcoes[0].id) {
                setApenasPromotoresGoogle(true);
                setPromotoresNeutrosGoogle(false);
                setTodasGoogle(false);
            }
            else if(valor == listaOpcoes[1].id) {
                setApenasPromotoresGoogle(false);
                setPromotoresNeutrosGoogle(true);
                setTodasGoogle(false);
            } 
            else if(valor == listaOpcoes[2].id) {
                setApenasPromotoresGoogle(false);
                setPromotoresNeutrosGoogle(false);
                setTodasGoogle(true);
            }
        }
        else if(rede === 'TripAdvisor') {
            if(valor == listaOpcoes[0].id) {
                setApenasPromotoresTripAdvisor(true);
                setPromotoresNeutrosTripAdvisor(false);
                setTodasTripAdvisor(false);
            }
            else if(valor == listaOpcoes[1].id) {
                setApenasPromotoresTripAdvisor(false);
                setPromotoresNeutrosTripAdvisor(true);
                setTodasTripAdvisor(false);
            } 
            else if(valor == listaOpcoes[2].id) {
                setApenasPromotoresTripAdvisor(false);
                setPromotoresNeutrosTripAdvisor(false);
                setTodasTripAdvisor(true);
            }
        }
    }

    return (
        <Card className='card-redes-sociais' title="Redes Sociais da Unidade" bordered={true}>
            
            <Modal visible={openGoogle} onOk={() => setOpenGoogle(false)} onCancel={() => setOpenGoogle(false)} title="Configurando Google" cancelButtonProps={{
                hidden: true
            }}>
                <ul>
                    <li>Acesse <a href="https://business.google.com" target='_blank'>https://business.google.com</a> e faça login (Se você tiver vários perfis, abra aquele que gostaria de gerenciar).</li>
                    <li>No menu à esquerda, clique em "Início".</li>
                    <li>No card "Receba mais avaliações", clique em "Compart. form. de avaliação", copie a URL e cole aqui na Plataforma Blue, no campo destinado à URL de avaliação Google.</li>
                </ul>
            </Modal>
            <Modal visible={openTrip} onOk={() => setOpenTrip(false)} onCancel={() => setOpenTrip(false)} title="Configurando TripAdvisor" cancelButtonProps={{
                hidden: true
            }}>
                <ul>
                    <li>Acesse <a href="https://tripadvisor.com.br/UserReview" target="_blank">https://tripadvisor.com.br/UserReview</a></li>
                    <li>Digite o nome do seu negócio e clique nele. (Caso seu negócio ainda não exista no TripAdvisor, será necessário criá-lo acessando: <a href="https://www.tripadvisor.com.br/CreateListing.html" target="_blank">https://www.tripadvisor.com.br/CreateListing.html</a>)</li>
                    <li>Após clicar, irá aparecer a Página de Avaliação do seu negócio. Copie a URL da página e cole aqui na Plataforma Blue, no campo destinado à URL do TripAdvisor.</li>
                </ul>
            </Modal>
            <Modal visible={openInsta} onOk={() => setOpenInsta(false)} onCancel={() => setOpenInsta(false)} title="Configurando Instagram" cancelButtonProps={{
                hidden: true
            }}>
                Digite <br />
                <a href="https://instagram.com/SeuNomeDeUsuário">https://instagram.com/SeuNomeDeUsuário</a>
            </Modal>
            <Modal visible={openZap} onOk={() => setOpenZap(false)} onCancel={() => setOpenZap(false)} title="Configurando WhatsApp" cancelButtonProps={{
                hidden: true
            }}>
                Digite <br />
                <a href="https://wa.me/55DDDseunúmero">https://wa.me/55DDDseunúmero</a>
            </Modal>
            {
                consultando ? (<Spin />) : (
                    <>
                        <Row>
                            <Col span={12}>
                                <Checkbox onChange={(e) => setExibirInstagram(e.target.checked)} checked={exibirInstagram}>
                                    <label className="ml-2">Mostrar link para Instagram</label>
                                </Checkbox>
                                <Tooltip title="Clique para obter informações" placement="right">
                                    <Icon type="info-circle" style={{marginLeft: '6px', cursor: 'pointer'}} onClick={() => setOpenInsta(true)} />
                                </Tooltip>
                                <Input disabled={!exibirInstagram} type="text" value={urlInstagram} onChange={(e) => handleUrl("Instagram",e.target.value)} placeholder="Informe a URL do seu instagram"/>
                                <label style={{display: 'block'}}>Texto descritivo:</label>
                                <Input disabled={!exibirInstagram} type="text" value={textoInsta} onChange={(e) => setTextoInsta(e.target.value)} placeholder="Texto de exibição para instagram" maxLength={40}/>
                            </Col>
                            <Col span={10} style={{marginLeft: '8px'}}>
                                <Checkbox onChange={(e) => setExibirWhatsApp(e.target.checked)} checked={exibirWhatsApp}>
                                    <label className="ml-2">Mostrar link para WhatsApp</label>
                                </Checkbox>
                                <Tooltip title="Clique para obter informações" placement="right">
                                    <Icon type="info-circle" style={{marginLeft: '6px', cursor: 'pointer'}} onClick={() => setOpenZap(true)} />
                                </Tooltip>
                                <Input disabled={!exibirWhatsApp} type="text" value={urlWhatsApp} onChange={(e) => handleUrl("WhatsApp",e.target.value)} placeholder="Informe a URL do seu whatsapp"/>
                                <label style={{display: 'block'}}>Texto descritivo:</label>
                                <Input disabled={!exibirWhatsApp} type="text" value={textoWhats} onChange={(e) => setTextoWhats(e.target.value)} placeholder="Texto de exibição para whatsApp" maxLength={40}/>
                            </Col>
                        </Row>
                        <hr style={{marginTop: '10px', color: '#ccc'}} />
                        <Row style={{marginTop: '10px'}}>
                            <Col span={12}>
                                <Checkbox onChange={(e) => setExibirGoogle(e.target.checked)} checked={exibirGoogle}>
                                    <label className="ml-2">Mostrar link para Google</label>
                                </Checkbox>
                                <Tooltip title="Clique para obter orientações" placement="right">
                                    <Icon type="info-circle" style={{marginLeft: '6px', cursor: 'pointer'}} onClick={() => setOpenGoogle(true)} />
                                </Tooltip>
                                <Input disabled={!exibirGoogle} type="text" value={urlGoogle} onChange={(e) => handleUrl("Google",e.target.value)} placeholder="Informe a URL de avaliação do google"/>
                                <label style={{display: 'block'}}>Mostrar quando:</label>
                                <Select defaultValue={0} style={{ width: '100%'}}
                                    value={valorSelecionadoOpcoes('Google')}
                                    onChange={(value) => handleChangeOpcoes('Google',value)}
                                    disabled={!exibirGoogle}
                                >
                                    <Option value={0}>Selecione...</Option>
                                    {
                                        listaOpcoes.map(l => {
                                            return (
                                                <Option value={l.id} key={l.id}>{l.texto}</Option>
                                            )
                                        })
                                    }
                                </Select> 
                                <Checkbox disabled={!exibirGoogle} checked={naoSugerirCriticasGoogle} onChange={(e) => setNaoSugerirCriticasGoogle(e.target.checked)}>Não mostrar para avaliações com críticas</Checkbox><br />
                            </Col>
                            <Col span={10} style={{marginLeft: '8px'}}>
                                <Checkbox onChange={(e) => setExibirTrip(e.target.checked)} checked={exibirTrip}>
                                    <label className="ml-2">Mostrar link para TripAdvisor</label>
                                </Checkbox>
                                <Tooltip title="Clique para obter orientações" placement="right">
                                    <Icon type="info-circle" style={{marginLeft: '6px', cursor: 'pointer'}} onClick={() => setOpenTrip(true)} />
                                </Tooltip>
                                <Input disabled={!exibirTrip} type="text" value={urlTrip} onChange={(e) => handleUrl("Trip",e.target.value)} placeholder="Informe a URL de avaliação do TripAdvisor"/>
                                <label style={{display: 'block'}}>Mostrar quando:</label>
                                <Select defaultValue={0} style={{ width: '100%'}}
                                    value={valorSelecionadoOpcoes('TripAdvisor')}
                                    onChange={(value) => handleChangeOpcoes('TripAdvisor',value)}
                                    disabled={!exibirTrip}
                                >
                                    <Option value={0}>Selecione...</Option>
                                    {
                                        listaOpcoes.map(l => {
                                            return (
                                                <Option value={l.id} key={l.id}>{l.texto}</Option>
                                            )
                                        })
                                    }
                                </Select>
                                <Checkbox disabled={!exibirTrip} checked={naoSugerirCriticasTripAdvisor} onChange={(e) => setNaoSugerirCriticasTripAdvisor(e.target.checked)}>Não mostrar para avaliações com críticas</Checkbox><br />
                            </Col>
                        </Row>
                        <Row style={{marginTop: '6px'}}>
                            <Col span={24} style={{textAlign: 'right'}}>
                                <Button disabled={!temAlteracao} onClick={() => handleRedeSocial()}>
                                    {
                                        carregando ? (
                                            <Spin />
                                        ) : ('Salvar')
                                    }
                                </Button>                    
                            </Col>
                        </Row>
                    </>
                )
            }
        </Card>
    )
}

export default RedeSocial;