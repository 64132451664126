import Services from '../../services';

export default class Service extends Services {
  getBeneficioInfo = async id => {
    try {
      const response = await fetch(
        `${this.endPoint}Beneficio/${id}`,
        {
          method: 'GET',
          headers: {
            //Authorization: `Bearer ${tokenLogin}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const json = await response.json();

      return json;
    } catch (err) {
      console.log('Error problem', err);
    }
  };
  listarRedeSocialPorFilial = async filial => {
    try {
      const response = await fetch(
        `${this.endPoint}RedeSocial/listar-por-filial/${filial}`,
        {
          method: 'GET',
          headers: {
            //Authorization: `Bearer ${tokenLogin}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const json = await response.json();

      return json;
    } catch (err) {
      console.log('Error problem', err);
    }
  };
}
