import React, { Component } from 'react';
import * as jwt_decode from 'jwt-decode';
import PropTypes from 'prop-types';
import {
  Layout, Icon, Card, Col, Row, Switch,
} from 'antd';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getIdContract, getModalTrue, getTitleModal } from '../../redux/actions';

import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Breadcrumb from '../../components/Breadcrumb';
import ModalContracts from '../../components/ModalContracts';

import Service from './Service';
import update from 'immutability-helper';

import './styles.css';

const { Content } = Layout;

class ListContract extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      data: [],
      arrIdContract: [],
      contract: {
        titulo: '',
        descricao: '',
      },
    };

    this.service = new Service();
    this.lisContracts = this.lisContracts.bind(this);
  }

  async componentDidMount() {
    if (!this.service.isLogged()) {
      this.props.history.push('/login');
    } else {
      const tokenLogin = localStorage.getItem('token');
      const decodeToken = jwt_decode(tokenLogin);
      this.setState({
        role: decodeToken.role,
        dataUser: decodeToken
      })
      this.lisContracts();
    }
  }

  onChange = async (item, index) => {
    const ativo = !this.state.data[index].ativo

    await this.setState({
      data: update(this.state.data, {[index]: {ativo: {$set: ativo}}})
    });

    ativo
      ? await this.service.activeContract(item.id)
      : await this.service.disableContract(item.id)
  };

  // save state modal true in redux
  openModal(item) {
    this.setState({
      idContract: item.id,
      contract: item
    })

    const payloadModal = {
      visible: true,
      data: item
    }

    this.props.getModalTrue(payloadModal);
    this.props.getTitleModal('Editar Contrato')
  }

  onCreateContract = async (data) => {
    if (data.id) {
      const payloadUpdate = {
        id: data.id,
        titulo: data.titulo,
        descricao: data.descricao,
      };
      await this.service.updateContract(data.id, payloadUpdate);
    } else {
      const payloadCreate = {
        titulo: data.titulo,
        descricao: data.descricao,
      };
      await this.service.createContract(payloadCreate);
    }
    this.lisContracts();
  };

  async lisContracts() {
    const { getIdContract } = this.props;
    const result = await this.service.lisContracts();

    const idcontract = result.object.map(item => item);

    getIdContract(idcontract);
    this.setState({ arrIdContract: idcontract });

    if (result === undefined) {
      await this.service.doLogout();
      this.props.history.push('/login');
    } else {
      this.setState({ data: result.object });
    }
  }

  async deleteContract(id) {
    await this.service.deleteContract(id);
    this.lisContracts();
  }

  render() {
    const { data } = this.state;
    const home = {
      home: "Início",
      user: "Usuários",
      company: "Empresas",
      contracts: "Contrato",
      plans: "Planos",
      logout: "Sair",
      iconClient: {
        home: "dashboard",
        user: "user",
        company: "bank",
        contracts: "profile",
        plans: "tag",
        logout: "logout"
      }
    }

    const routesClient = {
      home: "/",
      user: "administrators",
      company: "companys",
      contracts: "contracts",
      companyClient: "companyClient",
      plans: "plans",
    }

    return (
      <Layout>
        <Sidebar dataSidebar={home} routes={routesClient} current="contracts" />
        <Layout>
          <Header dataUser={this.state.dataUser} />
          <Row className="nopadding-col">
            <Col md={{ span: 16 }} xs={{ span: 24 }}>
              <Breadcrumb pages={[{ title: 'Contratos' }]} />
            </Col>
            <Col md={{ span: 8 }} xs={{ span: 24 }}>
              <div className="new-item">
                <ModalContracts
                  active
                  titleButton="Novo Contrato"
                  onCreateContract={this.onCreateContract}
                  // arrIdContract={arrIdContract}
                  // dataContract={contract}
                />
              </div>
            </Col>
          </Row>
          <Content className="content">
            <div style={{ background: '#FFF', padding: '30px', height: '80vh' }}>
              <Row gutter={16}>
                {data.map((item, index) => (
                  <Col key={item.id} lg={8} sm={12} xs={24}>
                    <Card
                      style={{ marginTop: 10, backgroundColor: '#ECECEC' }}
                      title={item.titulo}
                      extra={
                        <Switch checked={Boolean(item.ativo)} onChange={() => this.onChange(item, index)} />
                      }
                      actions={[
                        <Icon
                          type="edit"
                          style={{ color: '#00a1ed' }}
                          onClick={() => this.openModal(item)}
                        />,
                        <Icon
                          type="delete"
                          onClick={() => this.deleteContract(item.id)}
                          style={{ color: '#00a1ed' }}
                        />,
                      ]}
                      bordered={false}
                    >
                      {item.descricao}
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ getIdContract, getModalTrue, getTitleModal }, dispatch);
const mapStateToProps = store => ({
  contractId: store.userData.contractId,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ListContract);
