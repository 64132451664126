import Services from '../../services';

export default class Service extends Services {
  lisPlans = async () => {
    const tokenLogin = localStorage.getItem('token');
    try {
      const response = await fetch(`${this.endPoint}Plano`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'text/plain',
        },
      });

      const json = await response.json();

      return json;
    } catch (err) {
      console.log('Error problem', err);
      this.doLogout()
    }
  };

  lisPlansFunctions = async () => {
    const tokenLogin = localStorage.getItem('token');
    try {
      const response = await fetch(`${this.endPoint}Funcionalidade`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'text/plain',
        },
      });

      const json = await response.json();

      return json;
    } catch (err) {
      console.log('Error problem', err);
      this.doLogout()
    }
  };

  createPlans = async (body) => {
    const tokenLogin = localStorage.getItem('token');
    try {
      const response = await fetch(`${this.endPoint}Plano`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      const json = await response.json();
      console.log("json", json)

      return json;
    } catch (err) {
      console.log('Error problem', err);
      this.doLogout()
    }
  };

  activePlans = async (id) => {
    console.log('id', id);
    const tokenLogin = localStorage.getItem('token');
    try {
      const response = await fetch(`${this.endPoint}Plano/${id}/alterarsituacao`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'text/plain',
        },
      });

      const json = await response.json();
      console.log('json', json);

      return json;
    } catch (err) {
      console.log('Error problem', err);
      this.doLogout()
    }
  };

  editPlans = async (id, body) => {
    const tokenLogin = localStorage.getItem('token');
    try {
      const response = await fetch(`${this.endPoint}Plano/${id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'text/plain',
        },
        body: JSON.stringify(body),
      });

      const json = await response.json();
      console.log('json', json);

      return json;
    } catch (err) {
      console.log('Error problem', err);
      this.doLogout()
    }
  };

  updatePlans = async (body) => {
    console.log("body", body)
    const tokenLogin = localStorage.getItem('token');
    try {
      const response = await fetch(`${this.endPoint}Plano/${body.id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      const json = await response.json();
      console.log('json', json);

      return json;
    } catch (err) {
      console.log('Error problem', err);
      this.doLogout()
    }
  };

  changePlans = async (id, body) => {
    const tokenLogin = localStorage.getItem('token');
    try {
      const response = await fetch(`${this.endPoint}Plano/${id}/alterarsituacao`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'text/plain',
        },
        body: JSON.stringify(body),
      });

      const json = await response.json();

      return json;
    } catch (err) {
      console.log('Error problem', err);
      this.doLogout()
    }
  };

  deletePlans = async (id) => {
    const tokenLogin = localStorage.getItem('token');
    try {
      const response = await fetch(`${this.endPoint}Plano/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'text/plain',
        },
      });

      const json = await response.json();

      return json;
    } catch (err) {
      console.log('Error problem', err);
      this.doLogout()
    }
  };

  disablePlans = async (id) => {
    const tokenLogin = localStorage.getItem('token');
    try {
      const response = await fetch(`${this.endPoint}Plano/${id}/desativar`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'text/plain',
        },
      });

      const json = await response.json();

      return json;
    } catch (err) {
      console.log('Error problem', err);
      this.doLogout()
    }
  };
}
