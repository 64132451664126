import Services from '../../services';

export default class Service extends Services {
  lisPlans = async () => {
    const tokenLogin = localStorage.getItem('token');

    try {
      const response = await fetch(`${this.endPoint}Plano/ativos`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'text/plain',
        },
      });

      const json = await response.json();

      return json;
    } catch (err) {
      console.log('Error problem', err);
      this.doLogout()
    }
  };

  listCompany = async (tokenValue) => {
    const tokenLogin = localStorage.getItem('token');
    try {
      const response = await fetch(`${this.endPoint}Empresa`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'text/plain',
        },
      });

      const json = await response.json();

      return json;
    } catch (err) {
      console.log('Error problem', err);
      this.doLogout()
    }
  };

  listFilial = async (tokenValue) => {
    const tokenLogin = localStorage.getItem('token');
    try {
      const response = await fetch(`${this.endPoint}Filial`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'text/plain',
        },
      });

      const json = await response.json();

      return json;
    } catch (err) {
      console.log('Error problem', err);
      this.doLogout()
    }
  };

  createCompanyFilial = async (body) => {
    const tokenLogin = localStorage.getItem('token');
    try {
      const response = await fetch(`${this.endPoint}Filial`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      const json = await response.json();

      return json;
    } catch (err) {
      console.log('Error problem', err);
      this.doLogout()
    }
  };

  lisPlansAtivos = async () => {
    const tokenLogin = localStorage.getItem('token');
    try {
      const response = await fetch(`${this.endPoint}Plano/Ativos`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'text/plain',
        },
      });

      const json = await response.json();

      return json;
    } catch (err) {
      console.log('Error problem', err);
      this.doLogout()
    }
  };

  // Create Ivitation
  createInvitation = async (body) => {
    console.log('body', body);
    const tokenLogin = localStorage.getItem('token');
    try {
      const response = await fetch(`${this.endPoint}Convite`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      const json = await response.json();
      console.log('json', json);

      return json;
    } catch (err) {
      console.log('Error problem', err);
      this.doLogout()
    }
  };

  getDataNps = async (dataInicial, dataFinal, idFilial, idEmpresa) => {
    const tokenLogin = localStorage.getItem('token');
    try {
      let url = `${this.endPoint}Graficos/dadoGraficoNPS/${dataInicial}/${dataFinal}/${idFilial}`;
      if(idEmpresa > 0)
      {
        url += `?idEmpresa=${idEmpresa}`;
      }
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'application/json',
        },
      });

      const json = await response.json();

      return json;
    } catch (err) {
      console.log('Error problem', err);
      this.doLogout()
    }
  };

  getDataBlue = async () => {
    const tokenLogin = localStorage.getItem('token');
    try {
      const response = await fetch(`${this.endPoint}Graficos/dadosBlue`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'text/plain',
        },
      });

      const json = await response.json();

      return json;
    } catch (err) {
      console.log('Error problem', err);
      this.doLogout()
    }
  };

  getLoggedUser = () => JSON.parse(localStorage.getItem('user'));
}


