export const getUserData = value => ({
  type: 'USER_DATA',
  data: value,
});

export const getUserId = value => ({
  type: 'GET_USER_ID',
  userId: value,
});

export const getModalTrue = value => ({
  type: 'MODAL_TRUE',
  modalState: value,
});

export const getUserModal = value => ({
  type: 'USER_MODAL',
  userModal: value,
});

export const getUserDataUpdate = value => ({
  type: 'USER_DATA_UPDATE',
  userDataUpdate: value,
});

export const getIdContract = value => ({
  type: 'CONTRACT_ID',
  contractId: value,
});

export const getTitleModal = value => ({
  type: 'TITLE_MODAL',
  titleModal: value,
});

export const getDataPlans = value => ({
  type: 'DATA_PLANS',
  dataPlans: value,
});

export const getCompanyPlan = value => ({
  type: 'COMPANY_PLAN',
  companyPlan: value,
});

export const getDataPlansFunctions = value => ({
  type: 'DATA_PLANS_FUNCTIONS',
  dataPlansFunctions: value,
});

export const getDataUserCompany = value => ({
  type: 'DATA_USER_COMPANY',
  dataUserCompany: value,
});

export const setCompanySelected = value => ({
  type: 'COMPANY_SELECTED',
  companySelected: value,
});

export const getDataFilial = value => ({
  type: 'DATA_FILIAL',
  dataFilial: value,
});

export const setClientFilial = value => ({
  type: 'CLIENT_FILIAL',
  clientFilial: value,
});

export const setClientInvite = value => ({
  type: 'SET_INVITATION',
  invite: value,
});

export const getIdFilial = value => ({
  type: 'GET_FILIAL_ID',
  filialId: value,
});

export const setChecketedRow = value => ({
  type: 'SET_CHECKETED_ROW',
  rowSelected: value,
});

export const setQuestionsFilial = value => ({
  type: 'SET_QUESTIONS_FILIAL',
  questionsFilial: value,
});
