import React, { Component } from 'react';
import { Layout, Row, Col, Button, Divider, Card, Form } from 'antd';
import './styles.css';
import logoIcon from '../../images/logo_blue.png';
import btnGoogle from '../../images/btnGoogle.png';
import btnTrip from '../../images/btnTripAdvisor.png';
import btnInsta from '../../images/btnInstagram.png';
import btnZap from '../../images/btnWhatsApp.png';

import Service from './Service';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getUserData,
  getDataUserCompany,
  getModalTrue,
} from '../../redux/actions';
import { obterLogo } from '../../components/ModalLogomarca/services';

const { Header, Content } = Layout;

const LINK_BLUE_WEBSITE = "https://www.blueexperiencias.com.br/";

class QuizThanks extends Component {
  constructor(props) {
    super(props);

    this.state = { 
      loading: true,
      temRedeSocial: false,
      exibirGoogle: false,
      exibirTrip: false,
      exibirInsta: false,
      exibirWhatsApp: false,
      urlGoogle: '',
      urlTrip: '',
      urlInsta: '',
      urlWhats: '',
      critica: '',
      elogio: '',
      textoInsta: '',
      textoZap: '',
      naoPodeGanharPremio: false,
      semConfigLimitePremio: false
    };
    this.service = new Service();
  }

  async componentDidMount() {
    const { idBeneficio } = this.props.match.params;

    if (idBeneficio && idBeneficio != 2022080299 && idBeneficio != 2022080699) {
      try {
        let result = await this.service.getBeneficioInfo(idBeneficio);

        if (result && result.code == 200 && result.object && result.object.idBeneficio == idBeneficio) {
          this.setState({ beneficio: result.object });
        }
      } catch (error) {
        console.log('Error problem:', error);
      }
    } else if(idBeneficio == 2022080299) {
      this.setState({
        naoPodeGanharPremio: true
      })
    } else if(idBeneficio == 2022080699) {
      this.setState({
        naoPodeGanharPremio: true,
        semConfigLimitePremio: true
      })
    }

    

    let quiosque = undefined;
    try {
      quiosque = window.sessionStorage.getItem('quiosque');

      if(typeof quiosque === 'string') {
        window.setTimeout(() => { window.location.href = quiosque; }, 10 * 1000);
      }
    } catch(error) {
      console.error(error);
    }

    const { idEmpresa, idFilial } = this.props.match.params;
    const stateQuiz = this.props.location.state;
    const { nota, response } = stateQuiz;
    const elogio = response.filter(x => x.idPergunta === 86);
    const critica = response.filter(x => x.idPergunta === 87);
    const resultRedes = await this.service.listarRedeSocialPorFilial(idFilial);
    const redes = resultRedes.object;
    let google = false;
    let googleUrl = '';
    let trip = false;
    let tripUrl = '';
    let insta = false;
    let instaUrl = '';
    let zap = false;
    let zpUrl = '';
    let exibirRedes = false;
    let txtCritica = '';
    let txtElogio = '';
    let txtInsta = '';
    let txtZap = '';

    if(!quiosque) {
      
      if(critica && critica.length > 0)
      txtCritica = critica[0].valorChar;
      if(elogio && elogio.length > 0)
      txtElogio = elogio[0].valorChar;

      if(redes && redes.length > 0) {
          exibirRedes = true;
          const goo = redes.filter(x => x.tipo === 'Google');
          if(goo && goo.length > 0) {
            googleUrl = goo[0].urlAcesso;
            if(nota >= 9) {
              if(goo[0].exibirApenasPromotores) {
                google = true;
              }
            }
            if(nota >= 7) {
              if(goo[0].exibirPromotoresNeutros) {
                google = true;
              }
            }
            if(goo[0].exibirParaTodas) {
              google = true;
            }
            if(goo[0].exibirParaCriticas && critica && critica.length > 0 && critica[0].valorChar) {
              google = false;
            }
          } else {
            google = false;
          }

          const trp = redes.filter(x => x.tipo === 'TripAdvisor');
          if(trp && trp.length > 0) {
            tripUrl = trp[0].urlAcesso;
            if(nota >= 9) {
              if(trp[0].exibirApenasPromotores) {
                trip = true;
              }
            }
            if(nota >= 7) {
              if(trp[0].exibirPromotoresNeutros) {
                trip = true;
              }
            }
            if(trp[0].exibirParaTodas) {
              trip = true;
            }
            if(trp[0].exibirParaCriticas && critica && critica.length > 0 && critica[0].valorChar) {
              trip = false;
            }
          } else {
            trip = false;
          }

          if(!google && !trip) {
            exibirRedes = false;
          }

          const ig = redes.filter(x => x.tipo === 'Instagram');
          if(ig && ig.length > 0) {
            insta = true;
            instaUrl = ig[0].urlAcesso;
            txtInsta = ig[0].texto;
          } else {
            insta = false;
          }

          const zp = redes.filter(x => x.tipo === 'WhatsApp');
          if(zp && zp.length > 0) {
            zap = true;
            zpUrl = zp[0].urlAcesso;
            txtZap = zp[0].texto;
          } else {
            zap = false;
          }
      }
    }
    await this.carregarLogo(Number(idEmpresa),Number(idFilial));

    this.setState({ 
      loading: false,
      temRedeSocial: exibirRedes,
      exibirGoogle: google,
      exibirInsta: insta,
      exibirTrip: trip,
      exibirWhatsApp: zap,
      urlGoogle: googleUrl,
      urlTrip: tripUrl,
      urlInsta: instaUrl,
      urlWhats: zpUrl,
      critica: txtCritica,
      elogio: txtElogio,
      textoInsta: txtInsta,
      textoZap: txtZap
    });

    
  }

  carregarLogo = async (idEmpresa, idFilial) => {
    let url = '';
    const resultFilial = await obterLogo(idEmpresa, idFilial);
    url = resultFilial.url;
    if(!resultFilial.url) {
      const resultEmpresa = await obterLogo(idEmpresa, 0);
      url = resultEmpresa.url;
    }
    this.setState({
      logoCliente: url
    })
  }

  handleUrl() {
    window.addEventListener('hashhttps://www.blueexperiencias.com.br/change');
  }

  handleGoogle() {
    let text = this.state.elogio + '.' + this.state.critica;
    navigator.clipboard.writeText(text).then(() => {
      window.open(this.state.urlGoogle, '_blank');
    });
    
  }
  handleTrip() {
    let text = this.state.elogio + '.' + this.state.critica;
    navigator.clipboard.writeText(text).then(() => {
      window.open(this.state.urlTrip, '_blank')
    });
  }
  handleInsta() {
    window.open(this.state.urlInsta, '_blank');
  }
  handleWhatsApp() {
    window.open(this.state.urlWhats, '_blank');
  }
  render() {
    const {
      temRedeSocial,
      exibirGoogle,
      exibirInsta,
      exibirTrip,
      exibirWhatsApp,
      textoInsta,
      textoZap
    } = this.state;
    return (
      <Layout>
        <Content style={{
          padding: '24px 16px 0',
          backgroundColor: '#FFF',
          fontSize: '20pt',
          fontWeight: 'lighter'
        }}>
          {this.state.loading ? (
            <Row style={{
              flexDirection: 'row',
              textAlign: 'center',
              marginTop: 50
            }}>
              <Col style={{ marginTop: 50 }}>
                <p style={{ marginBottom: '-20px', fontSize: '32pt' }}>Quase pronto...</p>
              </Col>
            </Row>
          ) : (
              <>
              <Row style={{
                flexDirection: 'row',
                textAlign: 'center',
                marginTop: 50
              }}>
                {
                    this.state.logoCliente && (
                      <Row style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                        <img src={this.state.logoCliente} class="logo-cliente" />
                      </Row>
                    )
                  }
                <Col style={{ marginTop: 50 }}>
                  <p style={{ marginBottom: '-20px', fontSize: '32pt' }}>Pronto.</p>
                  <p style={{ marginTop: '-20px', fontSize: '32pt' }}>
                    <span style={{ fontWeight: 'bold' }}>Obrigado!</span>
                  </p>
                </Col>
                <Col span={24}>
                  <p>Você nos ajudou a melhorar a sua experiência e as de outras pessoas.</p>
                  {this.state.beneficio ? (
                    <div>
                      <p dangerouslySetInnerHTML={{ __html: this.state.beneficio.textoFinal }}></p>
                      <p style={{ fontSize: '12pt' }}>(Confira seu e-mail, valide o cupom e aproveite a próxima experiência.)</p>
                    </div>) 
                    : 
                    (
                      <>
                        {
                          this.state.naoPodeGanharPremio ? (
                            <>
                              {
                                this.state.semConfigLimitePremio ? (
                                  <p style={{ fontSize: '12pt' }}>
                                  (Desta vez não conseguimos gerar seu cupom, pois você já ganhou um informando este e-mail).
                                </p>
                                ) : (
                                  <p style={{ fontSize: '12pt' }}>
                                    (Desta vez não conseguimos gerar seu cupom, pois você já ganhou um há menos de {this.props.questionsFilial.beneficio.tempoProximoPremioMesmoEmailDias} dia(s)).
                                  </p>
                                )
                              }
                            </>
                            
                          ) : (<></>)
                        }
                      </>
                    )
                  }
                </Col>
              </Row></>)}
              {
                temRedeSocial && (
                  <>
                    <Card style={{textAlign: 'center', backgroundColor: 'rgb(241 241 241)', fontSize: '16pt', overflow: 'hidden', paddingBottom: '10px'}} bordered={true}>
                      <Row>
                        <Col>
                          Compartilhe esta mesma avaliação em outras<br /> 
                          plataformas para que mais pessoas saibam sua opinião.<br />
                          <p className="orientacao-rede-social">
                            (Basta clicar nos links abaixo e COLAR. Os comentários que você fez já foram COPIADOS)
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}>
                          {
                            exibirGoogle && (
                              <Button onClick={() => this.handleGoogle()} className="btn-rede-social btn-avaliacao-externa">
                                <label>Avalie no</label>
                                <img src={btnGoogle} alt="Avalie no Google"  />
                              </Button>
                            )
                          }
                          {
                            exibirTrip && (
                              <Button onClick={() => this.handleTrip()} className="btn-rede-social btn-avaliacao-externa">
                                <label>Avalie no</label>
                                <img src={btnTrip} alt="Avalie no TripAdvisor" /> 
                              </Button>
                            )
                          }
                        </Col>
                      </Row>
                    </Card>
                  </>
                )
              }
              <div className='div-follow-us'>
              {
                exibirInsta && (
                  <Button onClick={() => this.handleInsta()} className="btn-rede-social follow-us">
                    <img src={btnInsta} alt="Siga a gente no instagram" />
                    <label>{textoInsta}</label>
                  </Button>
                )
              }
              {
                exibirWhatsApp && (
                  <Button onClick={() => this.handleWhatsApp()} className="btn-rede-social follow-us">
                    <img src={btnZap} alt="Fale com a gente no whatsapp" />
                    <label>{textoZap}</label>
                  </Button>
                )
              }
              </div>
          <Row style={{
            fontSize: '14pt',
            borderTop: 'solid 2px #1b6dc677',
            marginTop: '60px',
            paddingTop: '30px',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'row'
          }}>
            <div style={{ flex: 1 }}>
              <a href={LINK_BLUE_WEBSITE} target="_blank" style={{ color: 'rgba(0, 0, 0, 0.65)' }}>
                <p style={{
                  marginBottom: '0px',
                  textAlign: 'right'
                }}>monitoramento</p>
                <p style={{
                  marginTop: '-10px',
                  textAlign: 'right'
                }}>via</p>
              </a>
            </div>
            <div style={{ flex: 1 }}>
              <a href={LINK_BLUE_WEBSITE} target="_blank" style={{ color: 'rgba(0, 0, 0, 0.65)' }}>
                <img src={logoIcon} style={{ width: 60, float: 'left', marginLeft: '5px' }} alt="Blue" />
              </a>
            </div>
          </Row>
        </Content>
      </Layout >
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { getUserData, getDataUserCompany, getModalTrue },
    dispatch
  );

const mapStateToProps = store => ({
  userData: store.userData.data,
  dataUserCompany: store.userData.dataUserCompany,
  clientFilial: store.userData.clientFilial,
  filialId: store.userData.filialId,
  questionsFilial: store.userData.questionsFilial,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(QuizThanks));
