import Services from '../../services';

export async function salvarLogo(empresaId, filialId, arquivo) {
  const service = new Services();
  const tokenLogin = localStorage.getItem('token');
  const formData = new FormData();
  formData.append('empresaId', empresaId);
  formData.append('filialId', filialId);
  formData.append('arquivo', arquivo);
  try {
    const response = await fetch(`${service.endPoint}Logomarca`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${tokenLogin}`,
      },
    });

    const json = await response.json();

    return json;
  } catch (err) {
    console.log('Error problem', err);
  }
}

export async function obterLogo(empresaId, filialId) {
  let obj = {
    url: '',
    id: 0,
  };

  const endPoint =
    empresaId > 0 && filialId > 0
      ? 'consultar-por-filial'
      : 'consultar-por-empresa';
  const idPesquisa = empresaId > 0 && filialId > 0 ? filialId : empresaId;
  const service = new Services();
  const tokenLogin = localStorage.getItem('token');
  try {
    const response = await fetch(
      `${service.endPoint}Logomarca/${endPoint}/${idPesquisa}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
        },
      }
    );

    const json = await response.json();
    if (json.code === 200) {
      const { arquivo, id, planoEmpresa } = json.object;
      obj.url = arquivo;
      obj.id = id;
      obj.planoEmpresa = planoEmpresa;
    }
  } catch (err) {
    console.log('Error problem', err);
  }

  return obj;
}

export async function removerLogo(id) {
  const service = new Services();
  const tokenLogin = localStorage.getItem('token');
  try {
    const response = await fetch(`${service.endPoint}Logomarca/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${tokenLogin}`,
      },
    });

    const json = await response.json();

    return json;
  } catch (err) {
    console.log('Error problem', err);
  }
}
