import Services from "../../services";

export async function salvarTemplateEmail(empresaId, conteudo, status, tipoRegra, tipoEmail, assunto, imagem, idAtual) {
    const service = new Services();
    const tokenLogin = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('empresaId',empresaId);
    formData.append('conteudo', conteudo);
    formData.append('status', status);
    formData.append('tipoRegra', tipoRegra);
    formData.append('tipo', tipoEmail);
    formData.append('assunto', assunto);
    formData.append('imagem', imagem);
    const method = idAtual > 0 ? 'PUT' : 'POST';
    const url = `${service.endPoint}TemplateEmail${!idAtual ? '' : `/${idAtual}`}`
    try {
      const response = await fetch(url, {
        method: method,
        body: formData,
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
        },
      });

      const json = await response.json();

      return json;
    } catch (err) {
      console.log('Error problem', err);
    }
}

export async function obterTemplate(empresaId) {
  let obj = {
      conteudo: '',
      tipoRegra: 0,
      tipo: 0,
      status: true,
      id: 0,
      assunto: '',
      imagem: ''
  }

  const service = new Services();
  const tokenLogin = localStorage.getItem('token');
  try {
      const response = await fetch(`${service.endPoint}TemplateEmail/listar/${empresaId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokenLogin}`
        },
      });

      const json = await response.json();
      if(json.code === 200) {
          const { conteudo, tipoRegra, tipo, status, id, assunto, imagem } = json.object[0];
          obj.conteudo = conteudo;
          obj.status = status;
          obj.tipoRegra = tipoRegra;
          obj.tipo = tipo;
          obj.id = id;
          obj.assunto = assunto;
          obj.imagem = imagem;
      }
    } catch (err) {
      console.log('Error problem', err);
    }

  return obj;
}

export async function removerTemplate(id) {
  const service = new Services();
  const tokenLogin = localStorage.getItem('token');
  try {
    const response = await fetch(`${service.endPoint}TemplateEmail/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${tokenLogin}`
      },
    });

    const json = await response.json();

    return json;
  } catch (err) {
    console.log('Error problem', err);
  }
}

export async function removerImagem(id) {
  const service = new Services();
  const tokenLogin = localStorage.getItem('token');
  try {
    const response = await fetch(`${service.endPoint}TemplateEmail/remover-imagem/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${tokenLogin}`
      },
    });

    const json = await response.json();

    return json;
  } catch (err) {
    console.log('Error problem', err);
  }
}