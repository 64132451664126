import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Layout, Menu, Icon, message, Modal } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setClientFilial } from '../../redux/actions';

import Services from '../../services';
import helpIcon from '../../images/question-icon.png';
import simplifiedLogo from '../../images/logo-reduzida.png';
import theater from '../../images/theater-masks.png';
import {
  BiLogoWhatsapp,
  BiNews,
  BiSolidFilePdf,
  BiStopCircle,
  BiTask,
} from 'react-icons/bi';
import resources from '../../images/resources.png';
import './styles.css';

class Sidebar extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      collapsed: true,
      openModal: false,
    };

    this.service = new Services();
  }

  doLogout = async () => {
    localStorage.setItem('user', 'null');
    localStorage.setItem('token', 'null');
    message.success('Logout realizado com sucesso');

    this.props.history.push('/login');
  };

  handleClickHelp = () => {
    this.setState({
      openModal: true,
    });
  };

  handleOk = e => {
    console.log(e);
    this.setState({
      openModal: false,
    });
  };

  handleCancel = () => {
    this.setState({
      openModal: false,
    });
  };

  componentDidMount() {
    try {
      (async () => {
        const result = await this.service.getAppVersion();

        if (
          result &&
          result.code === 200 &&
          result.object &&
          result.object.frontEnd &&
          result.object.backEnd
        ) {
          this.setState({ versao: result.object });
        }
      })();
    } catch (err) {
      console.warn('Erro ao obter versão', err);
    }
  }

  render() {
    const { collapsed, current } = this.state;
    const clientFilial =
      this.props.clientFilial && (this.props.clientFilial.filial || false);

    return (
      <Layout.Sider
        breakpoint="md"
        width={80}
        collapsedWidth="0"
        onBreakpoint={broken => {
          //console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          //console.log(collapsed, type);
        }}
      >
        <div className="sider">
          <div className="logo">
            {collapsed ? (
              <img src={simplifiedLogo} className="logo-icon" alt="App-Blue" />
            ) : (
              <img src={simplifiedLogo} className="logo-image" alt="App-Blue" />
            )}
          </div>
          {this.props.userData &&
          this.props.userData.object &&
          this.props.userData.object.namePerfil === 'Administrador' ? (
            <Menu mode="inline" defaultSelectedKeys={[current]}>
              <Menu.Item key="dashboard">
                <Link to={this.props.routes.home}>
                  <Icon type={this.props.dataSidebar.iconClient.home} />
                </Link>
              </Menu.Item>
              <Menu.Item key="administrators">
                <Link to={this.props.routes.user}>
                  <Icon type={this.props.dataSidebar.iconClient.user} />
                </Link>
              </Menu.Item>
              <Menu.Item key="company">
                <Link to={this.props.routes.company}>
                  <img src={theater} alt="Ícone de NPS" />
                </Link>
              </Menu.Item>
              <Menu.Item key="contracts">
                <Link to={this.props.routes.contracts}>
                  <Icon type={this.props.dataSidebar.iconClient.contracts} />
                </Link>
              </Menu.Item>
              <Menu.Item key="plans">
                <Link to={this.props.routes.plans}>
                  <Icon type={this.props.dataSidebar.iconClient.plans} />
                </Link>
              </Menu.Item>
              {this.props.dataSidebar.configuration ? (
                !clientFilial ? (
                  <Menu.Item key="configuration">
                    <Link to={this.props.routes.configuration}>
                      <Icon
                        type={this.props.dataSidebar.iconClient.configuration}
                      />
                    </Link>
                  </Menu.Item>
                ) : null
              ) : null}
              <Menu.Item key="logout" onClick={this.doLogout}>
                <Icon type={this.props.dataSidebar.iconClient.logout} />
              </Menu.Item>
            </Menu>
          ) : (
            <Menu mode="inline" defaultSelectedKeys={[current]}>
              <Menu.Item key="dashboard">
                <Link to={this.props.routes.home}>
                  <Icon type={this.props.dataSidebar.iconClient.home} />
                  <span>{this.props.dataSidebar.home}</span>
                </Link>
              </Menu.Item>
              {this.props.dataFilial.length > 0 ? (
                <Menu.Item key="administrators">
                  <Link to={this.props.routes.user}>
                    <img src={resources} alt="Indice blue " />
                    <span>{this.props.dataSidebar.user}</span>
                  </Link>
                </Menu.Item>
              ) : null}

              {this.props.dataFilial.length > 0 ? (
                <Menu.Item key="company">
                  <Link to={this.props.routes.company}>
                    <img src={theater} alt="Ícone de NPS" />
                    <span>{this.props.dataSidebar.company}</span>
                  </Link>
                </Menu.Item>
              ) : null}
              {/* {this.props.clientFilial && this.props.clientFilial.filial ? (
                <Menu.Item key="contracts">
                  
                    <Link to={this.props.routes.contracts}>
                      <Icon
                        type={this.props.dataSidebar.iconClient.contracts}
                      />
                    </Link>
                  
                </Menu.Item>
              ) : null} */}
              {this.props.dataFilial.length > 0 ? (
                <Menu.Item key="plans">
                  <Link to={this.props.routes.plans}>
                    <Icon type={this.props.dataSidebar.iconClient.plans} />
                    <span>{this.props.dataSidebar.plans}</span>
                  </Link>
                </Menu.Item>
              ) : null}
              {this.props.dataSidebar.configuration &&
              this.props.dataUserCompany ? (
                <Menu.Item key="configuration">
                  <Link to={this.props.routes.configuration}>
                    <Icon
                      type={this.props.dataSidebar.iconClient.configuration}
                    />
                    <span>{this.props.dataSidebar.configuration}</span>
                  </Link>
                </Menu.Item>
              ) : null}
              {/* <Menu.Item key="logout" onClick={this.doLogout}>
            
              <Icon type={this.props.dataSidebar.iconClient.logout} />
            
          </Menu.Item> */}

              <Menu.Item key="help" onClick={() => this.handleClickHelp()}>
                <Link className="helpButton">
                  <img src={helpIcon} alt="Ícone de ajuda" />
                  <span>Ajuda</span>
                </Link>
              </Menu.Item>
            </Menu>
          )}
        </div>
        {this.state.versao && this.state.versao.frontEnd ? (
          <div
            style={{
              color: 'white',
              opacity: 0.3,
              fontSize: '.8em',
              textAlign: 'center',
              bottom: 10,
              position: 'absolute',
              width: '100%',
            }}
          >
            v{this.state.versao.frontEnd}
          </div>
        ) : null}

        <Modal
          cancelButtonProps={''}
          title="Ajuda"
          visible={this.state.openModal}
          onCancel={this.handleCancel}
          footer={null}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              width: '100%',
            }}
          >
            <div
              style={{
                display: 'flex',
                gap: '4px',
                justifyContent: 'start',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <BiSolidFilePdf size={26} />
              <a
                target="blank"
                href="https://blueexperiencias.com.br/wp-content/uploads/2022/03/MANUAL-DA-BLUE-2022.pdf"
              >
                Manual da Blue
              </a>
            </div>

            <div
              style={{
                display: 'flex',
                gap: '8px',
                justifyContent: 'start',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <BiTask size={26} />
              <a
                target="blank"
                href="https://blueexperiencias.com.br/solicitarrelatorio"
              >
                Solicitar relatório
              </a>
            </div>

            <div
              style={{
                display: 'flex',
                gap: '4px',
                justifyContent: 'start',
                alignItems: 'center',
                width: '100%size={26}',
              }}
            >
              <BiLogoWhatsapp size={26} />
              <a target="blank" href="https://wa.me/556191905521">
                Falar com a Blue via WhatsApp
              </a>
            </div>

            <div
              style={{
                display: 'flex',
                gap: '4px',
                justifyContent: 'start',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <BiNews size={26} />
              <a
                target="blank"
                href="https://blueexperiencias.com.br/politicas-privacidade"
              >
                Termos de Uso e Políticas de Privacidade
              </a>
            </div>

            <div
              style={{
                display: 'flex',
                gap: '4px',
                justifyContent: 'start',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <BiStopCircle size={26} />
              <a
                target="blank"
                href="https://blueexperiencias.com.br/cancelamento"
              >
                Encerrar assinatura
              </a>
            </div>
          </div>
        </Modal>
      </Layout.Sider>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ setClientFilial }, dispatch);

const mapStateToProps = store => ({
  userData: store.userData.data,
  dataUserCompany: store.userData.dataUserCompany,
  dataFilial: store.userData.dataFilial,
  clientFilial: store.userData.clientFilial,
  companySelected: store.userData.companySelected,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Sidebar));
