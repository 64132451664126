import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as jwt_decode from 'jwt-decode';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import { Layout, Row, Col, message } from 'antd';
import { getUserData } from '../../redux/actions';
import Service from './Service';
import FormUser from '../../components/FormUser';

import './styles.css';
import ContentImage from '../../components/ContentImage';

const { Content } = Layout;

class Register extends Component {
  constructor(props, context) {
    super(props, context);
    const { data } = this.props;

    this.state = {
      active: false,
      activeFormUser: false,
      register: data.object.boolCompleteRegister,
    };

    this.service = new Service();
  }

  componentDidMount() {
    if (!this.service.isLogged()) {
      this.props.history.push('/login');
    } else {
      const token = this.props.userData.object.token
      const decodeToken = jwt_decode(token);
      const cadastro = this.props.location.state;
      this.setState({
        role: decodeToken.role,
        dataUser: decodeToken,
        cadastro,
      })
    }
    // this._getDataCharts();
  }

  onChildChanged(bool) {
    this.setState({ active: bool });
  }

  onChildChangedFormUser(bool) {
    this.setState({ activeFormUser: bool });
  }

  userUpdateRegister = async (dataUpdateUser) => {

    const token = this.props.data.object.token;
    const decodeToken = jwt_decode(token);

    const payload = {
      pessoa: {
        nome: dataUpdateUser.pessoa.nome,
        cpfcnpj: dataUpdateUser.pessoa.cpfcnpj,
        telefones: dataUpdateUser.pessoa.telefones,
      },
    };

    const result = await this.service.userUpdate(payload, decodeToken.IdUsuario);

    if (result && result.code === 200) {
      message.success('Login efetuado com sucesso');

      const inviteClient = this.props.invite

      if (inviteClient.length > 0) {
        this.props.history.push('/client');
      } else {
        this.props.history.push('/');
      }
    } else {
      message.error('Erro ao cadastrar');
    }
  };

  onChange = () => {};

  render() {
    console.log("userData", this.props.userData)
    return (
      <Layout style={{ height: '100vh' }}>
        <Content style={{ backgroundColor: '#fff', height: '100vh' }}>
          <Row className="content-row" type="flex" justify="center" align="middle">
            <Col className="content-left" xs={16} lg={12} style={{ backgroundColor: '#fff' }}>
              <div className="account-logo">
                <h1 className="link-blue">
                  <a style={{ color: '#000' }} href="http://blueexperiencias.com.br/" rel="noopener noreferrer" target="_blank">blueexperiencias.com.br</a>
                </h1>
              </div>
              {this.state.register === false ? (
                <div>
                  <div className="registerUser">
                    <h1 className="link-blue">Cadastro de Pessoa Fisíca</h1>
                  </div>
                  <FormUser
                    onChangeUpdate={dataUpdateUser => this.userUpdateRegister(dataUpdateUser)}
                    callbackParent={bool => this.onChildChanged(bool)}
                    dataUser={this.props.userData}
                  />
                </div>
              ) : null}

              {/* {cadastro !== undefined && cadastro === false && !active ? (
                <FormUser callbackParent={bool => this.onChildChanged(bool)} />
              ) // <CreatePassword callbackParent={bool => this.onChildChangedFormUser(bool)} />
              // <PersonalData callbackParent={bool => this.onChildChanged(bool)} />
              // <FormUser callbackParent={bool => this.onChildChanged(bool)} />
              // <FormUser />
                : null}
              {active ? (
                <CreateCompany callbackParent={bool => this.onChildChangedFormUser(bool)} />
              ) : null} */}
            </Col>
            <Col className="content-right contentImage" xs={8} lg={12} style={{ backgroundColor: '#00a1ed' }}>
              <ContentImage />
            </Col>
          </Row>
        </Content>
      </Layout>
    );
  }
}

Register.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators({ getUserData }, dispatch);

const mapStateToProps = store => ({
  data: store.userData.data,
  userData: store.userData.data,
  invite: store.userData.invite
});

export default connect( mapStateToProps, mapDispatchToProps )(withRouter(Register));
