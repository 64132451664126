import React, { Component } from 'react';
import { Form, Input, Icon, Button } from 'antd';
import * as jwt_decode from 'jwt-decode';
import InputMask from 'react-input-mask';
import Service from '../../services'
import './styles.css';

import { validaCPF, validaPhone } from '../../utils'

class FormUser extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      loading: false,
      confirmDirty: false,
      checked: true,
      segment: '',
      estado: '',
      cidade: '',
      cep: '',
      celular: '',
      comercial: '',
      endereco: '',
      segmentCompany: '',
    };
    this.service = new Service();
  }

  componentDidMount() {
    if (!this.service.isLogged()) {
      this.props.history.push('/login');
    } else {
      const token = this.props.dataUser.object.token
      const decodeToken = jwt_decode(token);

      this.setState({
        role: decodeToken.role,
        dataUser: decodeToken,
        nome: decodeToken.unique_name,
        email: decodeToken.email
      })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });

        let payload = {
          email: values.email,
          pessoa: {
            nome: values.name,
            cpfcnpj: values.cpfcnpj.replace(/\D/g, ''),
            email: values.email,
            //cidade: values.cidade,
            //estado: values.estado,
            //endereco: values.endereco,
            //cep: values.cep.replace(/\D/g, ''),
            telefones: [
              {
                ddd: values.celular.split(" ")[0].replace(/\D/g, ''),
                numero: values.celular.split(" ")[1].replace(/\D/g, ''),
                tipoTelefone: 1
              }
            ]
          }
        }

        if(values.comercial) {
          payload.pessoa.telefones.push({
            ddd: values.comercial.split(" ")[0].replace(/\D/g, ''),
            numero: values.comercial.split(" ")[1].replace(/\D/g, ''),
            tipoTelefone: 2
          })
        }

        this.props.onChangeUpdate(payload);
      }
    });
  };

  componentWillUnmount() {
    this.setState({ loading: false });
  }

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  onChangeChecked = (checked) => {
    this.setState({ checked });
  };

  getCEP = async (value) => {
    if(value.length === 8 ) {
      const result = await this.service.getCEP(value)
      if(result && !result.erro) {
        this.props.form.setFieldsValue({
          estado: result.uf || '',
          cidade: result.localidade || '',
          endereco: result.logradouro || ''
        });
        return true
      } else {
        return false
      }
    }
  }

  validateCEP = async (rule, value, callback) => {
    value = value.replace(/[^\d]+/g,'')
    if(value.length === 8) {
      const isValid = await this.getCEP(value)
      if(isValid) {
        callback()
      } else {
        callback('Forneça um CEP válido!')
      }
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { celular, comercial, cpfcnpj, loading } = this.state;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8, offset: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16, offset: 4 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 14,
          offset: 10,
        },
      },
    };

    return (
      <div className="">
        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
          <Form.Item>
            {getFieldDecorator('name', {
              initialValue: this.state.nome,
              rules: [{ required: true, message: 'Por favor, informe seu nome!', whitespace: true }],
            })(
              <Input
                placeholder={"Nome completo"}
                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                onChange={(e) => {
                  this.setState({ nome: e.target.value });
                }}
              />,

            )}
          </Form.Item>
          {/* <Form.Item>
            {getFieldDecorator('email', {
              initialValue: this.state.email,
              rules: [
                {
                  type: 'email',
                  message: 'Forneça um e-mail válido!',
                },
                {
                  required: true,
                  message: 'E-mail obrigatório!',
                },
              ],
            })(
              <Input
                placeholder="Email"
                type="email"
                prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
              />,
            )}
          </Form.Item> */}
          <Form.Item>
            {getFieldDecorator('cpfcnpj', {
              initialValue: cpfcnpj,
              rules: [
                {
                  required: true,
                  message: 'Forneça um CPF válido!',
                  whitespace: true,
                },
                {
                  validator: validaCPF,
                }
              ],
            })(
              <span className="ant-input-affix-wrapper">
                <span className="ant-input-prefix">
                  <Icon type="solution" style={{ color: 'rgba(0,0,0,.25)' }} />
                </span>
                <InputMask
                  className="ant-input"
                  mask="999.999.999-99"
                  placeholder="CPF"
                />
              </span>,
            )}
          </Form.Item>
          {/* <Form.Item>
            {getFieldDecorator('cep', {
              initialValue: cep,
              rules: [
                {
                  required: true,
                  message: 'Forneça um CEP válido!',
                  whitespace: true,
                },
                {
                  validator: this.validateCEP,
                }
              ],
            })(
              <span className="ant-input-affix-wrapper">
                <span className="ant-input-prefix">
                  <Icon type="pushpin" style={{ color: 'rgba(0,0,0,.25)' }} />
                </span>
                <InputMask
                  className="ant-input"
                  mask="99.999-999"
                  placeholder="CEP"
                />
              </span>,
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('estado', {
              initialValue: estado,
              rules: [{ required: true, message: 'Estado obrigatório!', whitespace: true }],
            })(
              <Input
                onChange={(e) => this.setState({ estado: e.target.value})}
                placeholder="Estado"
                prefix={<Icon type="bank" style={{ color: 'rgba(0,0,0,.25)' }} />}
              />,
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('cidade', {
              initialValue: cidade,
              rules: [{ required: true, message: 'Cidade obrigatória!', whitespace: true }],
            })(
              <Input
                onChange={(e) => this.setState({ cidade: e.target.value})}
                placeholder="Cidade"
                prefix={<Icon type="team" style={{ color: 'rgba(0,0,0,.25)' }} />}
              />,
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('endereco', {
              initialValue: endereco,
              rules: [{ required: true, message: 'Endereço obrigatório!', whitespace: true }],
            })(
              <Input
                placeholder="Endereço"
                prefix={<Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />}
              />,
            )}
          </Form.Item> */}
          <Form.Item>
            {getFieldDecorator('celular', {
              initialValue: celular,
              rules: [
                {
                  required: true,
                  message: 'Forneça um número do celular!',
                },
                {
                  validator: validaPhone,
                }
              ],
            })(
              <span className="ant-input-affix-wrapper">
                <span className="ant-input-prefix">
                  <Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />
                </span>
                <InputMask
                className="ant-input"
                mask="(99) 99999-9999"
                placeholder="Celular"
              />
              </span>,
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('comercial', {
              initialValue: comercial,
            })(
              <span className="ant-input-affix-wrapper">
                <span className="ant-input-prefix">
                  <Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />
                </span>
                <InputMask
                className="ant-input"
                mask="(99) 99999-9999"
                placeholder="Numero comercial"
              />
              </span>,
            )}
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" loading={loading} htmlType="submit">
              Próximo
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

// export default withRouter(FormUser);
export default Form.create()(FormUser);
