import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as jwt_decode from 'jwt-decode';
import { withRouter } from 'react-router-dom';
import { Layout, Card, Row, Col, DatePicker, Badge, Spin, Icon  } from 'antd';
import moment from 'moment';
// import ReactHighcharts from 'react-highcharts';
import Highcharts from 'highcharts';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUserData } from '../../redux/actions';

import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Breadcrumb from '../../components/Breadcrumb';

import Service from './Service';
import './styles.css';

const { Content } = Layout;

class IndexBlue extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      data: {},
      dataChart: {},
      loading: false,
      subRespostas: [],
      alturaGraficoRacional: 405,
      alturaGraficoEmocional: 405,
      idUnidade: 0,
      idGrupo: 0
    };
    this.service = new Service();
  }

  componentDidMount() {
    const { history } = this.props;
    if (!this.service.isLogged()) {
      history.push('/login');
    } else {
      const idUnidade = this.props.dataFilial.length > 0 ? this.props.dataFilial[0].id : 0;
      const idGrupo = this.props.companySelected.length > 0 ? this.props.companySelected[0].id : 0;
      const token = this.props.userData.object.token;
      const decodeToken = jwt_decode(token);
      this.setState({
        role: decodeToken.role,
        dataUser: decodeToken,
        idUnidade,
        idGrupo
      });
      this.handleDate();
    }
  }

  componentWillReceiveProps(props) {
    const idUnidade = props.dataFilial.length > 0 ? props.dataFilial[0].id : 0;
    const idGrupo = props.companySelected.length > 0 ? props.companySelected[0].id : 0;
    this.setState({
      idUnidade,
      idGrupo
    });
    if((idUnidade > 0 && idUnidade !== this.state.idUnidade) || (idGrupo > 0 && idGrupo !== this.state.idGrupo)) {
      const token = this.props.userData.object.token;
      const decodeToken = jwt_decode(token);
      this.setState({
        role: decodeToken.role,
        dataUser: decodeToken,
      });
      this.handleDate(idGrupo, idUnidade);
    }
  }

  handleDate = async (idGrupo, idUnidade) => {
    this.setState({ loading: true });
    let dateFirst = new Date();
    let date = new Date(new Date().setDate(dateFirst.getDate() - 30));

    const dateFormat = 'YYYY-MM-DD';

    let teste = moment(date).format(dateFormat);
    let teste2 = moment(new Date()).format(dateFormat);

    let result = await this.service.getDataIndiceBlue(
      teste,
      teste2,
      idUnidade > 0 ? idUnidade : this.props.dataFilial[0].id,
      idGrupo > 0 ? idGrupo : this.props.companySelected[0].id
    );
    this.setState({
      dataChart: result.object,
      dataInitial: teste,
      loading: false,
    });

    this.highChartsRenderRacionais();
    this.highChartsRenderEmocionais();
  };

  onChange = async (value, dateString) => {
    this.setState({ loading: true });
    let result = await this.service.getDataIndiceBlue(
      dateString[0],
      dateString[1],
      this.props.filialId,
      this.props.companySelected[0].id
    );

    this.setState({ dataChart: result.object, loading: false });

    this.highChartsRenderRacionais();
    this.highChartsRenderEmocionais();
    
  };

  onOk = value => {
    console.log('onOk: ', value);
  };

  highChartsRenderRacionais() {
    // Perguntas
    let categoriesRacionais =
      this.state.dataChart.racionais &&
      this.state.dataChart.racionais.map(item => {
        return item.pergunta.text;
      });

    let dataRacionais =
      this.state.dataChart.racionais &&
      this.state.dataChart.racionais.map(item => {
        return item.pergunta.value;
      });

    //Subperguntas
    let subPerguntasRacionais =
      this.state.dataChart.racionais &&
      this.state.dataChart.racionais.map(item => {
        return item;
      });

    let altura = 405;
    if(subPerguntasRacionais.length > 20) {
      altura = 705
    }
    this.setState({ perguntasRacionais: subPerguntasRacionais, alturaGraficoRacional: altura });

    Highcharts.chart({
      chart: {
        type: 'bar',
        renderTo: 'racionais',
      },
      title: {
        text: 'Racionais',
      },
      xAxis: {
        categories: categoriesRacionais || [],
      },
      yAxis: {
        allowDecimals: false,
        title: 'Totais',
        max: 5
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.y:.1f}</b>',
      },
      series: [
        {
          name: 'Média',
          showInLegend: false,
          color: '#00a1ed',
          data: dataRacionais || [],
        },
      ],
      credits: {
        enabled: false,
      },
    });
  }

  highChartsRenderEmocionais() {
    let categoriesEmocionais =
      this.state.dataChart.emocionais &&
      this.state.dataChart.emocionais.map(item => {
        return item.pergunta.text;
      });

    let dataEmocionais =
      this.state.dataChart.emocionais &&
      this.state.dataChart.emocionais.map(item => {
        return item.pergunta.value;
      });

    //console.log("dataEmocionais", dataEmocionais)

    let subPerguntasEmocionais =
      this.state.dataChart.emocionais &&
      this.state.dataChart.emocionais.map(item => {
        return item;
      });

    let altura = 405;
    if(subPerguntasEmocionais.length > 20) {
      altura = 705
    }

    this.setState({ perguntasEmocionas: subPerguntasEmocionais, alturaGraficoEmocional: altura });

    let _asks = [];

    subPerguntasEmocionais &&
      subPerguntasEmocionais.map((__item, __key) => {
        let _subanswer = {
          name: '',
          data: [],
          tooltip: {
            pointFormat: '{series.name}: <b>{point.y:.0f}%</b>',
          },
        };
        __item.subPergunta.map((___item, ___key) => {
          _subanswer.name = ___item.text;
          return _subanswer.data.push(___item.value);
        });
        return _asks.push(_subanswer);
      });

    Highcharts.chart({
      chart: {
        type: 'bar',
        renderTo: 'emocionais',
      },
      title: {
        text: 'Emocionais',
      },
      xAxis: {
        categories: categoriesEmocionais || [],
      },
      yAxis: {
        allowDecimals: false,
        title: 'Totais',
        max: 5
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.y:.1f}</b>',
      },
      series: [
        {
          name: 'Média',
          showInLegend: false,
          color: '#2aa1bb',
          data: dataEmocionais || [],
        },
      ],
      credits: {
        enabled: false,
      },
    });
  }

  render() {
    const { perguntasRacionais, perguntasEmocionas } = this.state;

    let dateInit = JSON.stringify(this.state.dataInitial);

    const homeClient = {
      home: 'Início',
      user: 'Índice Blue',
      company: 'NPS',
      companyClient: 'Empresa',
      contracts: 'Evoluções',
      plans: 'Avaliações',
      configuration: 'Opções',
      logout: 'Sair',
      iconClient: {
        home: 'home',
        user: 'area-chart',
        company: 'pie-chart',
        companyClient: 'bank',
        contracts: 'line-chart',
        plans: 'message',
        configuration: 'setting',
        logout: 'logout',
      },
    };

    const routesClient = {
      home: 'client',
      user: 'indexBlue',
      company: 'nps',
      companyClient: 'companyClient',
      contracts: 'evolution',
      plans: 'comments',
      configuration: 'settings',
    };

    const { RangePicker } = DatePicker;
    const dateFormat = 'YYYY-MM-DD';

    const total =
      perguntasRacionais != undefined && perguntasRacionais[0] != undefined
        ? perguntasRacionais[0].pergunta.quantidade
        : 0;

    return (
      <Layout>
        <Sidebar
          dataSidebar={homeClient}
          routes={routesClient}
          current="dashboard"
        />
        <Layout>
          <Header dataUser={this.state.dataUser} />
          <Content
            style={{
              margin: '24px 16px',
              padding: 10,
            }}
          >
            <Spin spinning={this.state.loading} size="large">
              <Row className="nopadding-col">
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                  xl={{ span: 24 }}
                >
                  <Breadcrumb pages={[{ title: 'Índice Blue' }]} />
                </Col>
              </Row>
              <Row
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-end',
                }}
              >
                {this.state.dataInitial !== undefined ? (
                  <RangePicker
                    defaultValue={[
                      moment(dateInit, dateFormat),
                      moment(new Date(), dateFormat),
                    ]}
                    format={dateFormat}
                    onChange={this.onChange}
                    onOk={this.onOk}
                  />
                ) : null}
              </Row>
              <Row style={{ flexDirection: 'row' }} gutter={16}>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 18 }}
                  style={{ marginBottom: 50, marginTop: 33 }}
                >
                  <Row gutter={16}>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      lg={{ span: 24 }}
                      md={{ span: 24 }}
                      style={{ marginBottom: 30 }}
                      className="home-charts"
                    >
                      <div style={{ height: this.state.alturaGraficoRacional }} id="racionais"></div>
                    </Col>
                  </Row>
                </Col>

                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 6 }}
                  style={{ marginBottom: 50, marginTop: 30 }}
                >
                  <Row gutter={16}>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 24 }}
                    >
                      <Card
                        title="Índice Blue"
                        className="tcenter"
                        style={{ textAlign: '-webkit-center', height: (this.state.alturaGraficoRacional / 2) }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#0a2851',
                            borderRadius: '50%',
                            height: 100,
                            width: 100,
                          }}
                        >
                          <h1
                            style={{
                              fontSize: 25,
                              fontWeight: 'bold',
                              color: '#FFF',
                            }}
                          >
                            {
                              (this.state.dataChart.racionais
                                && this.state.dataChart.emocionais
                                && this.state.dataChart.racionais.length == 0
                                && this.state.dataChart.emocionais.length == 0
                                ) ? ('--') :
                                (
                                  <>
                                    {this.state.dataChart.indiceBlue &&
                                    this.state.dataChart.indiceBlue.toFixed(2)}
                                  </>
                                )
                            }
                          </h1>
                          <p style={{ color: '#FFF', fontSize: 10 }}>
                          {
                              (this.state.dataChart.racionais
                                && this.state.dataChart.emocionais
                                && this.state.dataChart.racionais.length > 0
                                && this.state.dataChart.emocionais.length > 0
                                ) ? ('0 - 100') :
                                (
                                  <>
                                    
                                  </>
                                )
                            }
                          </p>
                        </div>
                      </Card>
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 24 }}
                    >
                      <Card
                        title="Zonas do Índice Blue"
                        className="tcenter"
                        style={{ height: (this.state.alturaGraficoRacional / 2) }}
                      >
                        <Row style={{ flexDirection: 'column' }}>
                          <Col span={24}>
                            <Badge color="red" text="Vermelha 0 a 25" />
                          </Col>
                          <Col span={24}>
                            <Badge color="yellow" text="Amarela 26 a 50" />
                          </Col>
                          <Col span={24}>
                            <Badge color="green" text="Verde 51 a 75" />
                          </Col>
                          <Col span={24}>
                            <Badge color="blue" text="Blue 76 a 100" />
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row style={{ flexDirection: 'row' }} gutter={16}>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                  style={{ marginBottom: 50, marginTop: 10 }}
                >
                  <Row gutter={16}>
                    {perguntasRacionais &&
                      perguntasRacionais.map(item => {
                        return (
                          <Col
                            xs={{ span: 24 }}
                            sm={{ span: 12 }}
                            md={{ span: 8 }}
                            lg={{ span: 8 }}
                            xl={{ span: 8 }}
                            key={item.pergunta.id}
                          >
                            <Card
                              title={
                                <div style={{
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  color: '#00a1ed',
                                  fontWeight: 'bold',
                                }}>
                                  <span>
                                    {item.pergunta.text}
                                  </span>
                                  <span>
                                    {item.pergunta.quantidade}
                                    <Icon type="message" style={{marginLeft: '6px'}} />
                                  </span>
                                </div>
                                
                              }
                              className="tcenter"
                              style={{
                                height: '250px',
                                marginBottom: '10px',
                                marginRight: '5px'
                              }}
                            >
                              {item.subPergunta.length !== 0
                                ? item.subPergunta.map(subPergunta => {
                                    return (
                                      <Col
                                        className="gutter-row"
                                        xs={{ span: 24 }}
                                        sm={{ span: 24 }}
                                        md={{ span: 24 }}
                                        lg={{ span: 24 }}
                                        xl={{ span: 24 }}
                                        key={subPergunta.id}
                                      >
                                        <div
                                          style={{
                                            display: 'flex',
                                          }}
                                        >
                                          <p
                                            style={{
                                              fontSize: 16,
                                            }}
                                          >
                                            {subPergunta.text}
                                          </p>
                                          <p
                                            style={{
                                              marginLeft: 'auto',
                                              order: 2,
                                              whiteSpace: 'nowrap',
                                            }}
                                          >
                                            {subPergunta.quantidade} <strong>({((subPergunta.quantidade / item.pergunta.quantidade) * 100).toFixed(2)}%)</strong>
                                          </p>
                                        </div>
                                      </Col>
                                    );
                                  })
                                : 'Não há informações a serem exibidas'}
                            </Card>
                          </Col>
                        );
                      })}

                    <Col
                      style={{ marginTop: 20, marginBottom: 20 }}
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      lg={{ span: 24 }}
                      md={{ span: 24 }}
                      className="home-charts"
                    >
                      <div style={{ height: this.state.alturaGraficoEmocional }} id="emocionais"></div>
                    </Col>
                    {perguntasEmocionas &&
                      perguntasEmocionas.map(item => {
                        return (
                          <Col
                            xs={{ span: 24 }}
                            sm={{ span: 12 }}
                            md={{ span: 8 }}
                            lg={{ span: 8 }}
                            xl={{ span: 8 }}
                            key={item.pergunta.id}
                          >
                            <Card
                              title={
                                <div style={{
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  color: '#00a1ed',
                                  fontWeight: 'bold',
                                }}>
                                  <span>
                                    {item.pergunta.text}
                                  </span>
                                  <span>
                                    {item.pergunta.quantidade}
                                    <Icon type="message" style={{marginLeft: '6px'}} />
                                  </span>
                                </div>
                              }
                              className="tcenter"
                              style={{
                                height: '250px',
                                marginBottom: '10px',
                                marginRight: '5px'
                              }}
                            >
                              {item.subPergunta.length !== 0
                                ? item.subPergunta.map(subPergunta => {
                                    return (
                                      <Col
                                        className="gutter-row"
                                        xs={{ span: 24 }}
                                        sm={{ span: 24 }}
                                        md={{ span: 24 }}
                                        lg={{ span: 24 }}
                                        xl={{ span: 24 }}
                                        key={subPergunta.id}
                                      >
                                        <div
                                          style={{
                                            display: 'flex',
                                          }}
                                        >
                                          <p
                                            style={{
                                              fontSize: 16,
                                            }}
                                          >
                                            {subPergunta.text}
                                          </p>
                                          <span
                                            style={{
                                              marginLeft: 'auto',
                                              order: 2,
                                              whiteSpace: 'nowrap',
                                            }}
                                          >
                                            {subPergunta.quantidade} <strong>({((subPergunta.quantidade / item.pergunta.quantidade) * 100).toFixed(2)}%)</strong>
                                          </span>
                                        </div>
                                      </Col>
                                    );
                                  })
                                : 'Não há informações a serem exibidas'}
                            </Card>
                          </Col>
                        );
                      })}
                  </Row>
                </Col>
              </Row>
            </Spin>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

IndexBlue.propTypes = {
  history: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getUserData }, dispatch);
const mapStateToProps = store => ({
  userData: store.userData.data,
  clientFilial: store.userData.clientFilial,
  filialId: store.userData.filialId,
  companySelected: store.userData.companySelected,
  dataFilial: store.userData.dataFilial
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(IndexBlue));
