import Services from '../../services';

export default class Service extends Services {
  getQuestionsFilial = async id => {
    //const tokenLogin = localStorage.getItem('token');
    try {
      const response = await fetch(
        `${this.endPoint}Questionario/GetPerguntasFilial/${id}`,
        {
          method: 'GET',
          headers: {
            //Authorization: `Bearer ${tokenLogin}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const json = await response.json();

      return json;
    } catch (err) {
      console.log('Error problem', err);
    }
  };

  listFilial = async tokenValue => {
    const tokenLogin = localStorage.getItem('token');
    try {
      const response = await fetch(`${this.endPoint}Filial`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'text/plain',
        },
      });

      const json = await response.json();

      return json;
    } catch (err) {
      console.log('Error problem', err);
    }
  };

  postQuestionsAnswers = async body => {
    //const tokenLogin = localStorage.getItem('token');
    try {
      const response = await fetch(
        `${this.endPoint}Questionario/SalvarRespostas`,
        {
          method: 'POST',
          headers: {
            //Authorization: `Bearer ${tokenLogin}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        }
      );

      const json = await response.json();

      return json;
    } catch (err) {
      console.log('Error problem', err);
    }
  };

  getIdentificacaoObrigatoria = async id => {
    const tokenLogin = localStorage.getItem('token');
    try {
      const response = await fetch(
        `${this.endPoint}Filial/identificacao-obrigatoria/${id}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${tokenLogin}`,
            'Content-Type': 'text/plain',
          },
        }
      );

      const json = await response.json();

      return json;
    } catch (err) {
      console.log('Error problem', err);
    }
  };

  listarRedeSocialPorFilial = async filial => {
    try {
      const response = await fetch(
        `${this.endPoint}RedeSocial/listar-por-filial/${filial}`,
        {
          method: 'GET',
          headers: {
            //Authorization: `Bearer ${tokenLogin}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const json = await response.json();

      return json;
    } catch (err) {
      console.log('Error problem', err);
    }
  };

  getUnidadeSemNome = async id => {
    const tokenLogin = localStorage.getItem('token');
    try {
      const response = await fetch(
        `${this.endPoint}Filial/unidade-sem-nome/${id}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${tokenLogin}`,
            'Content-Type': 'text/plain',
          },
        }
      );

      const json = await response.json();

      return json;
    } catch (err) {
      console.log('Error problem', err);
      //this.doLogout()
    }
  };
}
