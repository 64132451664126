import Services from '../../services';

export default class Service extends Services {
  getDataIndiceBlue = async (dataInicial, dataFinal, idFilial, idEmpresa) => {
    const tokenLogin = localStorage.getItem('token');
    try {
      let url = `${this.endPoint}Graficos/dadoGraficoIndiceBlue/${dataInicial}/${dataFinal}/${idFilial}`;
      if(idEmpresa > 0)
      {
         url += `?idEmpresa=${idEmpresa}`;
      }
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'application/json',
        },
      });

      const json = await response.json();

      return json;
    } catch (err) {
      console.log('Error problem', err);
      this.doLogout()
    }
  };
}
