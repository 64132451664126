import React, { Component } from 'react';
import { Layout, Checkbox, Row, Col } from 'antd';

const options = [
  { label: 'Desejo receber notificações', value: '1' },
];

const { Content } = Layout;

export default class Alerts extends Component {
  constructor(props) {
    super(props);
    console.log("alertStatus", this.props)

    this.state = {
      checked: this.props.alertStatus,
      disabled: false,
    }
  }

  onChange = (checkedValues) => {
    console.log('checked = ', checkedValues.target.checked);
    this.toggleChecked()
  }

  toggleChecked = () => {
    this.setState({ checked: !this.state.checked });
    console.log("state", this.state.checked)
    this.props.setAlertStatus(this.state.checked);
  };

  toggleDisable = () => {
    this.setState({ disabled: !this.state.disabled });
  };

  render() {
    return (
      <Layout>
        <Content
          style={{
            padding: 24,
            margin: 0,

          }}
        >
          <div>
            <Row>
              {
                options.map(item => {
                  return (
                    <Row key={item.value}>
                      <Col span={12}>
                        <Checkbox
                          checked={this.state.checked}
                          disabled={this.state.disabled}
                          onChange={this.onChange}
                        >
                          {item.label}
                        </Checkbox>
                      </Col>
                    </Row>
                  )
                }
              )}
            </Row>
            <Row>
              <h1>Os alertas serão enviados para email cadastrado.</h1>
            </Row>
          </div>
        </Content>
      </Layout>
    )
  }
}
