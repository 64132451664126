import Services from '../../services';

export default class Service extends Services {
  lisPlans = async () => {
    const tokenLogin = localStorage.getItem('token');

    try {
      const response = await fetch(`${this.endPoint}Plano/ativos`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'text/plain',
        },
      });

      const json = await response.json();

      return json;
    } catch (err) {
      console.log('Error problem', err);
      this.doLogout()
    }
  };
}
