import React from 'react';
import {
  Form,
  Modal,
  Input,
  Radio,
  Tooltip,
  Icon,
  DatePicker,
  InputNumber
} from 'antd'
import moment from 'moment';

export default Form.create({ name: 'form_beneficio_modal' })(

  class extends React.Component {
    render() {
      const { visible, onCancel, onSave, form, fields } = this.props;
      const { getFieldDecorator } = form;

      const doSave = () => {
        form.validateFields((err, values) => {
          if (err) {
            return;
          }
          form.resetFields();
          onSave(values);
        });
      }

      const doCancel = () => {
        form.resetFields();
        onCancel();
      }
      return (
        <Modal
          visible={visible}
          title={fields.idBeneficio > 0 ? "Alterar dados do Prêmio" : "Criar novo Prêmio"}
          okText="Salvar"
          cancelText="Cancelar"
          onCancel={doCancel}
          onOk={doSave}
        >
          <Form layout="vertical">
            <Form.Item style={{ display: 'none' }}>
              {getFieldDecorator('idBeneficio', { initialValue: fields.idBeneficio, })(<Input type="hidden" />)}
            </Form.Item>
            <Form.Item style={{ display: 'none' }}>
              {getFieldDecorator('idEmpresa', { initialValue: fields.idEmpresa, })(<Input type="hidden" />)}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('ativo', {
                rules: [{ required: true, message: 'Por favor informe se o prêmio está ou não ativo no momento' }],
                initialValue: fields.ativo,
              })(
                <Radio.Group>
                  <Radio value={true}>Ativa</Radio>
                  <Radio value={false}>Inativa</Radio>
                  <span>
                    <Tooltip placement="topLeft" title="Cupons (vouchers) só serão gerados para prêmios ativos!">
                      <Icon
                        style={{ marginLeft: 10, fontSize: 14 }}
                        type="question-circle"
                      />
                    </Tooltip></span>
                </Radio.Group>,
              )}
            </Form.Item>
            <Form.Item label={<span>
              Nome do prêmio
                <Tooltip placement="topLeft" title="Este também é o que será enviado no e-mail junto ao cupom (voucher) ao usuário.">
                <Icon
                  style={{ marginLeft: 10, fontSize: 14 }}
                  type="question-circle"
                />
              </Tooltip></span>}>
              {getFieldDecorator('nome', {
                rules: [{ required: true, message: 'Por favor informe o nome do prêmio!' }],
                initialValue: fields.nome
              })(<Input />)}
            </Form.Item>
            <Form.Item label={<span>
              Data Inicial
                <Tooltip placement="topLeft" title="O prêmio só estará disponível a partir das zero horas desta data">
                <Icon
                  style={{ marginLeft: 10, fontSize: 14 }}
                  type="question-circle"
                />
              </Tooltip></span>}>
              {getFieldDecorator('dataInicial', {
                rules: [{ required: true, message: 'Por favor informe a data inicial do prêmio!' }],
                initialValue: fields.dataInicial ? moment(fields.dataInicial) : null
              })(<DatePicker placeholder="Selecione a data" format="DD/MM/YYYY" />)}
            </Form.Item>
            <Form.Item label={<span>
              Data Final
                <Tooltip placement="topLeft" title="O prêmio só estará disponível até às 23:59 horas desta data">
                <Icon
                  style={{ marginLeft: 10, fontSize: 14 }}
                  type="question-circle"
                />
              </Tooltip></span>}>
              {getFieldDecorator('dataFinal', {
                rules: [{ required: true, message: 'Por favor informe a data final do prêmio!' }],
                initialValue: fields.dataFinal ? moment(fields.dataFinal) : null
              })(<DatePicker placeholder="Selecione a data" format="DD/MM/YYYY" />)}
            </Form.Item>
            <Form.Item label={<span>
              Limite de Cupons
                <Tooltip placement="topLeft" title="Número máximo de cupons disponíveis no prêmio. Deixe em branco para cupons ILIMITADOS.">
                <Icon
                  style={{ marginLeft: 10, fontSize: 14 }}
                  type="question-circle"
                />
              </Tooltip></span>}>
              {getFieldDecorator('maximoCupons', {
                initialValue: fields.maximoCupons
              })(<InputNumber min={1} />)}
            </Form.Item>
            <Form.Item label={<span>
              Validade do cupom (em dias)
                <Tooltip placement="topLeft" title="Período que o cupom é válido a partir de sua geração (em dias).">
                <Icon
                  style={{ marginLeft: 10, fontSize: 14 }}
                  type="question-circle"
                />
              </Tooltip></span>}>
              {getFieldDecorator('validadeCupomDias', {
                initialValue: fields.validadeCupomDias
              })(<InputNumber min={1} />)}
            </Form.Item>
            <Form.Item label={<span>
              Aguardar antes de utilizar (em horas)
                <Tooltip placement="topLeft" title="Tempo que o beneficiado deverá aguardar antes de utilizar seu cupom (em horas).">
                <Icon
                  style={{ marginLeft: 10, fontSize: 14 }}
                  type="question-circle"
                />
              </Tooltip></span>}>
              {getFieldDecorator('tempoAguardarUtilizacaoHoras', {
                initialValue: fields.tempoAguardarUtilizacaoHoras
              })(<InputNumber min={0} />)}
            </Form.Item>
            <Form.Item label={<span>
              Limite para confirmar (em horas)
                <Tooltip placement="topLeft" title="Tempo máximo para aguardar a validação do cupom (em horas). Após este período sem confirmação o cupom se torna inválido.">
                <Icon
                  style={{ marginLeft: 10, fontSize: 14 }}
                  type="question-circle"
                />
              </Tooltip></span>}>
              {getFieldDecorator('tempoMaximoConfirmacaoHoras', {
                initialValue: fields.tempoMaximoConfirmacaoHoras
              })(<InputNumber min={1} />)}
            </Form.Item>
            <Form.Item label={<span>
              Texto Inicial
                <Tooltip placement="topLeft" title="Este é o texto inicial sobre a premiação, exibido na primeira tela de avaliação.">
                <Icon
                  style={{ marginLeft: 10, fontSize: 14 }}
                  type="question-circle"
                />
              </Tooltip></span>}>
              {getFieldDecorator('textoInicial', {
                rules: [{ required: true, message: 'Por favor informe o texto inicial' }],
                initialValue: fields.textoInicial
              })(<Input.TextArea rows={2} autosize={{ maxRows: 3 }} placeholder="Use tags HTML para personalizar o texto" />)}
            </Form.Item>
            <Form.Item label={<span>
              Texto Final
                <Tooltip placement="topLeft" title="Este é o texto exibido na última tela, após a pessoa ter feito a avaliação e ter preenchido os dados obrigatórios para gerar o voucher.">
                <Icon
                  style={{ marginLeft: 10, fontSize: 14 }}
                  type="question-circle"
                />
              </Tooltip></span>}>
              {getFieldDecorator('textoFinal', {
                rules: [{ required: true, message: 'Por favor informe o texto final' }],
                initialValue: fields.textoFinal
              })(<Input.TextArea rows={2} autosize={{ maxRows: 3 }} placeholder="Use tags HTML para personalizar o texto" />)}
            </Form.Item>
            <Form.Item label={<span>
              Limite para ganhar outro cupom (em dias)
                <Tooltip placement="topLeft" title="Tempo mínimo em dias para que o cliente possa ganhar outro cupom utilizando o mesmo e-mail">
                <Icon
                  style={{ marginLeft: 10, fontSize: 14 }}
                  type="question-circle"
                />
              </Tooltip></span>}>
              {getFieldDecorator('tempoProximoPremioMesmoEmailDias', {
                initialValue: fields.tempoProximoPremioMesmoEmailDias
              })(<InputNumber min={1} />)}
            </Form.Item>

          </Form>
        </Modal>
      );
    }
  },
);
