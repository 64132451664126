import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Input, Icon, message } from 'antd';

import logo from '../../images/logo_blue.png';
import './styles.css';

import Service from './Service';

class Forgot extends Component {
  static defaultProps = {
    router: PropTypes.object,
    loading: PropTypes.bool,
    email: PropTypes.string,
    history: PropTypes.string,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      username: '',
      loading: false,
    };

    this.service = new Service();
  }

  componentDidMount() {
    // if (this.service.isLogged()) {
    //   this.props.history.push('/');
    // }
    // this.doLogin();
  }

  doForgot = async () => {
    this.setState({ loading: true });
    const { username } = this.state;

    const payload = {
      username,
    };

    const result = await this.service.userForgot(payload);

    if (result) {
      message.success('Confira seu email');

      // this.service.setToken(result.object.token);
      // this.service.setLoggedUser(result.user);

      this.props.history.push('/login');
    } else {
      message.error('Usuário e/ou senha incorreto(s)');
      this.setState({ loading: false });
    }
  };

  render() {
    const { username, loading } = this.state;
    return (
      <div className="login-page">
        <div className="wrapper">
          <div className="container">
            <img
              src={logo}
              style={{ height: 110, width: 110 }}
              className="logo-login"
              alt="Blue"
            />
            <form className="form">
              <Input
                placeholder="Insira o email cadastrado"
                value={username}
                onChange={e => {
                  this.setState({
                    username: e.target.value,
                  });
                }}
                prefix={
                  <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
              />

              <Button type="primary" loading={loading} onClick={this.doForgot}>
                Enviar
              </Button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Forgot);
