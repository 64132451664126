import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as jwt_decode from 'jwt-decode';
import { withRouter } from 'react-router-dom';
import {
  Layout,
  Row,
  Col,
  Card,
  Collapse,
  Icon,
  Spin,
  Tabs,
  message,
  Button,
  Table,
  Divider,
  Popconfirm,
  Tooltip,
  Alert,
} from 'antd';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getDataUserCompany,
  getModalTrue,
  getIdFilial,
} from '../../redux/actions';
import moment from 'moment';

import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Breadcrumb from '../../components/Breadcrumb';
import ModalCreateFilial from '../../components/ModalCreateFilial';
import ModalInvitation from '../../components/ModalInvitation';
import ModalUpdateCompany from '../../components/ModalUpdateCompany';
import ModalUpdateFilial from '../../components/ModalUpdateFilial';
import QuestionsClient from '../../components/QuestionsClient';
import Alerts from '../../components/Alerts';
import ModalBeneficio from '../../components/ModalBeneficio';
import ModalUtilizarCupom from '../../components/ModalUtilizarCupom';
//import ModalCreateCompany from '../../components/ModalCreateCompany';

import Service from './Service';
import './styles.css';
import RedeSocial from '../../components/RedeSocial';
import ModalLogomarca from '../../components/ModalLogomarca';
import ModalTemplateEmail from '../../components/ModalTemplateEmail';
import { obterTemplate } from '../../components/ModalTemplateEmail/services';

const { Content } = Layout;
const Panel = Collapse.Panel;
const { TabPane } = Tabs;

class SettingsBlue extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      loading: false,
      visible: false,
      visibleFilial: false,
      visibleTeste: false,
      visibleUpdateFilial: false,
      data: {},
      dataQuestions: {},
      dataQuestionsEmpresa: {},
      companyListUser: [],
      showLink: false,
      showRankCupom: true,
      modalBeneficio: {
        visible: false,
        data: {},
      },
      modalUtilizarCupom: {
        visible: false,
        data: {},
      },
      modalLogomarca: {
        visible: false,
        empresaId: 0,
        filialId: 0,
        nomeEmpresa: '',
        nomeFilial: '',
      },
      modalTemplateEmail: {
        visible: false,
        empresaId: 0,
        nomeEmpresa: '',
      },
      updateCompanyId: 0,
      updateFilialId: 0,
    };

    this.service = new Service();
  }

  abrirModalBeneficio(beneficio) {
    this.setState({
      visible: false,
      visibleUpdateFilial: false,
      visibleFilial: false,
      visibleTeste: false,
      modalBeneficio: {
        visible: true,
        data: beneficio,
      },
    });
  }

  fecharModalBeneficio() {
    const self = this;

    return () =>
      self.setState({
        modalBeneficio: {
          visible: false,
          data: {},
        },
      });
  }

  salvarBeneficio() {
    const self = this;

    return formData => {
      this.service
        .saveBeneficio(formData)
        .then(function(result) {
          if (result && result.object && result.object.sucesso) {
            message.success('Dados salvos com sucesso!', () => {
              let currentState = self.state;
              let resultBeneficio = result.object.beneficio || {};

              if (currentState && Array.isArray(currentState.data)) {
                currentState.data.forEach(e => {
                  const idEmpresa = (e || {}).id;
                  if (idEmpresa == resultBeneficio.idEmpresa) {
                    e.beneficio = resultBeneficio;
                  }
                });

                currentState.modalBeneficio = {
                  visible: false,
                  data: {},
                };
                self.setState(currentState);
              }
            });
            return;
          }

          if (result && result.mensage) {
            message.error(result.mensage);
          } else {
            message.error('Erro inesperado. Tente novamente!');
          }
        })
        .catch(function() {
          console.log('err:', arguments);
          message.error('Houve um erro inesperado. Tente novamente!');
        });
    };
  }

  abrirModalUtilizarCupom(filial) {
    this.setState({
      modalUtilizarCupom: {
        visible: true,
        data: { filial },
        cupomSelecionado: null,
      },
    });
  }

  fecharModalUtilizarCupom() {
    const self = this;

    return () =>
      self.setState({
        modalUtilizarCupom: {
          visible: false,
          data: {},
        },
      });
  }

  pesquisarCupom() {
    const self = this;

    return (idFilial, numero, done) => {
      this.service
        .pesquisarCupom(idFilial, numero)
        .then(function(result) {
          if (result && result.object) {
            self.setState({
              modalUtilizarCupom: {
                visible: self.state.modalUtilizarCupom.visible,
                data: self.state.modalUtilizarCupom.data,
                cupomSelecionado: result.object,
              },
            });
            done();
            return;
          }

          if (result && result.mensage) {
            message.error(result.mensage);
          } else {
            message.error('Erro inesperado. Tente novamente!');
          }
          done();
        })
        .catch(function() {
          console.log('err:', arguments);
          message.error('Houve um erro inesperado. Tente novamente!');
          done();
        });
    };
  }

  utilizarCupom() {
    const self = this;

    return (idFilial, numero, done) => {
      this.service
        .utilizarCupom(idFilial, numero)
        .then(function(result) {
          if (result && result.object) {
            message.success(
              `O cupom "${numero}" foi utilizado com sucesso!`,
              () => {
                self.setState({
                  modalUtilizarCupom: {
                    visible: false,
                    data: {},
                  },
                });
                done();
              }
            );
            return;
          }

          if (result && result.mensage) {
            message.error(result.mensage);
          } else {
            message.error('Erro inesperado. Tente novamente!');
          }
          done();
        })
        .catch(function() {
          console.log('err:', arguments);
          message.error('Houve um erro inesperado. Tente novamente!');
          done();
        });
    };
  }

  componentDidMount() {
    if (!this.service.isLogged()) {
      this.props.history.push('/login');
    } else {
      const token = this.props.userData.object.token;
      const decodeToken = jwt_decode(token);
      this.setState({
        role: decodeToken.role,
        dataUser: decodeToken,
        visible: false,
      });

      this.listCompany();
      this.listUserCompany();
      this.listCompanyFilial();
      this.listSegment();
      this.getListQuestions();
      this.getAlertStatus();
      this.getCupomPersonalizado();
    }
  }

  componentWillReceiveProps(props) {
    const idUnidade = props.dataFilial.length > 0 ? props.dataFilial[0].id : 0;
    const idGrupo =
      props.companySelected.length > 0 ? props.companySelected[0].id : 0;
    this.setState({
      idUnidade,
      idGrupo,
    });
    if (
      (idUnidade > 0 && idUnidade !== this.state.idUnidade) ||
      (idGrupo > 0 && idGrupo !== this.state.idGrupo)
    ) {
      const token = this.props.userData.object.token;
      const decodeToken = jwt_decode(token);
      this.setState({
        role: decodeToken.role,
        dataUser: decodeToken,
        visible: false,
      });

      this.listCompany();
      this.listUserCompany();
      this.listCompanyFilial();
      this.listSegment();
      this.getListQuestions(idGrupo);
      this.getAlertStatus();
      this.getCupomPersonalizado(idGrupo);
    }
  }

  componentWillUnmount() {
    this.setState({
      visible: false,
      visibleFilial: false,
      visibleTeste: false,
      visibleUpdateFilial: false,
      dataQuestionsEmpresa: [],
    });
  }

  async listCompanyFilial() {
    this.setState({ loading: true });
    const result = await this.service.listCompanyFilial();

    if (result === undefined) {
      await this.service.doLogout();
      this.props.history.push('/login');
    } else {
      this.props.dataUserCompany.map(company => {
        return result.object.filter(filial => {
          return filial.id === company.id;
        });
      });

      this.setState({ loading: false, dataFilial: result.object });
    }
  }

  listSegment = async () => {
    const result = await this.service.listSegment();

    if (result === undefined) {
      await this.service.doLogout();
      this.props.history.push('/login');
    } else {
      this.setState({ segment: result });
    }
  };

  async listCompany() {
    this.setState({ loading: true });
    const result = await this.service.listCompany();

    this.props.getDataUserCompany(result.object);

    if (result === undefined) {
      await this.service.doLogout();
      this.props.history.push('/login');
    } else {
      this.setState({ loading: false, data: result.object });
      this.props.getDataUserCompany(result.object);
    }
    this.setState({ loading: false });
  }
  callback(key) {
    console.log(key);
  }

  async listUserCompany() {
    const result = await this.service.listUserCompany();
    this.setState({ companyListUser: result.object });
  }

  onChange = () => {
    console.log('onChange');
  };

  onCreateFilial = async payload => {
    const response = await this.service.createCompanyFilial(payload);
    if (response && response.code === 200) {
      this.setState({
        visible: false,
        visibleFilial: false,
        visibleTeste: false,
        visibleUpdateFilial: false,
      });
      this.listCompanyFilial();
      this.listCompany();
      this.props.getModalTrue({ visible: false });
      message.success('Unidade cadastrada com sucesso!');
      return true;
    } else {
      message.error('Não foi possível cadastrar a unidade!');
      return false;
    }
  };

  async updateFilial(payload) {
    const response = await this.service.updateFilial(payload);
    if (response && response.code === 200) {
      this.setState({
        visible: false,
        visibleFilial: false,
        visibleTeste: false,
        visibleUpdateFilial: false,
      });
      this.listCompanyFilial();
      this.listCompany();
      this.props.getModalTrue({ visible: false });
      return true;
    } else {
      message.error('Não foi possível atualizar a filial!');
      return false;
    }
  }

  generateQRCodeUrl(url) {
    const baseUrl = 'https://api.qrserver.com/v1/create-qr-code/';

    const chartSize = '180x180';

    const encodedUrl = url;

    const qrCodeUrl = `${baseUrl}?size=${chartSize}&data=${encodedUrl}`;

    return qrCodeUrl;
  }

  createInvitation = async data => {
    const response = await this.service.createInvitation(data);

    if (response && response.object === true) {
      this.setState({
        visible: false,
        visibleFilial: false,
        visibleTeste: false,
        visibleUpdateFilial: false,
      });
      this.success();
      this.props.getModalTrue({ visible: false });
      return true;
    } else {
      this.error();
      return false;
    }
  };

  getListQuestions = async idGrupo => {
    const result = await this.service.getListQuestions();
    if (this.props.companySelected && this.props.companySelected.length > 0) {
      let idEmpresa = idGrupo > 0 ? idGrupo : this.props.companySelected[0].id;
      const resultFilial = await this.service.getListQuestionsEmpresa(
        idEmpresa
      );
      this.setState({
        dataQuestions: result,
        dataQuestionsEmpresa: resultFilial,
      });
    } else {
      this.setState({ dataQuestions: result, dataQuestionsEmpresa: [] });
    }
  };

  getCupomPersonalizado = async idGrupo => {
    if (this.props.companySelected && this.props.companySelected.length > 0) {
      let idEmpresa = idGrupo > 0 ? idGrupo : this.props.companySelected[0].id;
      const result = await obterTemplate(idEmpresa);
      if (result.id > 0 && result.tipoRegra === 1 && result.status) {
        this.setState({
          showRankCupom: false,
        });
      } else {
        this.setState({
          showRankCupom: true,
        });
      }
    }
  };

  error = () => {
    message.error('Convite não enviado, entre em contato com a blue');
  };

  success = () => {
    message.success('Convite enviado com sucesso!');
  };

  // save state modal true in redux
  openModal(item) {
    this.setState({
      idPlan: item.id,
      plan: item,
      visible: false,
      visibleTeste: false,
      visibleUpdateFilial: false,
    });

    const payloadModal = {
      visible: true,
      data: item,
    };
    this.props.getModalTrue(payloadModal);
  }

  saveQuestions = async data => {
    this.setState({ loading: true });

    const arrData = [data];

    const teste = arrData[0].idPergunta.map((item, index) => {
      return [{ idPergunta: item }];
    });
    teste.unshift({ idEmpresa: data.idEmpresa });

    const payload = {
      idEmpresa: data.idEmpresa,
      idsPerguntas: data.idPergunta,
    };

    const result = await this.service.createListQuestions(payload);

    if (result.object !== false) {
      this.successQuestions();
      this.setState({
        loading: false,
      });
    } else {
      this.errorQuestions();
      this.setState({
        loading: false,
      });
    }
  };

  updateCompany = async data => {
    this.setState({ loading: true, updateCompanyId: 0 });
    if (data.idEmpresa) {
      await this.service.updateFilial(data);
      this.setState({ visible: false, loading: false });
      this.listCompanyFilial();
    } else {
      await this.service.updateCompany(data);
      this.setState({ visible: false, loading: false, updateCompanyId: 0 });
      this.listCompany();
      this.listCompanyFilial();
    }

    this.setState({ visible: false, loading: false, updateCompanyId: 0 });
  };

  getAlertStatus = async () => {
    const result = await this.service.getAlertStatus();
    this.setState({ alertStatus: result.object });
  };

  setAlertStatus = async status => {
    await this.service.setAlertStatus(status);
  };

  successQuestions = () => {
    message.success('Formulário criado com sucesso!');
  };

  errorQuestions = () => {
    message.error('Error ao criar formulário');
  };

  deleteUser = async (dataIndex, record) => {
    if (record.idVinculoEmpresa !== null) {
      const payloadEmpresa = {
        idVinculoEmpresa: record.idVinculoEmpresa,
        idVinculoFilial: 0,
      };
      await this.service.deleteUser(payloadEmpresa);
    } else {
      const payloadFilial = {
        idVinculoEmpresa: 0,
        idVinculoFilial: record.idVinculoFilial,
      };
      await this.service.deleteUser(payloadFilial);
    }
    this.listUserCompany();
  };

  callback = key => {
    if (key.length === 2) {
      let idFilial = parseInt(key[1]);
      this.props.getIdFilial(idFilial);
    }
  };

  abrirModalLogomarca = (empresaId, filialId, nomeEmpresa, nomeFilial) => {
    this.setState({
      visible: false,
      visibleUpdateFilial: false,
      visibleFilial: false,
      visibleTeste: false,
      modalLogomarca: {
        visible: true,
        empresaId: empresaId,
        filialId: filialId,
        nomeEmpresa: nomeEmpresa,
        nomeFilial: nomeFilial,
      },
    });
  };

  abrirModalTemplate = (empresaId, nomeEmpresa) => {
    this.setState({
      visible: false,
      visibleUpdateFilial: false,
      visibleFilial: false,
      visibleTeste: false,
      modalTemplateEmail: {
        visible: true,
        empresaId: empresaId,
        nomeEmpresa: nomeEmpresa,
      },
    });
  };

  render() {
    const { dataFilial, companyListUser } = this.state;

    const homeClient = {
      home: 'Início',
      user: 'Índice Blue',
      company: 'NPS',
      companyClient: 'Empresa',
      contracts: 'Evoluções',
      plans: 'Avaliações',
      configuration: 'Opções',
      logout: 'Sair',
      iconClient: {
        home: 'home',
        user: 'area-chart',
        company: 'pie-chart',
        companyClient: 'bank',
        contracts: 'line-chart',
        plans: 'message',
        configuration: 'setting',
        logout: 'logout',
      },
    };

    const routesClient = {
      home: 'client',
      user: 'indexBlue',
      company: 'nps',
      companyClient: 'companyClient',
      contracts: 'evolution',
      plans: 'comments',
      configuration: 'settings',
    };

    const columns = [
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Empresa',
        dataIndex: 'nomeEmpresa',
        key: 'nomeEmpresa',
      },
      {
        title: 'Filial',
        dataIndex: 'nomeFilial',
        key: 'nomeFilial',
      },
      {
        title: 'Ações',
        dataIndex: 'id',
        key: 'id',
        className: 'col-action',
        render: (dataIndex, record) => (
          <span>
            <Divider type="vertical" />
            <Popconfirm
              placement="topRight"
              title={`Deseja excluir o usuário: ${record.email}`}
              onConfirm={() => this.deleteUser(dataIndex, record)}
              okText="Sim"
              cancelText="Não"
            >
              <Icon className="table-actions" type="delete" title="Deletar" />
            </Popconfirm>
          </span>
        ),
      },
    ];

    const filialText = <span>Criar Unidade</span>;
    const editText = <span>Editar</span>;

    return (
      <Layout>
        <Sidebar
          dataSidebar={homeClient}
          routes={routesClient}
          current="dashboard"
        />
        <Layout>
          <Header dataUser={this.state.dataUser} />
          <Content
            style={{
              margin: '24px 16px',
              padding: 24,
              background: '#fff',
            }}
          >
            <Spin spinning={this.state.loading} size="large">
              <Row className="nopadding-col">
                <Col md={{ span: 10 }} xs={{ span: 24 }}>
                  <Breadcrumb pages={[{ title: 'Opções' }]} />
                </Col>
                <Col md={{ span: 6 }} xs={{ span: 24 }}>
                  <div className="new-item">
                    {Array.isArray(this.props.dataFilial) &&
                    this.props.dataFilial.length ? (
                      <Button
                        className="btn-add"
                        icon="tag"
                        onClick={() =>
                          this.abrirModalUtilizarCupom(this.props.dataFilial[0])
                        }
                      >
                        Utilizar Cupom
                      </Button>
                    ) : (
                      <Tooltip
                        placement="bottomLeft"
                        title="Para utilizar os cupons, selecione uma filial primeiro."
                      >
                        <Button disabled className="btn-add" icon="tag">
                          Utilizar Cupom
                        </Button>
                      </Tooltip>
                    )}
                  </div>
                </Col>
                <Col md={{ span: 8 }} xs={{ span: 24 }}>
                  <div className="new-item">
                    <Button
                      style={{ backgroundColor: '#00a1ed', color: '#FFF' }}
                      className="btn-add"
                      icon="plus"
                      onClick={() => this.props.history.push('/plansChoice')}
                    >
                      Criar novo grupo
                    </Button>
                  </div>
                </Col>
                <Col md={{ span: 8 }} xs={{ span: 24 }}>
                  <div className="new-item" style={{ display: 'none' }}>
                    <ModalCreateFilial
                      active
                      titleButton="Cadastrar unidade"
                      dataCompany={this.state.itemCompany}
                      onCreateFilial={async payload =>
                        this.onCreateFilial(payload)
                      }
                    />
                    <ModalInvitation
                      active
                      visible={this.state.visibleTeste}
                      dataCompanyFilial={this.state.itemFilial}
                      titleButton="Enviar convite"
                      createInvitation={payload =>
                        this.createInvitation(payload)
                      }
                    />
                    <ModalUpdateCompany
                      visible={this.state.visible}
                      titleButton="Editar grupo"
                      onClose={() => this.setState({ visible: false })}
                      segments={this.state.segment}
                      dataId={this.state.updateCompanyId}
                      updateCompany={data => this.updateCompany(data)}
                    />
                    <ModalUpdateFilial
                      visible={this.state.visibleUpdateFilial}
                      titleButton="Editar unidade"
                      dataId={this.state.updateFilialId}
                      onClose={() =>
                        this.setState({ visibleUpdateFilial: false })
                      }
                      updateCompany={data => this.updateFilial(data)}
                    />
                    <ModalBeneficio
                      visible={this.state.modalBeneficio.visible}
                      onCancel={this.fecharModalBeneficio()}
                      onSave={this.salvarBeneficio()}
                      fields={this.state.modalBeneficio.data}
                    />
                    <ModalUtilizarCupom
                      visible={this.state.modalUtilizarCupom.visible}
                      cupomSelecionado={
                        this.state.modalUtilizarCupom.cupomSelecionado
                      }
                      onCancel={this.fecharModalUtilizarCupom()}
                      onPesquisar={this.pesquisarCupom()}
                      onUtilizar={this.utilizarCupom()}
                      data={this.state.modalUtilizarCupom.data}
                    />
                    <ModalLogomarca
                      visible={this.state.modalLogomarca.visible}
                      empresaId={this.state.modalLogomarca.empresaId}
                      nomeEmpresa={this.state.modalLogomarca.nomeEmpresa}
                      nomeFilial={this.state.modalLogomarca.nomeFilial}
                      filialId={this.state.modalLogomarca.filialId}
                      onClose={() => {
                        this.setState({
                          modalLogomarca: {
                            visible: false,
                          },
                        });
                      }}
                    />
                    <ModalTemplateEmail
                      visible={this.state.modalTemplateEmail.visible}
                      empresaId={this.state.modalTemplateEmail.empresaId}
                      nomeEmpresa={this.state.modalTemplateEmail.nomeEmpresa}
                      onClose={() => {
                        this.setState({
                          modalTemplateEmail: {
                            visible: false,
                          },
                        });
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Tabs type="card">
                <TabPane tab="Grupo / Unidade" key="1">
                  <Collapse accordion>
                    {this.props.dataUserCompany.map(item => {
                      return (
                        <Panel
                          header={`${item.nome}`}
                          key={item.id}
                          extra={
                            <Row
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Tooltip placement="topLeft" title={filialText}>
                                <Icon
                                  style={{ marginRight: 15, fontSize: 18 }}
                                  type="plus-square"
                                  onClick={e => {
                                    e.stopPropagation();
                                    this.openModal(item);
                                  }}
                                />
                              </Tooltip>
                              <Tooltip placement="topLeft" title={editText}>
                                <Icon
                                  style={{ marginRight: 15, fontSize: 18 }}
                                  type="edit"
                                  onClick={event => {
                                    this.setState({
                                      itemFilial: item,
                                      updateCompanyId: item.id,
                                      visible: true,
                                      visibleTeste: false,
                                      visibleFilial: false,
                                      visibleUpdateFilial: false,
                                    });
                                    event.stopPropagation();
                                  }}
                                />
                              </Tooltip>
                              <Tooltip
                                placement="topLeft"
                                title="Cadastrar logomarca grupo"
                              >
                                <Icon
                                  style={{ marginRight: 15, fontSize: 18 }}
                                  type="file-image"
                                  onClick={event => {
                                    event.stopPropagation();
                                    this.abrirModalLogomarca(
                                      item.id,
                                      0,
                                      item.nome,
                                      ''
                                    );
                                  }}
                                />
                              </Tooltip>
                              {/* <Tooltip placement="topLeft" title={invitText}>
                                <Icon
                                  style={{ fontSize: 18 }}
                                  type="usergroup-add"
                                  onClick={e => {
                                    this.setState({
                                      itemFilial: item,
                                      visible: false,
                                      visibleTeste: true,
                                      visibleFilial: false,
                                      visibleUpdateFilial: false,
                                    });
                                    e.stopPropagation();
                                  }}
                                />
                              </Tooltip> */}
                            </Row>
                          }
                        >
                          <Row>
                            <Col
                              style={{ padding: '5px' }}
                              xs={{ span: 24 }}
                              sm={{ span: 24 }}
                              md={{ span: 12 }}
                              lg={{ span: 12 }}
                            >
                              <Collapse
                                defaultActiveKey="1"
                                onChange={this.callback}
                              >
                                {dataFilial &&
                                  dataFilial
                                    .filter(_item => {
                                      return (
                                        _item.idEmpresa === item.id &&
                                        _item.id != 999999
                                      );
                                    })
                                    .map(_item => {
                                      return (
                                        <Panel
                                          header={`${_item.nome}`}
                                          key={_item.id}
                                          extra={
                                            <Row
                                              style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                              }}
                                            >
                                              <Col
                                                xs={{ span: 6 }}
                                                sm={{ span: 24 }}
                                                md={{ span: 24 }}
                                                lg={{ span: 10 }}
                                                style={{
                                                  display: 'flex',
                                                }}
                                              >
                                                <Tooltip
                                                  placement="topLeft"
                                                  title={editText}
                                                >
                                                  <Icon
                                                    style={{ fontSize: 14 }}
                                                    type="edit"
                                                    onClick={event => {
                                                      this.setState({
                                                        itemFilial: _item,
                                                        updateFilialId:
                                                          _item.id,
                                                        visible: false,
                                                        visibleTeste: false,
                                                        visibleFilial: false,
                                                        visibleUpdateFilial: true,
                                                      });
                                                      event.stopPropagation();
                                                    }}
                                                  />
                                                </Tooltip>
                                                <Tooltip
                                                  placement="topLeft"
                                                  title="Cadastrar logomarca unidade"
                                                >
                                                  <Icon
                                                    style={{
                                                      marginLeft: 15,
                                                      fontSize: 14,
                                                    }}
                                                    type="file-image"
                                                    onClick={event => {
                                                      event.stopPropagation();
                                                      this.abrirModalLogomarca(
                                                        item.id,
                                                        _item.id,
                                                        item.nome,
                                                        _item.nome
                                                      );
                                                    }}
                                                  />
                                                </Tooltip>
                                              </Col>
                                            </Row>
                                          }
                                        >
                                          {!this.state.showLink ? (
                                            <div
                                              style={{
                                                display: 'flex',
                                                gap: '8px',
                                                padding: '16px',
                                                alignItems: 'center',
                                              }}
                                            >
                                              <div>
                                                <img
                                                  src={this.generateQRCodeUrl(
                                                    `${this.service.endPointQuestionario}/${item.id}/${_item.id}`
                                                  )}
                                                  alt=""
                                                  style={{
                                                    width: 80,
                                                  }}
                                                />
                                              </div>

                                              <div>
                                                <div>
                                                  <label>
                                                    Avaliação padrão:{' '}
                                                  </label>
                                                  <a
                                                    href={`${this.service.endPointQuestionario}/${item.id}/${_item.id}`}
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                  >
                                                    {
                                                      this.service
                                                        .endPointQuestionario
                                                    }
                                                    /{item.id}/{_item.id}
                                                  </a>
                                                </div>

                                                <div>
                                                  <label>
                                                    Avaliação com reinício
                                                    automático:{' '}
                                                  </label>
                                                  <a
                                                    href={`${this.service.endPointQuestionario}/${item.id}/${_item.id}?quiosque`}
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                  >
                                                    {
                                                      this.service
                                                        .endPointQuestionario
                                                    }
                                                    /{item.id}/{_item.id}
                                                    ?quiosque
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          ) : null}
                                          <Row>
                                            <RedeSocial filial={_item.id} />
                                          </Row>
                                        </Panel>
                                      );
                                    })}
                              </Collapse>
                            </Col>
                            <Col
                              style={{ padding: '5px' }}
                              xs={{ span: 24 }}
                              sm={{ span: 24 }}
                              md={{ span: 12 }}
                              lg={{ span: 12 }}
                            >
                              {item.beneficio ? (
                                <Card>
                                  <h1>
                                    <Tooltip
                                      placement="topLeft"
                                      title="Alterar dados do prêmio"
                                    >
                                      <Icon
                                        style={{
                                          marginRight: 15,
                                          fontSize: 18,
                                        }}
                                        type="edit"
                                        onClick={() =>
                                          this.abrirModalBeneficio(
                                            item.beneficio
                                          )
                                        }
                                      />
                                    </Tooltip>
                                    <Tooltip
                                      placement="topLeft"
                                      title="Editar e-mail do cupom"
                                    >
                                      <Icon
                                        style={{
                                          marginRight: 15,
                                          fontSize: 18,
                                        }}
                                        type="mail"
                                        onClick={event => {
                                          event.stopPropagation();
                                          this.abrirModalTemplate(
                                            item.id,
                                            item.nome
                                          );
                                        }}
                                      />
                                    </Tooltip>
                                    Prêmio: {item.beneficio.nome}
                                  </h1>
                                  <Row>
                                    <Col span={16}>Prêmio ativo?</Col>
                                    <Col span={8}>
                                      <strong>
                                        {' '}
                                        {item.beneficio.ativo ? 'SIM' : 'NÃO'}
                                      </strong>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col span={16}>Vigência inicial:</Col>
                                    <Col span={8}>
                                      <strong>
                                        {' '}
                                        {`${moment(
                                          item.beneficio.dataInicial
                                        ).format('DD/MM/YYYY')}`}
                                      </strong>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col span={16}>Vigência final:</Col>
                                    <Col span={8}>
                                      <strong>
                                        {' '}
                                        {`${moment(
                                          item.beneficio.dataFinal
                                        ).format('DD/MM/YYYY')}`}
                                      </strong>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col span={16}>Limite de cupons:</Col>
                                    <Col span={8}>
                                      <strong>
                                        {' '}
                                        {`${
                                          item.beneficio.maximoCupons
                                            ? item.beneficio.maximoCupons
                                            : 'ILIMITADO'
                                        }`}
                                      </strong>
                                      <Tooltip
                                        placement="topLeft"
                                        title="Número máximo de cupons disponíveis no prêmio."
                                      >
                                        <Icon
                                          style={{
                                            marginLeft: 5,
                                            fontSize: 14,
                                          }}
                                          type="question-circle"
                                        />
                                      </Tooltip>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col span={16}>Validade do cupom:</Col>
                                    <Col span={8}>
                                      <strong>
                                        {' '}
                                        {`${item.beneficio.validadeCupomDias}`}{' '}
                                        {item.beneficio.validadeCupomDias > 1
                                          ? 'dias'
                                          : 'dia'}
                                      </strong>
                                      <Tooltip
                                        placement="topLeft"
                                        title="Período que o cupom é válido a partir de sua geração."
                                      >
                                        <Icon
                                          style={{
                                            marginLeft: 5,
                                            fontSize: 14,
                                          }}
                                          type="question-circle"
                                        />
                                      </Tooltip>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col span={16}>Limite para confirmar:</Col>
                                    <Col span={8}>
                                      <strong>
                                        {`${item.beneficio.tempoMaximoConfirmacaoHoras}`}{' '}
                                        {item.beneficio
                                          .tempoMaximoConfirmacaoHoras > 1
                                          ? 'hs'
                                          : 'h'}
                                      </strong>
                                      <Tooltip
                                        placement="topLeft"
                                        title="Tempo máximo para aguardar a validação do cupom. Após este período sem confirmação o cupom se torna inválido."
                                      >
                                        <Icon
                                          style={{
                                            marginLeft: 5,
                                            fontSize: 14,
                                          }}
                                          type="question-circle"
                                        />
                                      </Tooltip>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col span={16}>
                                      Aguardar antes de utilizar:
                                    </Col>
                                    <Col span={8}>
                                      <strong>
                                        {' '}
                                        {`${item.beneficio.tempoAguardarUtilizacaoHoras}`}{' '}
                                        {item.beneficio
                                          .tempoAguardarUtilizacaoHoras > 1
                                          ? 'hs'
                                          : 'h'}
                                      </strong>
                                      <Tooltip
                                        placement="topLeft"
                                        title="Tempo que o beneficiado deverá aguardar antes de utilizar seu cupom."
                                      >
                                        <Icon
                                          style={{
                                            marginLeft: 5,
                                            fontSize: 14,
                                          }}
                                          type="question-circle"
                                        />
                                      </Tooltip>
                                    </Col>
                                  </Row>
                                  {this.state.showRankCupom ? (
                                    <>
                                      <Row>
                                        <Col span={24}>
                                          <hr />
                                          <h1>
                                            <Icon
                                              style={{
                                                marginRight: 15,
                                                fontSize: 18,
                                              }}
                                              type="stock"
                                            />
                                            Estatísticas
                                          </h1>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col span={16}>
                                          Total de cupons já emitidos:
                                        </Col>
                                        <Col span={8}>
                                          <strong>
                                            {
                                              (
                                                item.beneficio.estatisticas ||
                                                {}
                                              ).totalCupons
                                            }
                                          </strong>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col span={16}>
                                          <span>
                                            <Icon
                                              style={{
                                                marginRight: 15,
                                                fontSize: 18,
                                              }}
                                              type="caret-right"
                                            />
                                            Total de cupons validados:
                                          </span>
                                        </Col>
                                        <Col span={8}>
                                          {
                                            (item.beneficio.estatisticas || {})
                                              .totalCuponsValidados
                                          }
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col span={16}>
                                          <span>
                                            <Icon
                                              style={{
                                                marginRight: 15,
                                                fontSize: 18,
                                              }}
                                              type="right"
                                            />
                                            Validados e já utilizados:
                                          </span>
                                        </Col>
                                        <Col span={8}>
                                          {
                                            (item.beneficio.estatisticas || {})
                                              .totalCuponsValidadosUtilizados
                                          }
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col span={16}>
                                          <span>
                                            <Icon
                                              style={{
                                                marginRight: 15,
                                                fontSize: 18,
                                              }}
                                              type="right"
                                            />
                                            Validados e aguardando utilização:
                                          </span>
                                        </Col>
                                        <Col span={8}>
                                          {
                                            (item.beneficio.estatisticas || {})
                                              .totalCuponsValidadosAguardandoUtilizacao
                                          }
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col span={16}>
                                          <span>
                                            <Icon
                                              style={{
                                                marginRight: 15,
                                                fontSize: 18,
                                              }}
                                              type="right"
                                            />
                                            Validados mas já expirados:
                                          </span>
                                        </Col>
                                        <Col span={8}>
                                          {
                                            (item.beneficio.estatisticas || {})
                                              .totalCuponsValidadosExpirados
                                          }
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col span={16}>
                                          <span>
                                            <Icon
                                              style={{
                                                marginRight: 15,
                                                fontSize: 18,
                                              }}
                                              type="caret-right"
                                            />
                                            Total de cupons não validados:
                                          </span>
                                        </Col>
                                        <Col span={8}>
                                          {
                                            (item.beneficio.estatisticas || {})
                                              .totalCuponsNaoValidados
                                          }
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col span={16}>
                                          <span>
                                            <Icon
                                              style={{
                                                marginRight: 15,
                                                fontSize: 18,
                                              }}
                                              type="right"
                                            />
                                            Não validados e ainda no prazo:
                                          </span>
                                        </Col>
                                        <Col span={8}>
                                          {
                                            (item.beneficio.estatisticas || {})
                                              .totalCuponsNaoValidadosAguardando
                                          }
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col span={16}>
                                          <span>
                                            <Icon
                                              style={{
                                                marginRight: 15,
                                                fontSize: 18,
                                              }}
                                              type="right"
                                            />
                                            Não validados e já expirados:
                                          </span>
                                        </Col>
                                        <Col span={8}>
                                          {
                                            (item.beneficio.estatisticas || {})
                                              .totalCuponsNaoValidadosExpirados
                                          }
                                        </Col>
                                      </Row>
                                    </>
                                  ) : (
                                    <>
                                      <Row>
                                        <Col span={24}>
                                          <hr />
                                          <h1>
                                            <Icon
                                              style={{
                                                marginRight: 15,
                                                fontSize: 18,
                                              }}
                                              type="stock"
                                            />
                                            Estatísticas
                                          </h1>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col span={16}>
                                          Total de cupons já enviados:
                                        </Col>
                                        <Col span={8}>
                                          <strong>
                                            {
                                              (
                                                item.beneficio.estatisticas ||
                                                {}
                                              ).totalCupons
                                            }
                                          </strong>
                                        </Col>
                                      </Row>
                                    </>
                                  )}
                                </Card>
                              ) : (
                                <Card>
                                  <h1>
                                    Você ainda não tem nenhum prêmio
                                    configurado.
                                  </h1>
                                  <a
                                    href="#"
                                    onClick={() =>
                                      this.abrirModalBeneficio({
                                        idBeneficio: 0,
                                        idEmpresa: item.id,
                                      })
                                    }
                                  >
                                    Clique aqui e cadastre seu primeiro prêmio.
                                  </a>
                                </Card>
                              )}
                            </Col>
                          </Row>
                        </Panel>
                      );
                    })}
                  </Collapse>
                </TabPane>
                <TabPane tab="Usúario" key="2">
                  <Table
                    className="table"
                    columns={columns}
                    dataSource={companyListUser}
                    rowKey={item => item.email}
                    scroll={{ x: 500 }}
                    size="middle"
                  />
                </TabPane>
                <TabPane tab="Alertas" key="3">
                  <Alerts
                    alertStatus={this.state.alertStatus}
                    setAlertStatus={this.setAlertStatus}
                  />
                </TabPane>
                <TabPane tab="Editar avaliações" key="4">
                  <QuestionsClient
                    data={this.state.dataQuestions}
                    dataPerguntasFilial={this.state.dataQuestionsEmpresa}
                    company={this.state.data}
                    saveQuestions={this.saveQuestions}
                  />
                </TabPane>
              </Tabs>
            </Spin>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { getDataUserCompany, getModalTrue, getIdFilial },
    dispatch
  );

const mapStateToProps = store => ({
  userData: store.userData.data,
  dataUserCompany: store.userData.dataUserCompany,
  clientFilial: store.userData.clientFilial,
  filialId: store.userData.filialId,
  dataFilial: store.userData.dataFilial,
  companySelected: store.userData.companySelected,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SettingsBlue));
