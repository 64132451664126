import Services from '../../services';

export default class Service extends Services {
  listUser = async (tokenValue) => {
    const tokenLogin = localStorage.getItem('token');
    try {
      const response = await fetch(`${this.endPoint}User`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'text/plain',
        },
      });

      const json = await response.json();

      return json;
    } catch (err) {
      console.log('Error problem', err);
      this.doLogout()
    }
  };

  updateUser = async (id, body) => {
    console.log('body', body);
    const tokenLogin = localStorage.getItem('token');
    try {
      const response = await fetch(`${this.endPoint}User/${id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      const json = await response.json();
      console.log('json', json);

      return json;
    } catch (err) {
      console.log('Error problem', err);
      this.doLogout()
    }
  };

  activeUser = async (id, body) => {
    console.log('id', id);
    const tokenLogin = localStorage.getItem('token');
    try {
      const response = await fetch(`${this.endPoint}User/ativar/${id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'text/plain',
        },
        body: JSON.stringify(body),
      });

      const json = await response.json();
      console.log('json', json);

      return json;
    } catch (err) {
      console.log('Error problem', err);
      this.doLogout()
    }
  };

  listUserPerfil = async (tokenValue) => {
    const tokenLogin = localStorage.getItem('token');
    try {
      const response = await fetch(`${this.endPoint}User/perfis`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'text/plain',
        },
      });

      const json = await response.json();

      return json;
    } catch (err) {
      console.log('Error problem', err);
      this.doLogout()
    }
  };

  disableUser = async (id, body) => {
    console.log('id', id);
    const tokenLogin = localStorage.getItem('token');
    try {
      const response = await fetch(`${this.endPoint}User/desativar/${id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'text/plain',
        },
        body: JSON.stringify(body),
      });

      const json = await response.json();
      console.log('json', json);

      return json;
    } catch (err) {
      console.log('Error problem', err);
      this.doLogout()
    }
  };

  // async deleteUser(id) {
  //   const tokenLogin = localStorage.getItem('token');
  //   try {
  //     const response = await fetch(`http://localhost:8000/admin/${id}`, {
  //       method: 'DELETE',
  //       headers: {
  //         Authorization: `Bearer ${tokenLogin}`,
  //         'Content-Type': 'application/json',
  //       },
  //     });

  //     const json = await response.json();

  //     return json;
  //   } catch (err) {
  //     console.log('Error problem', err);
  //   }
  // }
}
