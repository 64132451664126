const initialState = {
  filialId: 0,
  data: {},
  userId: '',
  modalState: {
    visible: false,
    data: {},
  },
  contractId: [],
  titleModal: '',
  dataPlans: {},
  userDataUpdate: {},
  dataPlansFunctions: [],
  userModal: {
    visible: false,
    data: {},
  },
  dataUserCompany: [],
  dataFilial: [],
  clientFilial: {
    seleted: {},
    filial: false,
  },
  invite: [],
  selectedRows: [],
  companySelected: [],
  questionsFilial: {},
  companyPlan: {}
};

export const userData = (state = initialState, action) => {
  switch (action.type) {
    case 'USER_DATA':
      return {
        ...state,
        data: action.data,
      };
    case 'GET_USER_ID':
      return {
        ...state,
        userId: action.userId,
      };
    case 'MODAL_TRUE':
      return {
        ...state,
        modalState: action.modalState,
      };
    case 'USER_MODAL':
      return {
        ...state,
        userModal: {...action.userModal},
      };
    case 'USER_DATA_UPDATE':
      return {
        ...state,
        userDataUpdate: action.userDataUpdate,
      };
    case 'CONTRACT_ID':
      return {
        ...state,
        contractId: action.contractId,
      };
    case 'TITLE_MODAL':
      return {
        ...state,
        titleModal: action.titleModal,
      };
    case 'DATA_PLANS':
      return {
        ...state,
        dataPlans: action.dataPlans,
      };
    case 'DATA_PLANS_FUNCTIONS':
      return {
        ...state,
        dataPlansFunctions: action.dataPlansFunctions,
      };
    case 'DATA_USER_COMPANY':
      return {
        ...state,
        dataUserCompany: action.dataUserCompany,
      };
    case 'DATA_FILIAL':
      return {
        ...state,
        dataFilial: [...action.dataFilial],
      };
    case 'CLIENT_FILIAL':
      return {
        ...state,
        clientFilial: action.clientFilial,
      };
    case 'SET_INVITATION':
      return {
        ...state,
        invite: action.invite,
      };
    case 'GET_FILIAL_ID':
      return {
        ...state,
        filialId: action.filialId,
      };
    case 'SET_CHECKETED_ROW':
      return {
        ...state,
        rowSelected: action.rowSelected,
      };
    case 'COMPANY_SELECTED':
      return {
        ...state,
        companySelected: action.companySelected,
      };
    case 'SET_QUESTIONS_FILIAL':
      return {
        ...state,
        questionsFilial: action.questionsFilial,
      };
    case 'COMPANY_PLAN':
      return {
        ...state,
        companyPlan: action.companyPlan,
      };

    default:
      return state;
  }
};
