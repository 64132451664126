import React, { Component } from 'react';
import { Rate, Checkbox } from 'antd';

export default class RateComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rateValue: [],
      checkSubPerguntas: []
    }
  }

	handleChange = (value, index) => {
    const { rateValue } = this.state
    rateValue[index] = value
    this.setState({ rateValue })

    if (value <= 3) {
      this.setState({ subPerguntas: this.props.subPergunta })
    } else {
      this.setState({ subPerguntas: 5 })
    }
    this.props.changeRate(value, this.props.item, this.props.index)
  };

  onChangeCheck(checkedValues, item) {
    console.log('checked = ', checkedValues.target.value);
    this.props.changeSubAsk(checkedValues.target.value, item)

    // this.setState({ ...this.state.checkSubPerguntas, checkSubPerguntas: checkedValues.target.value })
  }

  currentValue(){
    let currentResponse = this.props.arrResponse.filter( item => item.idPergunta === this.props.item.id ).shift()
    if(currentResponse && currentResponse.valorInteiro){
      return parseInt(currentResponse.valorInteiro)
    }
    return 0
  }

  render() {
    return (
      <span>
        <Rate onChange={this.handleChange} style={{ fontSize: 40, color: '#00a1ed' }} value={ this.props.arrResponse && this.props.arrResponse.length > 0 ? this.currentValue() : 0 } />
        {
          this.state.rateValue[this.props.index] <= 3 ?
            <>
              <h4>O que houve?</h4>
            {
              this.props.item.subPergunta.map(item => {
                return  (
                  <Checkbox
                    key={item.id}
                    onChange={(checkedValues) => this.onChangeCheck(checkedValues, item)}
                    value={item.id}
                  >
                    {item.descricao}
                  </Checkbox>
                )
              })
            }
            {/* <Checkbox.Group options={plainOptions} onChange={(checkedValues) => this.onChangeCheck(checkedValues)} /> */}
            </>
          : null
        }
      </span>
    );
  }
}
