import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Layout, Menu, Dropdown, Icon, message, Tooltip } from 'antd';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getUserData,
  setCompanySelected,
  getDataFilial,
  getCompanyPlan,
  getDataUserCompany,
  getDataPlans,
  setClientFilial,
  getIdFilial
} from '../../redux/actions';

import './styles.css';
import Services from '../../services';
import FormItem from 'antd/lib/form/FormItem';
//import logoIcon from '../../images/logoblue.png';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: true,
      name: '',
      gruposUsuario: [],
      grupoUnidade: '',
      alerta: ''
    };
    this.service = new Services();
  }

  componentDidMount() {
    if (!this.service.isLogged()) {
      this.props.history.push('/');
    } else {
      this.loadGrupos();
    }
  }

  componentWillReceiveProps(props) {
    const idUnidade = props.dataFilial.length > 0 ? props.dataFilial[0].id : 0;
    const idGrupo = props.companySelected.length > 0 ? props.companySelected[0].id : 0;
    this.setState({ 
      name: props.dataUser.unique_name, 
      grupoUnidade: `${idGrupo}-${idUnidade}` 
    });
  }

  doLogout = async () => {
    localStorage.setItem('user', 'null');
    localStorage.setItem('token', 'null');
    this.props.getUserData({});
    this.props.setCompanySelected([]);
    this.props.getCompanyPlan({});
    this.props.getDataFilial([]);
    message.success('Logout realizado com sucesso');
    this.props.history.push('/login');
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  async _currentUser() {
    const user = await this.service.currentUser();
    if (user === undefined) {
      await this.service.doLogout();
      this.props.history.push('/login');
    } else {
      // this.props.updateUser(user.data);
    }
  }

  doChangeCompany() {
    this.props.history.push('/client');
  }

  handleChangeUnidade = async (unidade) => {

    if(!unidade.target.value) {
      message.warn('Selecione um grupo/unidade válido');
      return;
    }
    const selecao = unidade.target.value.split('-');
    this.setState({
      grupoUnidade: unidade.target.value
    });
    const result = await this.service.listFilial();

    const filial = result.object.filter(item => {
      return selecao[1] == item.id;
    });

    this.props.setClientFilial({ selected: filial[0], filial: true });
    this.props.getDataFilial(filial);
    this.props.getIdFilial(filial.length > 0 ? filial[0].id : 0);

    const companyItem = this.props.dataUserCompany.filter(item => {
      return selecao[0] == item.id;
    });

    this.props.setCompanySelected(companyItem);
  }

  async loadGrupos() {
    const grupos = await this.service.getGruposUsuario();
    let alerta = '';
    if(grupos.object.length === 0) {
      alerta = 'Nenhum grupo ou unidade criados. Para começar, vá até a aba Opções e crie sua primeira unidade.'
    }
    this.setState({
      gruposUsuario: grupos.object,
      alerta: alerta
    })
  }

  render() {
    return (
      <Layout.Header className="header">
        {/* <img src={logoIcon} className="logo-icon" alt="App-Blue" /> */}

        <Dropdown
          className="user-logged"
          overlay={
            <Menu>
              {/* <Menu.Item key="0">Minha conta</Menu.Item> */}
              <Menu.Item key="1" onClick={this.doLogout}>
                Sair
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}
          placement="bottomRight"
        >
          <span>
            <Icon type="user" />
            {' ' + this.state.name + ' '}
            <Icon type="down" />
          </span>
        </Dropdown>

        <Tooltip
            placement="bottomLeft"
            title={this.state.alerta}
        >
        <select className="select-unidade"
        onChange={this.handleChangeUnidade}
        >
          <option value="">Selecione um Grupo/Unidade</option>
          {
            this.state.gruposUsuario.map(g => {
              return (
                  <optgroup style={{
                    borderTop: '1px',
                    borderTopColor: '#000',
                    borderTopStyle: 'solid'
                  }} key={g.grupoId}label={g.grupoNome}>
                  {
                    g.unidades.map(u => {
                      return (
                        <option 
                          key={`${g.grupoId}-${u.unidadeId}`} 
                          value={`${g.grupoId}-${u.unidadeId}`}
                          selected={this.state.grupoUnidade === `${g.grupoId}-${u.unidadeId}`}
                          style={{
                            fontWeight: `${u.unidadeId === 999999 ? 'bold' : ''}`,
                          }}
                        >{   
                          this.state.grupoUnidade === `${g.grupoId}-${u.unidadeId}` ? 
                            `${g.grupoNome}/${u.unidadeNome}`
                            : 
                            `  ${u.unidadeNome}`
                          }
                        </option>
                      )
                    })
                  }
                  <hr />
                  <br />
                  </optgroup>
              )
            })
          }
        </select>
        </Tooltip>
      </Layout.Header>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { getUserData, 
      setCompanySelected, 
      getDataFilial, 
      getCompanyPlan,       
      getDataUserCompany,
      getDataPlans,
      setClientFilial,
      getIdFilial,
      setCompanySelected
    },
    dispatch
  );

const mapStateToProps = store => ({
  data: store.userData.data,
  companySelected: store.userData.companySelected,
  dataFilial: store.userData.dataFilial,
  userData: store.userData.data,
  dataUserCompany: store.userData.dataUserCompany,
  clientFilial: store.userData.clientFilial,
  companyPlan: store.userData.companyPlan,
  filialId: store.userData.filialId,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Header));
