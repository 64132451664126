import Services from '../../services';

export default class Service extends Services {
  getDataFeedbacks = async (dataInicial, dataFinal, idFilial, idEmpresa) => {
    const tokenLogin = localStorage.getItem('token');
    try {
      let url = `${this.endPoint}Graficos/dadoPerguntas/${dataInicial}/${dataFinal}/${idFilial}`;
      if(idEmpresa > 0)
      {
        url += `?idEmpresa=${idEmpresa}`;
      }
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'application/json',
        },
      });

      const json = await response.json();

      return json;
    } catch (err) {
      console.log('Error problem', err);
      this.doLogout()
    }
  };

  checkAnswers = async (id) => {
    const tokenLogin = localStorage.getItem('token');
    try {
      const response = await fetch(`${this.endPoint}Questionario/AlterarChecagemRespostas/${id}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'application/json',
        },
      });
      const json = await response.json();

      return json;
    } catch (err) {
      console.log('Error problem', err);
    }
  };

  consultarTipoExibicao = async (empresaId) => {
    const service = new Services();
    const tokenLogin = localStorage.getItem('token');
    try {
      const response = await fetch(`${service.endPoint}Empresa/consultar-tipo-exibicao/${empresaId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'text/plain',
        },
      });
  
      const json = await response.json();
  
      return json;
    } catch (err) {
    }
  };
}
