import React from 'react';
import {
  message,
  Form,
  Modal,
  Input,
  Button,
  Radio,
  Tooltip,
  Icon,
  DatePicker,
  InputNumber,
  Spin,
  Row,
  Col
} from 'antd'
import moment from 'moment';

export default class ModalUtilizarCupom extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      pesquisando: false
    };
  }

  render() {
    const self = this;
    const { visible, onCancel, onPesquisar, onUtilizar, cupomSelecionado } = self.props;
    const filial = self.props.data.filial || {};

    const doPesquisar = (value) => {
      if (typeof (value) !== 'string' || !value.trim().length) {
        message.error('Informe um número válido de cupom!');
        return;
      }
      self.setState({ pesquisando: true });
      onPesquisar(filial.id, value, () => {
        self.setState({ pesquisando: false });
      });
    }
    const doUtilizar = () => {
      self.setState({ utilizando: true });
      onUtilizar(filial.id, cupomSelecionado.numero, () => {
        self.setState({ utilizando: false });
      });
    }
    const doCancel = () => {
      onCancel();
    }

    return (
      <Modal
        visible={visible}
        title="Utilizar Cupom"
        onCancel={doCancel}
        footer={!!cupomSelecionado ? (
          <div>
            <Row>
              <Col span={8}>Número do cupom:</Col>
              <Col span={16}><strong>{cupomSelecionado.numero}</strong></Col>
            </Row>
            <Row>
              <Col span={8}>Gerado em:</Col>
              <Col span={16}><strong>{moment(cupomSelecionado.dataGeracao).format('DD/MM/YYYY HH:mm:ss')}</strong></Col>
            </Row>
            <Row>
              <Col span={8}>Nome do beneficiado:</Col>
              <Col span={16}><strong>{cupomSelecionado.nomeBeneficiado}</strong></Col>
            </Row>
            <Row>
              <Col span={8}>E-mail:</Col>
              <Col span={16}><strong>{cupomSelecionado.emailBeneficiado}</strong></Col>
            </Row>
            <Row>
              <Col span={8}>Telefone:</Col>
              <Col span={16}><strong>{cupomSelecionado.telefoneBeneficiado}</strong></Col>
            </Row>
            <Button type="success" loading={this.state.utilizando} onClick={() => doUtilizar()}>Confirmar utilização do cupom</Button>
          </div>
        ) : null}
      >
        {this.state.pesquisando
          ? (
            <div style={{ textAlign: "center" }}>
              <Spin tip="Pesquisando..." />
            </div>
          ) : (
            <Input.Search
              placeholder={!!cupomSelecionado ? cupomSelecionado.numero : "Informe o número do cupom"}
              disabled={!!cupomSelecionado}
              enterButton
              size="large"
              onSearch={value => doPesquisar(value)}
            />
          )}
      </Modal>
    );
  }
}
