import React, { Component } from 'react';
import { Layout, Menu, Icon } from 'antd';
import Header from '../../components/Header';
import logoIcon from '../../images/logoblue.png';

//import Sidebar from '../../components/Sidebar';
import './styles.css';

const { Content, Footer, Sider } = Layout;

export default class Main extends Component {
  render() {
    return (
      <Layout>
        {/* <Sidebar /> */}
        <Sider
          breakpoint="md"
          width={130}
          collapsedWidth="0"
          onBreakpoint={broken => {
            console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
        >
          <div className="logo">
            <img src={logoIcon} className="logo-icon" alt="App-Blue" />
          </div>
          <Menu theme="dark" mode="inline" defaultSelectedKeys={['4']}>
            <Menu.Item key="1">
              <Icon type="user" />
              <span className="nav-text">Dashboard</span>
            </Menu.Item>
            <Menu.Item key="2">
              <Icon type="video-camera" />
              <span className="nav-text">Administradores</span>
            </Menu.Item>
            <Menu.Item key="3">
              <Icon type="upload" />
              <span className="nav-text">Empresas</span>
            </Menu.Item>
            <Menu.Item key="4">
              <Icon type="user" />
              <span className="nav-text">Opções</span>
            </Menu.Item>
            <Menu.Item key="5">
              <Icon type="user" />
              <span className="nav-text">Sair</span>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout>
          <Header style={{ background: 'red', padding: 0 }} />
          <Content
            style={{
              margin: '24px 16px 0',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                padding: 24,
                background: '#fff',
                minHeight: 360,
                width: '100%',
              }}
            >
              content
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>
            Ant Design ©2018 Created by Ant UED
          </Footer>
        </Layout>
      </Layout>
    );
  }
}
