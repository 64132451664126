import { Checkbox, Form, Icon, Input, message, Modal, Radio, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { obterLogo, obterTemplate, removerImagem, removerLogo, removerTemplate, salvarLogo, salvarTemplateEmail } from './services';
import './styles.css';

export default function ModalTemplateEmail(props) {
    const [loading, setLoading] = useState(false);
    const [idAtual, setIdAtual] = useState(0);
    const [conteudo, setConteudo] = useState('');
    const [tipoRegra, setTipoRegra] = useState(0);
    const [tipoEmail, setTipoEmail] = useState(1);
    const [status, setStatus] = useState(true);
    const [assunto, setAssunto] = useState('');
    const [file, setFile] = useState(null);
    const [imagem, setImagem] = useState(''); 
    const [inputFileKey, setInputFileKey] = useState(1);

    const modules = {
        toolbar: [
          [{ 'header': [1, 2, false] }],
          ['bold', 'italic', 'underline','strike','link']
        ],
    }
    
    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike',
        'link'
    ]

    const {
        visible,
        empresaId,
        nomeEmpresa,
        onClose,
    } = props;

    const onHandleOk = async () => {
        if(!tipoRegra) {
            message.warning('Selecione o tipo de regra do modelo.');
            return;
        }
        if(!conteudo && !file) {
            message.warning('Informe o texto e\\ou imagem para o modelo.');
            return;
        }
        setLoading(true);
        const result = await salvarTemplateEmail(empresaId, conteudo, status, tipoRegra, tipoEmail, assunto, file, idAtual);
        if(result && result.object) {
            message.success(`Modelo ${idAtual > 0 ? 'alterado' : 'cadastrado'} com sucesso.`);
            reset();
            onClose();
        } else {
            message.error(result ? result.message : 'Erro ao cadastrar modelo.');
        }
        setLoading(false);
    }

    const handleDelete = async () => {
        if(idAtual > 0) {
            setLoading(true);
            const result = await removerTemplate(idAtual);
            if(result && result.object) {
                message.success(`Modelo excluído com sucesso.`);
                reset();
            } else {
                message.error(result ? result.message : 'Erro ao excluir modelo.');
            }
            setLoading(false);
        }
    }

    const onChangeTipoRegra = (e) => {
        setTipoRegra(e.target.value);
    }

    const reset = () => {
        setConteudo('');
        setIdAtual(0);
        setStatus(true);
        setTipoRegra(0);
        setAssunto('');
        setFile(null);
        setImagem('');
        setInputFileKey(Math.random());
    }

    const handleFile = (e) => {
        if(!e.target.files) {
            return;
        }

        const image = e.target.files[0];
        const extensoes = ['image/png','image/jpeg','image/jpg','image/gif'];
        if(extensoes.includes(image.type)) {
            setFile(image);
            setImagem(URL.createObjectURL(image));
        } else {
            message.error('Extensão de arquivo inválida.');
        }
    }

    const handleDeleteFile = async () => {
        setFile(null);
        setImagem('');
        if(idAtual > 0) {
            await removerImagem(idAtual);
        }
    }

    useEffect(() => {
        async function load() {
            if(empresaId > 0) {
                const result = await obterTemplate(empresaId);

                if(result) {
                    setConteudo(result.conteudo);
                    setIdAtual(result.id);
                    setStatus(result.status);
                    setTipoRegra(result.tipoRegra);
                    setAssunto(result.assunto);
                    setImagem(result.imagem);
                }
            }
        }

        load();
    },[empresaId]);

    return (
        <Modal visible={visible} 
        onCancel={() => onClose()} 
        cancelText="Cancelar"
        okText="Salvar"
        title={`Cadastrar template de email para ${nomeEmpresa}`}
        confirmLoading={loading}
        onOk={() => onHandleOk()}
        width={800}>
            <div className="form-content-template">
                <div className='form-template'>
                    <Form>
                        <Form.Item>
                            <Checkbox style={{marginRight: '3px'}} name='status' checked={status} onChange={(e) => setStatus(e.target.checked)} />
                            <span>Modelo de e-mail ativo?</span>
                        </Form.Item>
                        <Form.Item>
                            <label>No e-mail do cupom, você deseja que seja enviado:</label>
                            <br /><br />
                            <Radio.Group
                                onChange={onChangeTipoRegra} value={tipoRegra}>
                                <Radio name='tipo' value={1}  /> 
                                <span>Sem o cupom da Blue. Apenas o texto e\ou imagem ao lado.</span>
                                <br />
                                <Radio style={{marginLeft: '3px'}} name='tipo' value={2} /> 
                                <span>Com o cupom da Blue e também o texto e/ou imagem ao lado.</span>
                            </Radio.Group>
                        </Form.Item>
                        {
                            tipoRegra === 2 && (
                                <>
                                    <Icon
                                        style={{
                                        marginRight: 5,
                                        fontSize: 14,
                                        }}
                                        type="question-circle"
                                    />
                                    <span className="info">O texto/imagem ao lado aparecerão logo após o link do cupom.</span>
                                </>
                                
                            )
                        }
                        
                        
                    </Form>
                    {
                        idAtual > 0 && (
                            <label className='delete' onClick={() => handleDelete()}>Remover este modelo.</label>
                        )
                    }
                    
                </div>
                <div className='form-template'>
                    <h4>Conteúdo do e-mail</h4>
                    <Form.Item>
                        <label>Assunto: </label>
                        <Input
                            placeholder="Assunto"
                            type="text"
                            value={assunto}
                            onChange={(e) => setAssunto(e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item>
                        <label>Texto: </label>
                        <ReactQuill theme="snow" style={{
                                height: '200px'
                            }} value={conteudo} onChange={setConteudo} 
                            modules={modules} formats={formats} />
                    </Form.Item>
                    <br /><br />
                    <Form.Item>
                        <label>Imagem: </label>
                        <div>
                            <Icon
                                style={{
                                marginRight: 5,
                                fontSize: 14,
                                }}
                                type="question-circle"
                            />
                            <span className='info'>Recomendamos máximo de 600 pixels de largura.</span>
                        </div>
                        <div>
                            <Icon
                                style={{
                                marginRight: 5,
                                fontSize: 14,
                                }}
                                type="question-circle"
                            />
                            <span className='info'>A imagem será enviada no email no tamanho original.</span>
                        </div>
                        <Input
                            placeholder="Assunto"
                            type="file"
                            key={inputFileKey}
                            onChange={(e) => handleFile(e)}
                        />
                    </Form.Item>
                    {
                        imagem ? (
                            <>
                                <div>
                                    <label className='delete' onClick={() => handleDeleteFile()}>Remover esta imagem.</label>
                                </div>
                                <img alt="Imagem atual" src={imagem} />
                                
                            </>
                            
                        ) : (
                            <></>
                        )
                    }
                </div>
            </div>
            
        </Modal>
    )
}