import React, { Component } from 'react';
import { Layout, Row, Col, Button } from 'antd';
import './styles.css';
import Service from './Service';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getUserData,
  getDataUserCompany,
  getModalTrue,
  setQuestionsFilial,
} from '../../redux/actions';
import { obterLogo } from '../../components/ModalLogomarca/services';

import btnInsta from '../../images/btnInstagram.png';
import btnZap from '../../images/btnWhatsApp.png';

const { Header, Content, Footer } = Layout;

class Quiz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      indice_blue: null,
      showButton: true,
      critica: '',
      elogio: '',
      nome: '',
      celular: '',
      email: '',
      arrResponse: [],
      subPerguntas: [],
      rateValue: [],
      checkSubPerguntas: [],
      logoCliente: '',
      unidadeSemNome: false,
      respostaUnica: false,
      loading: true,
      temRedeSocial: false,
      exibirGoogle: false,
      exibirTrip: false,
      exibirInsta: false,
      exibirWhatsApp: false,
      urlGoogle: '',
      urlTrip: '',
      urlInsta: '',
      urlWhats: '',
      textoInsta: '',
      textoZap: '',
      exibirRedes: false,
    };
    this.service = new Service();

    if (typeof window.Tawk_API === 'object') {
      let hideTawkToInteval = setInterval(function() {
        if (
          typeof window.Tawk_API.hideWidget !== 'function' ||
          typeof window.Tawk_API.isChatHidden !== 'function'
        )
          return;

        window.Tawk_API.hideWidget();

        if (window.Tawk_API.isChatHidden()) {
          clearInterval(hideTawkToInteval);
        }
      }, 100);
    }
  }

  async componentDidMount() {
    const { idEmpresa, idFilial } = this.props.match.params;

    if (idEmpresa && idFilial) {
      const unidadeSemNome = await this.service.getUnidadeSemNome(idFilial);
      await this.setState({
        idEmpresa: Number(idEmpresa),
        idFilial: Number(idFilial),
        unidadeSemNome: unidadeSemNome,
      });

      const url = window.location.href;

      let quiosque = undefined;

      if (url.includes('quiosque')) {
        quiosque = url;
      }

      await this.getQuestionsFilial();
      await this.carregarLogo(Number(idEmpresa), Number(idFilial));

      const resultRedes = await this.service.listarRedeSocialPorFilial(
        idFilial
      );

      const redes = resultRedes.object;

      let google = false;
      let googleUrl = '';
      let trip = false;
      let tripUrl = '';
      let insta = false;
      let instaUrl = '';
      let zap = false;
      let zpUrl = '';
      let exibirRedes = false;
      let txtCritica = '';
      let txtElogio = '';
      let txtInsta = '';
      let txtZap = '';

      if (!quiosque) {
        if (redes && redes.length > 0) {
          exibirRedes = true;

          const goo = redes.filter(x => x.tipo === 'Google');

          if (goo && goo.length > 0) {
            google = true;
          } else {
            google = false;
          }

          const trp = redes.filter(x => x.tipo === 'TripAdvisor');

          if (trp && trp.length > 0) {
            trip = true;
          } else {
            trip = false;
          }

          const ig = redes.filter(x => x.tipo === 'Instagram');
          if (ig && ig.length > 0) {
            insta = true;
            instaUrl = ig[0].urlAcesso;
            txtInsta = ig[0].texto;
          } else {
            insta = false;
          }

          const zp = redes.filter(x => x.tipo === 'WhatsApp');

          if (zp && zp.length > 0) {
            zap = true;
            zpUrl = zp[0].urlAcesso;
            txtZap = zp[0].texto;
          } else {
            zap = false;
          }
        }
      }

      this.setState({
        loading: false,
        temRedeSocial: exibirRedes,
        exibirGoogle: google,
        exibirInsta: insta,
        exibirTrip: trip,
        exibirWhatsApp: zap,
        urlGoogle: googleUrl,
        urlTrip: tripUrl,
        urlInsta: instaUrl,
        urlWhats: zpUrl,
        critica: txtCritica,
        elogio: txtElogio,
        textoInsta: txtInsta,
        textoZap: txtZap,
      });
    } else {
      // TODO: sair dessa tela
      console.log('TODO: sair dessa tela');
    }

    try {
      let queryString = window.location.search || '';

      if (queryString.startsWith('?')) {
        queryString = queryString.substring(1);
      }

      var queryStringKeys = queryString
        .split('&')
        .filter(v => v)
        .map(v => (v.split('=')[0] || '').toLowerCase());

      if (queryStringKeys.indexOf('quiosque') >= 0) {
        window.sessionStorage.setItem('quiosque', window.location.href);
      }
    } catch (error) {
      console.error(error);
    }
  }

  getQuestionsFilial = async () => {
    const idFilial = this.state.idFilial;
    const result = await this.service.getQuestionsFilial(idFilial);
    const data = result.object;

    this.props.setQuestionsFilial(result.object);

    this.setState({
      dataFilial: data,
    });
  };

  getNomeFilial(nome) {
    return this.state.unidadeSemNome ? '' : `(${nome})`;
  }

  getValueLocalStorage(idEmpresa, idFilial) {
    // Obtém todos os cookies e divide-os em um array
    const cookies = document.cookie.split(';');
    // Loop através de todos os cookies
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();

      // Verifica se o cookie começa com o nome desejado
      if (cookie.startsWith(`${idEmpresa}:${idFilial}=`)) {
        // Obtém o valor do cookie
        const cookieValue = cookie.substring(
          `${idEmpresa}:${idFilial}=`.length,
          cookie.length
        );

        // Retorna o valor decodificado
        return JSON.parse(decodeURIComponent(cookieValue));
      }
    }

    // Se o cookie não for encontrado, retorna null
    return null;
  }

  getRespostaUnica() {
    const { idEmpresa, idFilial } = this.props.match.params;

    const respostaFilial = this.getValueLocalStorage(idEmpresa, idFilial);

    this.setState({
      respostaUnica: respostaFilial,
    });
  }

  carregarLogo = async (idEmpresa, idFilial) => {
    let url = '';
    let planoEmpresa = 0;

    const resultFilial = await obterLogo(idEmpresa, idFilial);
    url = resultFilial.url;
    planoEmpresa = resultFilial.planoEmpresa;

    if (!resultFilial.url) {
      const resultEmpresa = await obterLogo(idEmpresa, 0);
      url = resultEmpresa.url;
      planoEmpresa = resultEmpresa.planoEmpresa;
    }

    this.setState({
      logoCliente: url,
    });

    if (planoEmpresa === 4 || planoEmpresa === 5 || planoEmpresa === 6) {
      this.getRespostaUnica();
    }
  };

  handleInsta() {
    window.open(this.state.urlInsta, '_blank');
  }

  handleWhatsApp() {
    window.open(this.state.urlWhats, '_blank');
  }

  render() {
    return !this.state.dataFilial ? (
      <div className="root-loading">
        <img src="/loading.gif" />
        <span>Quase pronto...</span>
      </div>
    ) : !this.state.respostaUnica ? (
      <>
        <Layout>
          <Content
            style={{
              padding: '24px 16px 0',
              backgroundColor: '#FFF',
            }}
          >
            <Row style={{ flexDirection: 'row', marginTop: 50 }}>
              <Col
                xs={{ span: 24 }}
                md={{ span: 12, offset: 6 }}
                lg={{ span: 12, offset: 6 }}
                style={{ fontSize: '18pt', fontWeight: 'lighter' }}
              >
                {this.state.logoCliente && (
                  <Row
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img src={this.state.logoCliente} class="logo-cliente" />
                  </Row>
                )}
                <Row>
                  <p style={{ fontSize: '22pt' }}>Olá!</p>
                </Row>
                <Row
                  style={{
                    flexDirection: 'row',
                    textAlign: 'center',
                    marginTop: 20,
                  }}
                >
                  <Col span={24}>
                    {!this.state.logoCliente && (
                      <>
                        <p
                          style={{
                            fontWeight: 'bold',
                            marginBottom: '0px',
                            fontSize: '22pt',
                          }}
                        >
                          {this.state.dataFilial &&
                            this.state.dataFilial.filial.nomeEmpresa}
                        </p>
                        <p>
                          {this.state.dataFilial &&
                            this.getNomeFilial(
                              this.state.dataFilial.filial.nome
                            )}
                        </p>
                      </>
                    )}
                    <p
                      style={{
                        marginBottom: '0px',
                      }}
                    >
                      {`${
                        !this.state.logoCliente ? 'gostaria' : 'Gostaríamos'
                      }`}{' '}
                      de ouvir sua <br />
                      <strong style={{ whiteSpace: 'pre' }}>
                        opinião sincera.
                      </strong>
                    </p>
                  </Col>
                </Row>
                <Row
                  style={{
                    flexDirection: 'row',
                    textAlign: 'center',
                    marginTop: 50,
                  }}
                >
                  {this.state.dataFilial && this.state.dataFilial.beneficio ? (
                    <Col span={24}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.state.dataFilial.beneficio.textoInicial,
                        }}
                      ></div>
                    </Col>
                  ) : (
                    <Col span={24}>
                      <p>
                        Leva apenas alguns segundos. Sua opinião nos ajuda a
                        melhorar pensando em você.
                      </p>
                    </Col>
                  )}
                  <Button
                    type="ghost"
                    style={{
                      marginTop: 20,
                      fontWeight: 'normal',
                      paddingLeft: '30px',
                      paddingRight: '30px',
                      textTransform: 'uppercase',
                      height: 'auto',
                      fontSize: '28pt',
                    }}
                    onClick={() =>
                      this.props.history.push({
                        pathname: `/blue/questionario/${this.state.idEmpresa}/${this.state.idFilial}/steps`,
                      })
                    }
                  >
                    COMEÇAR
                  </Button>
                </Row>
              </Col>
            </Row>
          </Content>
        </Layout>
      </>
    ) : (
      <Layout>
        <Content
          style={{
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '100px',
            backgroundColor: '#FFF',
          }}
        >
          {this.state.logoCliente && (
            <Row
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img src={this.state.logoCliente} class="logo-cliente" />
            </Row>
          )}

          <Row>
            <p style={{ fontSize: '22pt', textAlign: 'center' }}>
              <strong>Obrigado!</strong> <br></br> Você já fez esta avaliação
              recentemente.
            </p>
          </Row>

          {this.state.temRedeSocial && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                width: '100%',
                paddingLeft: '10%',
              }}
            >
              <Button
                onClick={() => this.handleInsta()}
                className="btn-rede-social follow-us"
              >
                <img src={btnInsta} alt="Siga a gente no instagram" />
                <label>{this.state.textoInsta}</label>
              </Button>
              <Button
                onClick={() => this.handleWhatsApp()}
                className="btn-rede-social follow-us"
              >
                <img src={btnZap} alt="Fale com a gente no whatsapp" />
                <label>{this.state.textoZap}</label>
              </Button>
            </div>
          )}
        </Content>
      </Layout>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { getUserData, getDataUserCompany, getModalTrue, setQuestionsFilial },
    dispatch
  );

const mapStateToProps = store => ({
  filialId: store.userData.filialId,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Quiz);
