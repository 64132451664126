import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// import InputMask from 'react-input-mask';
// import { validaPhone } from '../../utils'

import { Button, Input, Icon, message, Form } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getUserData,
  setClientFilial,
  setClientInvite,
} from '../../redux/actions';

import './styles.css';

import Service from './Service';

class FormRegister extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      login: {
        username: '',
        password: '',
      },
      loading: false,
      user_register: false,
      nome: '',
      celular: '',
      email: '',
      empresa: '',
    };
    this.service = new Service();
  }

  onChangeForm = async () => {
    let payload = {
      nome: this.state.nome,
      celular: this.state.celular,
      email: this.state.email,
      empresa: this.state.empresa,
    };

    const result = await this.service.registerUser(payload);

    if (result.mensage === 'Created') {
      this.setState({ loading: false });
      message.success('Cadastro realizado, verifique seu email.', 9);

      setTimeout(() => {
        this.props.history.push('/');
      }, 6000);
    } else if (result.mensage === 'CPF/CNPJ já cadastrado.') {
      message.error('Email já cadastrado', 9);
      setTimeout(() => {
        this.props.history.push('/');
      }, 6000);
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      // console.log("values", values)
      if (!err) {
        this.setState({
          loading: true,
          email: values.email,
          celular: values.celular,
          empresa: values.empresa,
        });
        this.onChangeForm();
      }
    });
  };

  render() {
    const { loading } = this.state;
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8, offset: 4 },
        lg: { span: 12, offset: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        lg: { span: 24 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        lg: { span: 24 },
      },
    };

    return (
      <div>
        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
          <Form.Item>
            {getFieldDecorator('name', {
              initialValue: this.state.nome,
              rules: [
                {
                  required: true,
                  message: 'Por favor, informe seu nome!',
                  whitespace: true,
                },
              ],
            })(
              <Input
                placeholder={'Nome completo'}
                prefix={
                  <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                onChange={e => {
                  this.setState({ nome: e.target.value });
                }}
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('email', {
              initialValue: this.state.email,
              rules: [
                {
                  type: 'email',
                  message: 'Forneça um e-mail válido!',
                },
                {
                  required: true,
                  message: 'E-mail obrigatório!',
                },
              ],
            })(
              <Input
                placeholder="Email"
                type="email"
                prefix={
                  <Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                onChange={e => {
                  this.setState({ email: e.target.value });
                }}
              />
            )}
          </Form.Item>
          {/* <Form.Item>
            {getFieldDecorator('celular', {
              initialValue: celular,
              rules: [
                {
                  required: true,
                  message: 'Forneça um número do celular!',
                },
                {
                  validator: validaPhone,
                }
              ],
            })(
              <span className="ant-input-affix-wrapper">
                <span className="ant-input-prefix">
                  <Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />
                </span>
                <InputMask
                className="ant-input"
                mask="(99) 99999-9999"
                placeholder="Celular"
                onChange={(e) => {
                  this.setState({ celular: e.target.value });
                }}
              />
              </span>,
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('empresa', {
              initialValue: this.state.empresa,
              rules: [{ required: true, message: 'Por favor, informe o nome da empresa!', whitespace: true }],
            })(
              <Input
                placeholder={"Nome da empresa"}
                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                onChange={(e) => {
                  this.setState({ empresa: e.target.value });
                }}
              />,
            )}
          </Form.Item> */}
          <Form.Item {...tailFormItemLayout}>
            <Button
              style={{
                backgroundColor: '#00a1ed',
                color: '#FFF',
                marginTop: 0,
              }}
              loading={loading}
              htmlType="submit"
            >
              Registrar
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

FormRegister.propTypes = {
  history: PropTypes.object.isRequired,
  getUserData: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { getUserData, setClientFilial, setClientInvite },
    dispatch
  );
const mapStateToProps = store => ({ data: store.userData.data });

const formCreate = Form.create()(FormRegister);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(formCreate));
