import Services from '../../services';

export default class Service extends Services {
  userUpdate = async (body, id) => {
    const tokenLogin = localStorage.getItem('token');
    try {
      const response = await fetch(`${this.endPoint}User/${id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      const json = await response.json();

      return json;
    } catch (err) {
      console.log('Error problem', err);
      //this.doLogout()
    }
  };
}
