import Services from '../../services';

export async function salvarRedeSocial(request) {
    const service = new Services();
    const tokenLogin = localStorage.getItem('token');
    try {
      const response = await fetch(`${service.endPoint}RedeSocial`, {
        method: request.id > 0 ? 'PUT' : 'POST',
        body: JSON.stringify(request),
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'application/json',
        },
      });

      const json = await response.json();

      return json;
    } catch (err) {
      console.log('Error problem', err);
    }
}

export async function listarPorFilial(filial) {
    const service = new Services();
    const tokenLogin = localStorage.getItem('token');
    try {
      const response = await fetch(`${service.endPoint}RedeSocial/listar-por-filial/${filial}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'application/json',
        },
      });

      const json = await response.json();

      return json;
    } catch (err) {
      console.log('Error problem', err);
    }
}

export async function excluirRedeSocial(id) {
    const service = new Services();
    const tokenLogin = localStorage.getItem('token');
    try {
      const response = await fetch(`${service.endPoint}RedeSocial/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${tokenLogin}`,
          'Content-Type': 'application/json',
        },
      });

      const json = await response.json();

      return json.object;
    } catch (err) {
      console.log('Error problem', err);
    }
}