import React, { Component } from 'react';
import * as jwt_decode from 'jwt-decode';
import PropTypes from 'prop-types';
import {
  Layout, Icon, Card, Col, Row, Switch,
} from 'antd';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import update from 'immutability-helper';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Breadcrumb from '../../components/Breadcrumb';
import ModalContracts from '../../components/ModalContracts';

import { getModalTrue, getTitleModal, getDataPlansFunctions } from '../../redux/actions';

import Service from './Service';

import './styles.css';

const { Content } = Layout;

class ListPlans extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      data: [],
      functionsPlan: [],
    };

    this.service = new Service();
    this.lisPlans = this.lisPlans.bind(this);
  }

  async componentDidMount() {
    if (!this.service.isLogged()) {
      this.props.history.push('/login');
    } else {
      const tokenLogin = localStorage.getItem('token');
      const decodeToken = jwt_decode(tokenLogin);
      this.setState({
        role: decodeToken.role,
        dataUser: decodeToken,
      });
      this.lisPlans();
    }

    this.lisPlansFunctions();
  }

  onChange = async (item, index) => {    
    const ativo = !this.state.data[index].ativo;

    await this.setState({
      data: update(this.state.data, { [index]: { ativo: { $set: ativo } } }),
    });

    await this.service.changePlans(item.id);
  };

  onCreateContract = async (data) => {
    if (data.id) {
      const payloadUpdate = {
        id: data.id,
        titulo: data.titulo,
        descricao: data.descricao,
      };

      await this.service.updateContract(data.id, payloadUpdate);
    } else {
      const payloadCreate = {
        titulo: data.titulo,
        descricao: data.descricao,
      };

      await this.service.createContract(payloadCreate);
    }
    this.lisContracts();
  };

  onCreatePlans = async (data) => {
    console.log('data id', data);

    if (data.id) {
      const payload = {
        id: data.id,
        idContrato: data.idContrato,
        nome: data.nome,
        valor: data.valor,
        qtdRespostas: data.qtdRespostas,
        qtdUsuarios: data.qtdUsuarios,
        funcionalidadePlano: [
          {
            value: 1,
            text: "teste",
          },
        ],
      };      

     await this.service.updatePlans(payload); 

    } else {
      const payloadCreate = {
        idContrato: data.idContrato,
        nome: data.nome,
        valor: data.valor,
        qtdRespostas: data.qtdRespostas,
        qtdUsuarios: data.qtdUsuarios,
        funcionalidadePlano: [
          {
            value: 1,
            text: "teste",
          },
        ],
      };      
      await this.service.createPlans(payloadCreate);
    }

    this.lisPlans();
  };

  // save state modal true in redux
  openModal(item) {    
    this.setState({
      idPlan: item.id,
      plan: item,
    });
    const payloadModal = {
      visible: true,
      data: item,
    };
    this.props.getModalTrue(payloadModal);
    this.props.getTitleModal('Editar Plano');
  }

  async lisPlans() {
    const result = await this.service.lisPlans();    

    if (result === undefined) {
      await this.service.doLogout();
      this.props.history.push('/login');
    } else {
      this.setState({ data: result.object });
    }
  }

  async deletePlans(id) {
    await this.service.deletePlans(id);
    this.lisPlans();
  }

  async lisPlansFunctions() {
    const result = await this.service.lisPlansFunctions();
    this.props.getDataPlansFunctions(result);

    this.setState({ functionsPlan: result });
  }

  async updateAdmin(id) {
    this.props.history.push(`/administrators/update/${id}`);
  }

  async updatePasswordAdmin(id) {
    this.props.history.push(`/administrators/changepass/${id}`);
  }

  render() {
    const { data } = this.state;
    const home = {
      home: 'Início',
      user: 'Usuários',
      company: 'Empresas',
      contracts: 'Contrato',
      plans: 'Planos',
      logout: 'Sair',
      iconClient: {
        home: 'dashboard',
        user: 'user',
        company: 'bank',
        contracts: 'profile',
        plans: 'tag',
        logout: 'logout',
      },
    };

    const routesClient = {
      home: '/',
      user: 'administrators',
      company: 'companys',
      contracts: 'contracts',
      plans: 'plans',
    };

    return (
      <Layout>
        <Sidebar dataSidebar={home} routes={routesClient} current="plans" />
        <Layout>
          <Header dataUser={this.state.dataUser} />
          <Row className="nopadding-col">
            <Col md={{ span: 16 }} xs={{ span: 24 }}>
              <Breadcrumb pages={[{ title: 'Planos' }]} />
            </Col>
            <Col md={{ span: 8 }} xs={{ span: 24 }}>
              <div className="new-item">
                <ModalContracts
                  active
                  titleButton="Novo Plano"
                  onCreatePlans={this.onCreatePlans}
                  dataPlan={this.state.plan}
                  functionsPlan={this.state.functionsPlan}
                />
              </div>
            </Col>
          </Row>
          <Content className="content">
            <div style={{ background: '#FFF', padding: '30px', height: '80vh' }}>
              <Row gutter={16}>
                {data.map((item, index) => (
                  <Col key={item.id} lg={8} sm={12} xs={24}>
                    <Card
                      style={{ marginTop: 10, backgroundColor: '#ECECEC' }}
                      title={item.nome}
                      extra={(
                        <Switch
                          checked={Boolean(item.ativo)}
                          onChange={() => this.onChange(item, index)}
                        />
)}
                      actions={[
                        <Icon
                          type="edit"
                          style={{ color: '#00a1ed' }}
                          onClick={() => this.openModal(item)}
                        />,
                        <Icon
                          type="delete"
                          onClick={() => this.deletePlans(item.id)}
                          style={{ color: '#00a1ed' }}
                        />,
                      ]}
                      bordered={false}
                    >
                      <p>{item.funcionalidadePlano.length === 0 ? '' : item.funcionalidadePlano[0].text}</p>
                      <p>{`Quantidade de usuarios: ${item.qtdUsuarios}`}</p>
                      <p>{`Quantidade de respostas: ${item.qtdRespostas}`}</p>
                      <p>{`Valor do Plano: R$ ${item.valor}`}</p>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ getModalTrue, getTitleModal, getDataPlansFunctions }, dispatch);
const mapStateToProps = store => ({
  modalState: store.userData.modalState,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ListPlans);
