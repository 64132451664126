import Services from '../../services';

export default class userLogin extends Services {
  userLogin = async (_body) => {
    try {
      const response = await fetch(`${this.endPoint}Login/authenticate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(_body),
      });
      const json = await response.json();

      return json;
    } catch (err) {
      console.log('Error problem', err);
      this.doLogout()
    }
  };

  userForgot = async (_body) => {
    try {
      const response = await fetch(`${this.endPoint}Login/esqueciSenha`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(_body),
      });
      const json = await response.json();

      return json;
    } catch (err) {
      console.log('Error problem', err);
      this.doLogout()
    }
  };
}
