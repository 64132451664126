import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import * as jwt_decode from 'jwt-decode';
import PropTypes from 'prop-types';

import { Button, Input, Icon, message, Row, Col } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getUserData,
  setClientFilial,
  setClientInvite,
  setCompanySelected
} from '../../redux/actions';
import FormRegister from '../../components/FormRegister';

//import logo from '../../images/logoblue.png';
import logo from '../../images/logo_blue.png';
import './styles.css';

import Service from './Service';

class Login extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      login: {
        username: '',
        password: '',
      },
      loading: false,
      register_user: false,
      loadingRegister: false,
    };
    this.service = new Service();
  }

  newdoLogin = async () => {
    this.setState({ loading: true });
    const { login } = this.state;

    const payload = {
      username: login.username,
      password: login.password,
    };

    const result = await this.service.userLogin(payload);

    if (result && result.object.token) {
      message.success('Login efetuado com sucesso');

      //Set redux data user
      this.props.getUserData(result);
      this.props.setCompanySelected([]);
      const token = result.object.token;
      const decodeToken = jwt_decode(token);

      if (decodeToken.role === 'Administrador') {
        this.manageAdmin(result, decodeToken);
      } else {
        this.manageClient(result, decodeToken);
      }
    } else {
      message.error('Usuário e/ou senha incorreto(s)');
      this.setState({ loading: false });
      this.props.history.push('/');
    }
  };

  manageAdmin = (result, decodeToken) => {
    if (result.object.boolCompleteRegister) {
      this.service.setToken(result.object.token);
      this.setState({ loading: false });
      this.props.history.push('/');
    } else {
      this.service.setToken(result.object.token);
      this.setState({ loading: false });
      this.props.history.push({
        pathname: '/register',
        userState: result,
        decodeToken,
      });
    }
  };

  manageClient = async (result, decodeToken) => {
    this.props.setClientFilial({ selected: {}, filial: false });
    this.props.setClientInvite([]);
    this.service.setToken(result.object.token);

    const getInvite = await this.service.userInvitation();
    console.log('getInvite', getInvite);

    if (getInvite && getInvite.object.length > 0) {
      // com convite pendente
      console.log('tem convite');

      if (getInvite && getInvite.object[0].aceito === false) {
        console.log('Aquiiiiiii', getInvite.object[0].aceito);
        this.props.history.push({
          pathname: '/invitation',
          inviteState: getInvite.object,
        });
      } else {
        this.props.history.push({
          pathname: '/invitation',
          inviteState: getInvite.object,
        });

        this.props.setClientInvite(getInvite.object);
      }
    } else {
      console.log('nao tem convite');
      const getCompany = await this.service.getCompany();
      console.log('getCompany', getCompany);

      const getFilial = await this.service.getFilial();
      console.log('getFilial', getFilial);

      if (getCompany.object.length > 0) {
        console.log('tem company == tela cliente');
        this.props.setClientFilial({
          selected: getCompany.object[0],
          filial: false,
        });
        this.props.history.push('/client');
      } else {
        console.log('nao tem company');
        if (getFilial.object.length > 0) {
          console.log('Tem filial == tela cliente', getFilial);
          this.props.setClientFilial({
            selected: getFilial.object[0],
            filial: true,
          });
          this.props.history.push({
            pathname: '/client',
            getFilial: getFilial,
          });
        } else {
          console.log('nao tem filial');
          if (!result.object.boolCompleteRegister) {
            console.log(
              'nao tem convite, nao tem company, nao tem filial, nao tem registro'
            );
            this.props.history.push('/register');
          } else {
            console.log('tem registro pessoa fisica');
            this.props.history.push('/');
          }
        }
      }
    }
  };

  getInvitation = async () => {
    const result = await this.service.userInvitation();
    this.setState({ invite: result.object });
  };

  showRegister = async () => {
    this.setState({ register_user: true, loadingRegister: true });
  };

  render() {
    const { login, loading, loadingRegister } = this.state;

    return (
      <div className="login-page">
        <div className="wrapper">
          <div className="container">
            <img
              src={logo}
              style={{ height: 110, width: 110 }}
              className="logo-login"
              alt="Blue"
            />
            {!this.state.register_user ? (
              <form className="form">
                <Input
                  placeholder="Username or Email"
                  value={login.username}
                  onChange={e => {
                    this.setState({
                      login: { ...login, username: e.target.value },
                    });
                  }}
                  prefix={
                    <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                  }
                />
                <Input
                  type="password"
                  placeholder="Senha"
                  value={login.password}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      this.newdoLogin();
                    }
                  }}
                  onChange={e => {
                    this.setState({
                      login: { ...login, password: e.target.value },
                    });
                  }}
                  prefix={
                    <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                  }
                />
                <Row type="flex" justify="space-between">
                  <Col>
                    <Link to="/forgot">Recuperar Senha</Link>
                  </Col>
                </Row>
                <Button
                  type="primary"
                  loading={loading}
                  onClick={this.newdoLogin}
                >
                  Entrar
                </Button>
                <div style={{ marginTop: 20 }}>
                  <h2
                    style={{
                      fontSize: 16,
                      color: '#00a1ed',
                      fontWeight: 'bold',
                    }}
                  >
                    Não é Usúario?
                  </h2>
                </div>
                <Button
                  style={{
                    backgroundColor: '#00a1ed',
                    color: '#FFF',
                    marginTop: 0,
                  }}
                  loading={loadingRegister}
                  onClick={this.showRegister}
                >
                  Cadastre-se
                </Button>
              </form>
            ) : (
              <FormRegister showRegister={this.showRegister} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  // data: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getUserData: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { getUserData, setClientFilial, setClientInvite, setCompanySelected },
    dispatch
  );
const mapStateToProps = store => ({ data: store.userData.data });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Login));
