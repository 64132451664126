import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import * as jwt_decode from 'jwt-decode';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getDataPlans, getUserModal, getModalTrue } from '../../redux/actions';
import {
  Layout, Table, Divider, Icon, Row, Col, Popconfirm, Switch, message, AutoComplete, Spin
} from 'antd';

import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Breadcrumb from '../../components/Breadcrumb';

import ModalUser from '../../components/ModalUser'

import Service from './Service';
import './styles.css';

const { Content } = Layout;

class ListUsers extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      data: [],
      loading: false,
      visible: false,
      dataAutoComplete: [],
      dataComplete: []
    };

    this.service = new Service();
    this.listUser = this.listUser.bind(this);
    this.updateUser = this.updateUser.bind(this);
  }

  async componentDidMount() {
    if (!this.service.isLogged()) {
      this.props.history.push('/login');
    } else {
      const tokenLogin = localStorage.getItem('token');
      const decodeToken = jwt_decode(tokenLogin);
      this.setState({
        role: decodeToken.role,
        dataUser: decodeToken
      })
      this.listUser();
      this.listUserPerfil();
      this.props.getUserModal({})
    }
  }

  async listUser() {
    this.setState({ loading: true })
    const result = await this.service.listUser();
    console.log("result", result)

    const dataAutoComplete = result.object.map(nome => {
      return nome.pessoa.nome
    })

    this.setState({ dataAutoComplete, dataComplete: result.object })

    if (result === undefined) {
      await this.service.doLogout();
      this.props.history.push('/login');
    } else {
      this.setState({ data: result.object });
    }

    this.setState({ loading: false })
  }

  async listUserPerfil() {
    const result = await this.service.listUserPerfil()
    this.setState({ listPerfil: result.object})
  }

  async updateUser(data) {
    if(data) {
      await this.service.updateUser(data.id, data);
      this.listUser();

      const newpayload = {
        visible: false,
        item: {}
      }
      this.setState({ visible: false});
      this.props.getModalTrue(newpayload);
    }
  }

  async activeUser(id) {
    await this.service.activeUser(id);
  }

  async deleteAdmin(id) {
    const result = await this.service.deleteUser(id);
    this.setState({ data: result.object }, () => {
      this.listUser();
    });
  }

  async updateAdmin(id, record) {
    this.setState({ visible: true })
    const payloadModal = {
      visible: true,
      data: record,
    }
    this.props.getUserModal(payloadModal)
  }

  async updatePasswordAdmin(id) {
    this.props.history.push(`/administrators/changepass/${id}`);
  }  

  onChange = async (userId) => {
    console.log("userId", userId)

    const newUsers = this.state.data.map((user) => {
      return user.id === userId ? {...user, ativo: !user.ativo} : user;
    });

    console.log("newUsers", newUsers)

    const updatedUser = newUsers.filter(user => {
      return user.id === userId
    })
    console.log("updatedUser", updatedUser)
    this.setState({ data: newUsers})

    updatedUser[0].ativo ? await this.service.activeUser(userId) : await this.service.disableUser(userId)

  };

  handleMenuClick = (e) => {
    message.info('Click on menu item.');
    console.log('click', e);
  }

  handleButtonClick = (e) => {
    message.info('Click on left button.');
    console.log('click left button', e.key);
  }

  changeSelectUser = (value) => {
    const userName = this.state.data.map(item => {
      return item
    })

    const filterUser = userName.filter(item => item.pessoa.nome === value);
    this.setState({ data: filterUser })
  }

  onSearchUser = (value) => {
    if(value === '') {
      this.setState({ data: this.state.dataComplete })
    }
  }

  render() {
    const { data, dataAutoComplete, listPerfil } = this.state;
    const home = {
      home: "Início",
      user: "Usuários",
      company: "Empresas",
      contracts: "Contrato",
      plans: "Planos",
      logout: "Sair",
      iconClient: {
        home: "dashboard",
        user: "user",
        company: "bank",
        contracts: "profile",
        plans: "tag",
        logout: "logout"
      }
    }

    const routesClient = {
      home: "/",
      user: "administrators",
      company: "companys",
      contracts: "contracts",
      plans: "plans",
      configuration: "settings",
    }

    const columns = [
      {
        title: 'Nome',
        dataIndex: 'pessoa.nome',
        key: 'pessoa.nome',
        // className: 'hidden-xs',
      },
      {
        title: 'E-mail',
        dataIndex: 'email',
        key: 'email',
        // className: 'hidden-xs',
      },
      {
        title: 'Perfil',
        dataIndex: 'nomePerfil',
        key: 'nomePerfil',
        // className: 'hidden-xs',
      },
      {
        title: 'Ações',
        dataIndex: 'id',
        key: 'id',
        className: 'col-action',
        render: (dataIndex, record) => (
          <span>
            <Divider type="vertical" />
            <Switch checked={record.ativo} onChange={() => this.onChange(dataIndex, record)} />
            <Popconfirm
              placement="topRight"
              title={`Deseja editar o administrador: ${record.pessoa.nome}`}
              onConfirm={() => this.updateAdmin(dataIndex, record)}
              okText="Sim"
              cancelText="Não"
            >
              <Icon className="table-actions" type="edit" title="Editar" />
            </Popconfirm>
          </span>
        ),
      },
    ];

    return (
      <Layout>
        <Sidebar dataSidebar={home} routes={routesClient} current="administrators" />
        <Layout>
          <Header dataUser={this.state.dataUser}/>
          <Row className="nopadding-col">
            <Col md={{ span: 14 }} xs={{ span: 24 }}>
              <Breadcrumb pages={[{ title: 'Usuários' }]} />
            </Col>
            <Col md={{ span: 10 }} xs={{ span: 24 }}>
              <div className="new-item">
                {
                  this.state.visible && (<ModalUser updateUser={this.updateUser} listPerfil={listPerfil} visible={this.state.visible} />)
                }
                <AutoComplete
                  allowClear
                  style={{ width: 200 }}
                  dataSource={dataAutoComplete}
                  placeholder="Nome do usuário"
                  filterOption={(inputValue, option) =>
                    option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                  }
                  onSelect={(value) => this.changeSelectUser(value)}
                  onSearch={(value) => this.onSearchUser(value)}
                />
              </div>
            </Col>
          </Row>
          <Spin
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            delay={500}
            spinning={this.state.loading}
            size="large"
          >
            <Content className="content">
              <div style={{ background: '#FFF', padding: '30px', height: '90vh' }}>
                <Table
                  className="table"
                  columns={columns}
                  dataSource={data}
                  rowKey={item => item.id}
                  scroll={{ x: 500 }}
                />
              </div>
            </Content>
          </Spin>
        </Layout>
      </Layout>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ getDataPlans, getUserModal, getModalTrue }, dispatch);

const mapStateToProps = store => ({
  dataPlans: store.userData.dataPlans,
  userModal: store.userData.userModal,
  userDataUpdate: store.userData.userDataUpdate
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListUsers));
