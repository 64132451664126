import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getDataPlans, getUserModal } from '../../redux/actions';

import StringMask from 'string-mask';

import { Modal, Form, Input, Icon, Button, Dropdown, Menu } from 'antd';

import FormUser from '../FormUser';
import InputMask from 'react-input-mask';

import Service from '../../services'

class ModalUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmDirty: false,
      visible: false,
      checked: true,
      segment: '',
      segmentCompany: '',
      estado: '',
      cidade: '',
      cep: '',
      cpfcnpj: '',
      nome: '',
      email: '',
      celular: '',
      comercial: '',
      endereco: '',
      telefone: ''
    };

    this.service = new Service();
    this.getCep = this.getCep.bind(this);
  }

  componentDidMount() {

    this.setState({
      visible: this.props.visible,
      nomePerfil: this.props.userModal.data.nomePerfil,
      idPerfil: this.props.userModal.data.idPerfil,
      estado: this.props.userModal.data.pessoa.estado,
      cidade: this.props.userModal.data.pessoa.cidade,
      cep: this.props.userModal.data.pessoa.cep,
      cpfcnpj: this.props.userModal.data.pessoa.cpfcnpj,
      nome: this.props.userModal.data.pessoa.nome,
      email: this.props.userModal.data.email,
      endereco: this.props.userModal.data.pessoa.endereco,
      comercial: (
        this.props.userModal.data.pessoa.telefones.length > 1 ?
        this.setMask(this.props.userModal.data.pessoa.telefones[1].ddd + '' + this.props.userModal.data.pessoa.telefones[1].numero, '(00) 00000-0000') : ''
      ),
      telefone: (
        this.props.userModal.data.pessoa.telefones.length > 0 ?
        this.setMask(this.props.userModal.data.pessoa.telefones[0].ddd + '' + this.props.userModal.data.pessoa.telefones[0].numero, '(00) 00000-0000') : ''
      ),
    })
  }

  componentDidUpdate(prevProps) {
    if(this.props.userModal.data.pessoa.email !== prevProps.userModal.data.pessoa.email) {
      this.setState({
        visible: this.props.visible,
        nomePerfil: this.props.userModal.data.nomePerfil,
        idPerfil: this.props.userModal.data.idPerfil,
        estado: this.props.userModal.data.pessoa.estado,
        cidade: this.props.userModal.data.pessoa.cidade,
        cep: this.props.userModal.data.pessoa.cep,
        cpfcnpj: this.props.userModal.data.pessoa.cpfcnpj,
        nome: this.props.userModal.data.pessoa.nome,
        email: this.props.userModal.data.email,
        endereco: this.props.userModal.data.pessoa.endereco,
        comercial: (
          this.props.userModal.data.pessoa.telefones.length > 1 ?
          this.setMask(this.props.userModal.data.pessoa.telefones[1].ddd + '' + this.props.userModal.data.pessoa.telefones[1].numero, '(00) 00000-0000') : ''
        ),
        telefone: (
          this.props.userModal.data.pessoa.telefones.length > 0 ?
          this.setMask(this.props.userModal.data.pessoa.telefones[0].ddd + '' + this.props.userModal.data.pessoa.telefones[0].numero, '(00) 00000-0000') : ''
        ),
      })
    }
  }

  componentWillReceiveProps(props) {
    this.setState({
      visible: props.visible,
    });
  }

  showModal = () => {
    this.setState({
      visible: this.props.visible,
    });
  };

  handleOk = () => {
    this.setState({
      ModalText: 'The modal will be closed after two seconds',
      confirmLoading: true,
    });
    setTimeout(() => {
      this.setState({
        visible: false,
        confirmLoading: false,
      });
    }, 2000);

    this.onChange();
  };

  handleCancel = async () => {
    await this.setState({
      visible: false,
    });
  };

  onChange = async () => {
    const { email, nome, estado, cidade, endereco, cep, cpfcnpj, perfil, celular, telefone, comercial, nomePerfil, idPerfil } = this.state;

    const payload = {
      id: this.props.userModal.data.id,
      email: email,
      idPerfil: parseInt(perfil) || idPerfil,
      nomePerfil: nomePerfil,
      ativo: true,
      pessoa: {
        id: this.props.userModal.data.id,
        nome: nome,
        cpfcnpj: cpfcnpj,
        email: email,
        cidade: cidade,
        estado: estado,
        endereco: endereco,
        cep: cep,
        telefones: [],
      }
    }

    if(telefone) {
      payload.pessoa.telefones[0] = {
        ddd: telefone.split(" ")[0].replace(/\D/g, ''),
        numero: telefone.split(" ")[1].replace(/\D/g, ''),
        tipoTelefone: 1
      }
    }

    if(comercial) {
      payload.pessoa.telefones[1] = {
        ddd: comercial.split(" ")[0].replace(/\D/g, ''),
        numero: comercial.split(" ")[1].replace(/\D/g, ''),
        tipoTelefone: 2
      }
    }
    this.props.updateUser(payload)
  }

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  onChangeChecked = (checked) => {
    this.setState({ checked });
  };

  getCep = async () => {
    if(this.state.cep.length === 10 ) {
      const result = await this.service.getCEP(this.state.cep);

      if(result) {
        this.setState({
          estado: result.uf || '', cidade: result.localidade || '', endereco: result.logradouro || ''
        });
      }
    }
  }

  handleMenuClick = (e) => {
    this.setState({ perfil: e.key, nomePerfil: e.item.props.children[1]})
  }

  setMask(value, maskType) {
    const mask = new StringMask(maskType);
    return mask.apply(value);
  }

  render() {
    const { visible, confirmLoading, email, nome, cpfcnpj, celular, telefone, comercial, nomePerfil } = this.state;
    const { getFieldDecorator } = this.props.form;   

    const menu = (
      <Menu onClick={this.handleMenuClick}>
        <Menu.Item key="1">
          <Icon type="user" />
          {this.props.listPerfil[0].descricao}
        </Menu.Item>
        <Menu.Item key="2">
          <Icon type="user" />
          {this.props.listPerfil[1].descricao}
        </Menu.Item>
      </Menu>
    );

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8, offset: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16, offset: 4 },
      },
    };

    return (
      <div>
        <Button type="primary" onClick={this.showModal} style={{ display: 'none'}}>
          Open Modal with async logic
        </Button>
        <Modal
          title="Title"
          visible={visible}
          onOk={this.handleOk}
          confirmLoading={confirmLoading}
          onCancel={this.handleCancel}
        >
          <div className="">
            <Form {...formItemLayout}>
              <Form.Item>
                {getFieldDecorator('name', {
                  initialValue: nome,
                  rules: [{ required: true, message: 'Por favor informe seu nome!', whitespace: true }],
                })(
                  <Input
                    placeholder={"Nome completo"}
                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    onChange={(e) => {
                      this.setState({
                        nome: e.target.value
                      });
                    }}
                  />,

                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('email', {
                  initialValue: email,
                  rules: [
                    {
                      type: 'email',
                      message: 'Forneça um e-mail válido!',
                    },
                    {
                      required: true,
                      message: 'E-mail obrigatório!',
                    },
                  ],
                })(
                  <Input
                    placeholder="Email"
                    type="email"
                    prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    onChange={(e) => {
                      this.setState({
                        email: e.target.value
                      });
                    }}
                  />,
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('cpfcnpj', {
                  initialValue: cpfcnpj,
                  rules: [{ required: true, message: 'Forneça um CPF valido', whitespace: true }],
                })(
                  <span className="ant-input-affix-wrapper">
                    <span className="ant-input-prefix">
                      <Icon type="solution" style={{ color: 'rgba(0,0,0,.25)' }} />
                    </span>
                    <InputMask
                      className="ant-input"
                      mask="999.999.999-99"
                      defaultValue={cpfcnpj}
                      onChange={(e) => this.setState({ cpfcnpj: e.target.value} )}
                      placeholder="CPF"
                    />
                  </span>,
                )}
              </Form.Item>
              <Form.Item>
                <Dropdown overlay={menu}>
                  <Button style={{ marginRight: 15}}>
                    {nomePerfil} <Icon type="down" />
                  </Button>
                </Dropdown>
              </Form.Item>
              {/* <Form.Item>
                {getFieldDecorator('cep', {
                  initialValue: cep,
                  rules: [{ required: true, message: 'Forneça um Cep valido', whitespace: true }],
                })(
                  <span className="ant-input-affix-wrapper">
                    <span className="ant-input-prefix">
                      <Icon type="pushpin" style={{ color: 'rgba(0,0,0,.25)' }} />
                    </span>
                    <InputMask
                      className="ant-input"
                      mask="99.999-999"
                      defaultValue={cep}
                      onBlur={() => this.getCep()}
                      onChange={(e) => this.setState({ cep: e.target.value} )}
                      placeholder="CEP"
                    />
                  </span>,
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('estado', {
                  initialValue: estado,
                  rules: [{ required: true, message: 'Por favor informe seu estado!', whitespace: true }],
                })(
                  <Input
                    placeholder={"estado"}
                    prefix={<Icon type="bank" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    onChange={(e) => {
                      this.setState({
                        estado: e.target.value
                      });
                    }}
                  />,
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('cidade', {
                  initialValue: cidade,
                  rules: [{ required: true, message: 'Forneça uma cidade valida', whitespace: true }],
                })(
                  <Input
                    onChange={(e) => this.setState({ cidade: e.target.value })}
                    placeholder="Cidade"
                    prefix={<Icon type="team" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  />,
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('endereco', {
                  initialValue: endereco,
                  rules: [{ required: true, message: 'Endereço', whitespace: true }],
                })(
                  <Input
                    onChange={(e) => this.setState({ endereco: e.target.value} )}
                    placeholder="Endereço"
                    prefix={<Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  />,
                )}
              </Form.Item> */}
              <Form.Item>
                {getFieldDecorator('celular', {
                  initialValue: celular,
                  rules: [
                    {
                      required: true,
                      message: 'Forneça o numero do celular',
                    },
                  ],
                })(
                  <span className="ant-input-affix-wrapper">
                    <span className="ant-input-prefix">
                      <Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />
                    </span>
                    <InputMask
                      value={telefone}
                      className="ant-input"
                      mask="(99) 999-999-999"
                      placeholder="Celular"
                      onChange={(e) => {
                        this.setState({
                          telefone: e.target.value
                        });
                      }}
                    />
                  </span>,
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('comercial', {
                  initialValue: comercial,
                  rules: [{ message: 'Forneça um numero comercial' }],
                })(
                  <span className="ant-input-affix-wrapper">
                    <span className="ant-input-prefix">
                      <Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />
                    </span>
                    <InputMask
                      value={comercial}
                      className="ant-input"
                      mask="(99) 999-999-999"
                      placeholder="Numero comercial"
                      onChange={(e) => {
                        this.setState({
                          comercial: e.target.value
                        });
                      }}
                    />
                  </span>,
                )}
              </Form.Item>
              {/* <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit">
                  Próximo
                </Button>
              </Form.Item> */}
            </Form>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ getDataPlans, getUserModal }, dispatch);

const mapStateToProps = store => ({
  dataPlans: store.userData.dataPlans,
  userModal: store.userData.userModal
});

export default Form.create(FormUser)(connect(mapStateToProps, mapDispatchToProps)(ModalUser));
